export class UserCountryRole {
    public userId: number;
    public countryId: string;
    public countryCode: string;
    public allGroups: any[] = [];
    public allRoles: any[] = [];
    public showRoles: boolean;
    public selectedRoleIdsForDisplay: string[] = [];
    public selectedGroupIdsForDisplay: string[] = [];
    public selRoleIds: string[] = [];
    public selGroupIds: string[] = [];
    public expirationDate: string;
    public disableDelete: boolean;
    public restrictionVal : String;
    public dshbModuleCode: string;
    public restrictionType: string;
    public dshbModuleId: String;
}
