import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import {catchError, mergeMap, map} from 'rxjs/operators';
import { AlertService } from '../components/alerts/alert.service';
import { ResourceService } from '../resource.service';
import { throwError , of } from 'rxjs'; 


@Injectable()
export class RoleManagementService {

    public privileges;
    public selectedPrivileges: Array<number> = [];
    public selectedGroups: Array<number> = [];
    public accessPrivilegeMetadata = [];

    // --Noble
    public privilegeData: any;
    public defaultPrivilegeData: any;
    public formattedPrivilegeData: any = [];
    public onTemplateLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
    public header =Â newÂ HttpHeaders({Â 'api':Â 'admin'Â });
    // --Noble

    constructor(public _http: HttpClient,
        public alertService: AlertService,
        public resourceService: ResourceService) { }

     // LFWM-1994 passing appId and privilegetype
    public initializePrivilegeTemplate(appId,privilegeType) {
        this.getAccessPrivilege(appId,privilegeType)
            .subscribe((resp: any) => {
                if (resp['statusCode'] && resp['statusMessage']) {
                    if (resp['statusCode'] === 200) {
                        this.privilegeData = resp['modules'];
                        this.defaultPrivilegeData = Object.assign({}, this.privilegeData);
                        this.formatPrivilegesData();
                    }
                } else {
                    if (resp.error) {
                        this.alertService.clearAll();
                        this.alertService.error(this.resourceService.translateServerResponse(resp.error));
                    }
                }
            }, (error: any) => {
                this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
            });
    }
    /* This Method will create object format required for UI representation
    from access privilege service response */
    public formatPrivilegesData() {
        this.formattedPrivilegeData = [];
        let formattedModule0;
        let formattedModule1;
        let formattedModule2;
        let formattedModule3;
        let formattedModule4;
        this.privilegeData.map((module0: any) => {
            const parentIndex = this.formattedPrivilegeData.length;
            const level = 0;
            const subParentIndex = 0;
            const currentIndexlevel0 = '' + 0;
            formattedModule0 = this.createModuleData(module0, parentIndex, level, subParentIndex, currentIndexlevel0);
            this.formattedPrivilegeData.push(formattedModule0);
            if (module0.submodules && module0.submodules.length > 0) {
                module0.submodules.map((module1: any, index1) => {
                    const level1 = 1;
                    const subParentIndex1 = currentIndexlevel0;
                    const currentIndexlevel1 = '' + subParentIndex1 + level1 + index1;
                    formattedModule1 = this.createModuleData(module1, parentIndex, level1, subParentIndex1, currentIndexlevel1);
                    formattedModule0['isDetailParent'] = !formattedModule1['isNotDetailScreen'];
                    this.formattedPrivilegeData.push(formattedModule1);
                    if (module1.submodules && module1.submodules.length > 0) {
                        module1.submodules.map((module2: any, index2) => {
                            const level2 = 2;
                            const subParentIndex2 = currentIndexlevel1;
                            const currentIndexlevel2 = '' + subParentIndex2 + level2 + index2;
                            formattedModule2 = this.createModuleData(module2, parentIndex, level2, subParentIndex2, currentIndexlevel2);
                            formattedModule1['isDetailParent'] = !formattedModule2['isNotDetailScreen'];
                            this.formattedPrivilegeData.push(formattedModule2);
                            if (module2.submodules && module2.submodules.length > 0) {
                                module2.submodules.map((module3: any, index3) => {
                                    const level3 = 3;
                                    const subParentIndex3 = currentIndexlevel2;
                                    const currentIndexlevel3 = '' + subParentIndex3 + level3 + index3;
                                    formattedModule3 = this.createModuleData(module3, parentIndex, level3, subParentIndex3,
                                        currentIndexlevel3);
                                    formattedModule2['isDetailParent'] = !formattedModule3['isNotDetailScreen'];
                                     // Code for removing space if more than one detail page is present starts
                                    /*  The detail field names are hardcoded , because some fields of module 3
                                     are in detail level while some are still main-pages without detail pages */
                                     const detailModules: Array<string> = ['mn_imprt_stkparm', 'lbl_to_dtl',
                                     'mn_comm_adv_config', 'mn_comm_asgn_loc', 'lbl_grp_build_params', 'mn_add_info'];
                                     if (detailModules.indexOf(formattedModule3['displayId']) > -1) {
                                         formattedModule3['isDetailParent'] = true;
                                     } else {
                                         formattedModule3['isDetailParent'] = false;
                                     }
                                     // Code for removing space if more than one detail page is present ends
                                    this.formattedPrivilegeData.push(formattedModule3);
                                    /* Start : Code changes for level-4 menu */
                                    if (module3.submodules && module3.submodules.length > 0) {
                                        module3.submodules.map((module4: any, index4) => {
                                            const level4 = 4;
                                            const subParentIndex4 = currentIndexlevel3;
                                            const currentIndexlevel4 = '' + subParentIndex4 + level4 + index4;
                                            formattedModule4 = this.createModuleData(module4, parentIndex, level4, subParentIndex4,
                                                currentIndexlevel4);
                                            formattedModule3['isDetailParent'] = !formattedModule4['isNotDetailScreen'];
                                            formattedModule4['isDetailParent'] = false;
                                            this.formattedPrivilegeData.push(formattedModule4);
                                        });
                                    }
                                    /* End : Code changes for level-4 menu */
                                });
                            }
                        });
                    }
                });
            }
        });
        this.formattedPrivilegeData.map((privilegeData: any) => {
            privilegeData.privileges = this.prepareGridLayout(privilegeData.privileges);
        });
        this.onTemplateLoaded.emit(true);
    }

    /*This method is used to create module object */
    public createModuleData(module: any, parentIndex: any, level: any, subParentIndex: any, currentIndex: any): any {
        const moduleIdKey = 'lbl_access';
        const formattedModule = {
            level: level,
            displayId: module.displayId,
            privileges: [],
            parentIndex: parentIndex,
            isEnabled: false,
            isChecked: false,
            subParentIndex: subParentIndex,
            currentIndex: currentIndex,
            isNotDetailScreen: true,
            isNotHiddenModule: (module.privileges.length > 0) ? true : false
        };
        if (module.privileges && module.privileges.length > 0) {
            let notDetailScreen = false;
            module.privileges.map((privilege: any) => {
                if (privilege.display !== moduleIdKey) {
                    const formattedPrivilege = {
                        id: privilege.id,
                        display: privilege.display,
                        isChecked: false,
                        isEnabled: false
                    };
                    formattedModule.privileges.push(formattedPrivilege);
                } else {
                    notDetailScreen = true;
                    formattedModule['id'] = privilege.id;
                }
            });
            if (!notDetailScreen) {
                formattedModule['isNotDetailScreen'] = false;
            }
        }
        return formattedModule;
    }
    public prepareGridLayout(privilegeData: Array<any>): Array<any> {
        let gridRow;
        const gridLayout = [];
        for (let x = 0; x < privilegeData.length; x++) {
            if (x % 6 === 0) {
                if (gridRow && gridRow.length === 6) {
                    gridLayout.push(gridRow);
                }
                gridRow = [];
            }
            gridRow.push(privilegeData[x]);
        }
        if (gridRow && gridRow.length !== 0) {
            for (let y = gridRow.length; y < 6; y++) {
                gridRow.push({ isDummy: true });
            }
            gridLayout.push(gridRow);
        }
        return gridLayout;
    }

    public refreshPrivilegeData(privilegesList: Array<number>) {
        this.resetPrivilegeData(false);
        this.selectedPrivileges = [];
        for (let x = 0; x < this.formattedPrivilegeData.length; x++) {
            const moduleDefinition = this.formattedPrivilegeData[x];
            // if (moduleDefinition.level === 0) {
            //     parentModuleDefinition = moduleDefinition;
            // }
            if (privilegesList.indexOf(moduleDefinition.id) > -1) {
                moduleDefinition.isChecked = true;
                this.selectedPrivileges.push(moduleDefinition.id);
            } else {
                moduleDefinition.isChecked = false;
            }
            for (let y = 0; y < moduleDefinition.privileges.length; y++) {
                for (let z = 0; z < moduleDefinition.privileges[y].length; z++) {
                    const privilegeDefinition = moduleDefinition.privileges[y][z];
                    if (!privilegeDefinition.isDummy) {
                        if (privilegesList.indexOf(privilegeDefinition.id) > -1) {
                            privilegeDefinition.isChecked = true;
                            this.selectedPrivileges.push(privilegeDefinition.id);
                        } else {
                            privilegeDefinition.isChecked = false;
                        }
                    }
                }
            }
            if (moduleDefinition.isNotDetailScreen) {
                this.enableToggleButton(moduleDefinition, x);
            }
        }
    }
    /*This method is used to enable toggle button corresponds to privilege code,
    while selecting role from dropdown */
    public enableToggleButton(parentModuleDefinition: any, moduleIndex: number) {
        if (parentModuleDefinition.isChecked) {
            parentModuleDefinition.isEnabled = parentModuleDefinition.isChecked;
        } else {
            for (let x = 0; x < this.formattedPrivilegeData.length; x++) {
                if (parentModuleDefinition.parentIndex === this.formattedPrivilegeData[x].parentIndex
                    && this.formattedPrivilegeData[x].currentIndex === '0') {
                    parentModuleDefinition.isEnabled = this.formattedPrivilegeData[x].isChecked;
                }
            }
        }
        if (parentModuleDefinition.level === 0) {
            for (let x = moduleIndex; x < this.formattedPrivilegeData.length; x++) {
                const moduleDefinition = this.formattedPrivilegeData[x];
                if (parentModuleDefinition.isChecked) {
                    if (parentModuleDefinition.parentIndex === this.formattedPrivilegeData[x].parentIndex
                        && this.formattedPrivilegeData[x].isNotDetailScreen) {
                        this.formattedPrivilegeData[x].isEnabled = parentModuleDefinition.isChecked;
                    }
                } else {
                    this.formattedPrivilegeData[x].isEnabled = parentModuleDefinition.isChecked;
                    this.formattedPrivilegeData[x].isChecked = parentModuleDefinition.isChecked;
                    this.formattedPrivilegeData[x].privileges.forEach((privilege) => {
                        privilege.forEach((privil) => {
                            privil.isEnabled = parentModuleDefinition.isEnabled;
                            privil.isChecked = parentModuleDefinition.isChecked;
                        });
                    });
                }
            }
        } else {
            if (this.formattedPrivilegeData.length > 0) {
                this.formattedPrivilegeData[moduleIndex].privileges.forEach((privilege) => {
                    privilege.forEach((privil) => {
                        privil.isEnabled = parentModuleDefinition.isChecked;
                        if (!parentModuleDefinition.isChecked) {
                            privil.isChecked = parentModuleDefinition.isChecked;
                        }
                    });
                });
                if (parentModuleDefinition.isDetailParent) {
                    for (let x = moduleIndex; x < this.formattedPrivilegeData.length; x++) {
                        const moduleDefinition = this.formattedPrivilegeData[x];
                        if (parentModuleDefinition.parentIndex === this.formattedPrivilegeData[x].parentIndex
                        ) {
                            if (parentModuleDefinition.currentIndex === this.formattedPrivilegeData[x].subParentIndex) {
                                this.formattedPrivilegeData[x].isEnabled = parentModuleDefinition.isChecked;
                                this.formattedPrivilegeData[x].privileges.forEach((privilege) => {
                                    privilege.forEach((privil) => {
                                        privil.isEnabled = parentModuleDefinition.isChecked;
                                        if (!parentModuleDefinition.isChecked) {
                                            privil.isChecked = parentModuleDefinition.isChecked;
                                            privil.isEnabled = parentModuleDefinition.isChecked;
                                        }
                                    });
                                });
                            }
                        }
                    }
                }
            }
        }
    }
    public resetPrivilegeData(enableToggle: boolean) {
        for (let x = 0; x < this.formattedPrivilegeData.length; x++) {
            const moduleDefinition = this.formattedPrivilegeData[x];
            if (moduleDefinition.level === 0) {
                moduleDefinition.isChecked = false;
                moduleDefinition.isEnabled = enableToggle;
            } else {
                moduleDefinition.isEnabled = !enableToggle;
                moduleDefinition.isChecked = false;

            }
            for (let y = 0; y < moduleDefinition.privileges.length; y++) {
                for (let z = 0; z < moduleDefinition.privileges[y].length; z++) {
                    const privilegeDefinition = moduleDefinition.privileges[y][z];
                    if (!privilegeDefinition.isDummy) {
                        privilegeDefinition.isChecked = false;
                        privilegeDefinition.isEnabled = !enableToggle;
                    }
                }
            }
        }

    }

    public getCurrentRolePrivilegeIds() {
        const pList: any = [];
        this.formattedPrivilegeData.map((moduleDefinition: any) => {
            moduleDefinition.privileges.map((privilegeRow: any) => {
                privilegeRow.map((privilegeDefinition: any) => {
                    if (privilegeDefinition.isChecked) {
                        pList.push(privilegeDefinition.id);
                    }
                });
            });
        });
    }

    /*Method used to add a new role in the system or update an existing role */
    public saveRole(accessPrivilegeRole: any) {
        return this._http.post('api/admin/role', accessPrivilegeRole, {Â headers:Â this.header}).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.error.errors.length !== 0) {
                        if (error.status === 400) {
                            return Observable.throw(error.error.errors['0'].defaultMessage);
                        } else if (error.error.errors[0].validationCode !== 200) {
                            return Observable.throw(error.error.errors['0'].validationMessage);
                        }
                    }


                }

            }));
    }

    /* Method used to perform soft delete the role from DB */
    public deleteRole(appId: number, roleId: number): Observable<any> {
        const url = 'api/admin/' + appId + '/role/' + roleId + '/';
        return this._http.delete(url, {Â headers:Â this.header}).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.error.errors[0].validationCode !== 200) {
                        return Observable.throw(error.error.errors['0'].validationMessage);
                    }
                }
            }));
    }
    /* method used to fetch access privileges defined in the system */
    public getAccessPrivilege(appId,privilegeType) {
        return this._http.get('api/admin/'+appId+'/'+privilegeType+'/access', {Â headers:Â this.header}).pipe(
        // return this._http.get('/api/admin/1/B/access')
            map((response: Response) => this.getModifiedAccessPrivilege(response, 'read')),
            catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.error.errors[0].validationCode !== 200) {
                        return Observable.throw(error.error.errors['0'].validationMessage);
                    }
                }
            }));
    }
    /* method used to add checked attribute to access privilege */
    getModifiedAccessPrivilege(response: any, mode: String) {
        response['modules'].forEach((mainModule, modIndex) => {
            mainModule.disablehr = true;
            mainModule['privileges'].forEach((privilege, indexModPrivil) => {
                mainModule['privileges'][indexModPrivil].checked = false;
            });
            if (mode === 'create') {
                mainModule['toggleSubmodule'] = true;
                mainModule['disableCheckbox'] = true;
            }
            if (mainModule['submodules'] && mainModule['submodules'].length > 0) {
                mainModule['submodules'].forEach((submodule, indexSubPrivil) => {
                    submodule.disablehr = false;
                    if (indexSubPrivil === mainModule['submodules'].length - 1) {
                        submodule.disablehr = true;
                    }
                    submodule.privileges.forEach((privilege, indexPrivilege) => {
                        if (mode === 'read') {
                            mainModule['toggleSubmodule'] = false;
                            mainModule['disableCheckbox'] = false;
                        } else {
                            mainModule['toggleSubmodule'] = true;
                            mainModule['disableCheckbox'] = true;
                        }
                        mainModule['submodules'][indexSubPrivil].privileges[indexPrivilege].checked = false;
                        if (submodule.submodules.length > 0) {
                            submodule.disablehr = false;
                            submodule.submodules.forEach((subPrivilege, indexSub) => {
                                subPrivilege.privileges.forEach((subprivilege, indexSubPrivilege) => {
                                    mainModule['submodules'][indexSubPrivil].submodules[indexSub].privileges[indexSubPrivilege].checked
                                        = false;
                                });
                                if (indexSub === submodule.submodules.length - 1) {
                                    subPrivilege.disablehr = true;
                                }
                                if (subPrivilege.submodules.length > 0) {
                                    subPrivilege.disablehr = true;
                                    subPrivilege.submodules.forEach((innerSub, indexInnerSub) => {
                                        innerSub.checked = false;
                                        innerSub.disablehr = false;
                                        if (indexSub === submodule.submodules.length - 1) {
                                            innerSub.disablehr = true;
                                        }
                                    });
                                } else {
                                    subPrivilege.disablehr = false;
                                }

                                if (indexSub === submodule.submodules.length - 1) {
                                    subPrivilege.disablehr = true;
                                }

                            });
                        }
                    });
                });

            }
        });
        this.selectedPrivileges = [];
        this.selectedGroups = [];
        return response;
    }

    // public updateAccessPrivilegePerRole(accessPrivilege: Array<any>, rolePrivileges: Array<any>) {
    //     this.selectedPrivileges = [];
    //     if (accessPrivilege.length > 0 && rolePrivileges.length > 0) {
    //         accessPrivilege.forEach((previligeModule, indexModule) => {
    //             rolePrivileges.forEach((rolePrivil, indexRolePrivil) => {
    //                 previligeModule.privileges.forEach((privilege, indexAccessPrivilege) => {
    //                     if (rolePrivil === privilege.id) {
    //                         accessPrivilege[indexModule].privileges[indexAccessPrivilege].checked = true;
    //                         accessPrivilege[indexModule].toggleSubmodule = true;
    //                         accessPrivilege[indexModule].disableCheckbox = true;
    //                         this.selectedPrivileges.push(privilege.id);

    //                     }
    //                 });
    //                 if (previligeModule['submodules'] != null) {
    //                     previligeModule['submodules'].forEach((submodule, index) => {
    //                         submodule.privileges.forEach((privilege, indexPrivilege) => {
    //                             if (rolePrivil === privilege.id) {
    //                                 accessPrivilege[indexModule].submodules[index].privileges[indexPrivilege].checked = true;
    //                                 accessPrivilege[indexModule].toggleSubmodule = true;
    //                                 accessPrivilege[indexModule].disableCheckbox = true;
    //                                 this.selectedPrivileges.push(privilege.id);

    //                             }
    //                         });
    //                         if (submodule.submodules != null) {
    //                             if (submodule.submodules.length > 0) {
    //                                 submodule.submodules.forEach((subModule, indexSub) => {
    //                                     subModule.privileges.forEach((subprivilege, indexSubPrivilege) => {
    //                                         if (rolePrivil === subprivilege.id) {
    //                                             accessPrivilege[indexModule].submodules[index].submodules[indexSub]
    //                                                 .privileges[indexSubPrivilege].checked = true;
    //                                             accessPrivilege[indexModule].toggleSubmodule = true;
    //                                             accessPrivilege[indexModule].disableCheckbox = true;
    //                                             this.selectedPrivileges.push(subprivilege.id);

    //                                         }
    //                                     });

    //                                     if (subModule.submodules && subModule.submodules.length > 0) {
    //                                         subModule.submodules.forEach((subLastModule, indexSubLast) => {
    //                                             subLastModule.privileges.forEach((subLastPrivilege, indexSubLastPrivilege) => {
    //                                                 if (rolePrivil === subLastPrivilege.id) {
    //                                                     accessPrivilege[indexModule].submodules[index].submodules[indexSub]
    //                                                         .submodules[indexSubLast].privileges[indexSubLastPrivilege].checked = true;
    //                                                     accessPrivilege[indexModule].toggleSubmodule = true;
    //                                                     accessPrivilege[indexModule].disableCheckbox = true;
    //                                                     this.selectedPrivileges.push(subLastPrivilege.id);
    //                                                 }
    //                                             });
    //                                         });

    //                                     }
    //                                 });
    //                             }
    //                         }

    //                     });
    //                 }
    //             });
    //         });
    //     }
    //     return accessPrivilege;
    // }

    /* Method is used to return selected access privileges corresponds to a role */
    public getDefaultPrivileges(): Array<number> {
        return this.selectedPrivileges;
    }

    /* Method is used to set selected access privileges corresponds to a role after saving a role*/
    public setDefaultPrivileges(privileges: Array<number>) {
        this.selectedPrivileges = [];
        this.selectedPrivileges = privileges;
    }

    /* Method is used to return selected access privileges corresponds to a role */
    public getDefaultGroups(): Array<number> {
        return this.selectedGroups;
    }

    /* Method is used to set selected access privileges corresponds to a role after saving a role*/
    public setDefaultGroups(groups: any) {
        this.selectedGroups = [];
        this.selectedGroups = groups;
    }

    /* Method is used to  remove selected access privileges */
    public removeDefaultPrivileges() {
        this.selectedPrivileges = [];
    }

    /* Method is used to  remove selected groups */
    public removeDefaultGroups() {
        this.selectedGroups = [];
    }

    /* Method is used to save default access privileges in the system */
    public setAccessPrivilegeMetaData(metadata: any) {
        this.accessPrivilegeMetadata.push(metadata);
    }

    /* Method will return default access privileges in the system */
    public getAccessPrivilegeMetaData(): Array<any> {
        return this.accessPrivilegeMetadata;
    }

    /*Method is used to fetch all the Access Privileges defined for a specific role in the system */
    public getAccessPrivilegeRole(appId: number, roleId: number,privilegeType:string): Observable<any> {
        const url = 'api/admin/' + appId + '/role/' + roleId + '/'+privilegeType+'/accessprivilege/';
        return this._http.get(url, { headers: this.header }).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.error.errors[0].validationCode !== 200) {
                        return Observable.throw(error.error.errors['0'].validationMessage);
                    }
                }
            }));
    }

    public checkActiveUser(appId: number, roleId: number): Observable<any> {
        const url = `api/admin/role/activeuser/${appId}/${roleId}`;
        return this._http.get(url, { headers: this.header })
            .pipe(mergeMap((res: any) => {
                if (res.statusCode !== 200) {
                    throwError({ error: res });
                }
                return of(res);
            }),
            catchError((error: any) => {
                if (error.error.errors && error.error.errors.length > 0) {
                    return throwError(error.error.errors[0].validationMessage);
                } else if (error.error) {
                    return throwError(error.error.statusMessage);
                }
            }));
    }

    //LFWM-3692 Method is to check the active user before deleting roles from group managament
    public checkActiveUserfromGroup(appId: number, roleId: number, groupId: number): Observable<any> {
        const url = `api/admin/role/activeuser/${appId}/${roleId}/${groupId}`;
        return this._http.get(url, { headers: this.header })
            .pipe(mergeMap((res: any) => {
                if (res.statusCode !== 200) {
                    throwError({ error: res });
                }
                return of(res);
            }),
                catchError((error: any) => {
                    if (error.error.errors && error.error.errors.length > 0) {
                        return throwError(error.error.errors[0].validationMessage);
                    } else if (error.error) {
                        return throwError(error.error.statusMessage);
                    }
                }));
    }
}


