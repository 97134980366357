import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NavigationService } from '../navigation.service';
import { TokenService } from './token.service';
import { UserInfo } from '../models/user-info.model';
import { LoginResponse } from '../models/login-response.model';
import { UpdatePreferencesRequest } from '../models/update-preferences-request.model';
import { AppStorageService, KEYS_APPSTORAGE } from './app-storage.service';
import { ResourceService } from '../resource.service';
import { SessionTimerService } from './session-timer.service';
import { sceconfig } from '../sce-config';
import { DatePickerConfigService } from '../components/datepicker/datepicker-config.service';

/* Below DatePickerConfigService import is a relative import. cannot import sce-p-lib directly as it will cause cyclic errors*/
/* The above DatePickerConfigService import needs to be fixed by moving the date configuration setup in the
 getEnabledCountries() method to somewhere outside*/
import { DateTimeConfig } from '../models/date-time-config';
import {forkJoin , of} from 'rxjs';
import {catchError,delay, map} from 'rxjs/operators';
import { ConfirmDialogService } from '../components/confirm-dialog/confirm-dialog.service';

export const loginUrls = {
  'DO_LOGIN': 'api/user/login',
  'UPDATE_PASSWORD': 'api/users/password',
  'GET_COUNTRIES': 'api/app/countries',
  'GET_LANGUAGES': 'api/app/languages',
  'UPDATE_PREFS': 'api/users/preferences'
};

/* #TODO-LIBRARY  -- public SearchCriteriaToPrePopulate: {}; // accessed in 3 places in WMS code using .reference
Should be re-referenced dynamically using ['SearchCriteriaToPrePopulate']
*/

@Injectable()
export class UserService {

  public userInfo: UserInfo;
  public billingUserInfo: UserInfo;
  public pomsUserInfo: UserInfo;
  public menurouting;
  public firstLogin: boolean;
  public userEnabledCountries: Array<any>;
  public dateTimeConfig: DateTimeConfig;
  public isExpired: boolean = false;
  public header =Â newÂ HttpHeaders({Â 'api':Â 'admin'Â });
/*  public SearchCriteriaToPrePopulate: {}; // accessed in 3 places in WMS code.,
To remove the dependency it  Has been re-referenced dynamically using ['SearchCriteriaToPrePopulate'] */

  constructor(public http: HttpClient,
              public tokenService: TokenService,
              public appStorageService: AppStorageService,
              public navService: NavigationService,
              public resourceService: ResourceService,
              public sessionTimerService: SessionTimerService,
              public dateConfigService: DatePickerConfigService,
              public confirmDialogService: ConfirmDialogService,
              @Optional() @SkipSelf() parent: UserService) {
     if (parent) {
       // If parent already loaded
       throw new Error(`Tried to initialize singleton service UserService again.`);
     }


    this.userInfo = this.appStorageService.getItem(KEYS_APPSTORAGE.USER_INFO);
    this.billingUserInfo = this.appStorageService.getItem(KEYS_APPSTORAGE.BILLING_USER_INFO);
    this.pomsUserInfo = this.appStorageService.getItem(KEYS_APPSTORAGE.POMS_USER_INFO);
    this.menurouting = this.appStorageService.getItem(KEYS_APPSTORAGE.MENU_ROUTING);
    this.sessionTimerService.initialize(this, this.tokenService);
  }
 /* Authenticates the the user with username and password. Sets the received token through
     tokenService in case of successfull login. Also updates userinfo object. */
     public login(username: string, password: string): Observable<any> {
      return this.http.post(loginUrls.DO_LOGIN, JSON.stringify({ userName: username, password: password, 'appCode': 'wms' }),{Â headers:Â this.header}).pipe(
        // ,{headers:new HttpHeaders({'Content-Type':'application/x-www-form-urlencoded'})})
        map((response: LoginResponse) => {
          // login successful if there's a jwt token in the response
          if (response && response.token) {
		 this.isExpired = response['expired']
	          if (this.isExpired ){
	            return false
          }else{
            // store the token using tokenService
            this.tokenService.setToken(response.userInfo.userName, response.token, response.tokenExpiry);
            // setting user permissions if not empty in userinfo
            if (response.userInfo.userPermissions) {
              response.userInfo.userPermissionsList = this.generatePermissionList(response.userInfo);
            }
            this.userInfo = response.userInfo;
            this.userInfo.photo = response['thumbnailPhoto'];
            this.userInfo.ldapDomain = response['ldapDomain'];
            //LFWM-2161 to set the downtime notification status
            // this is to restrict the notification for one time when user logins
            this.userInfo.isDowntimeInfoDisplayed = false;
            this.userInfo.isExpiryPopup = false;
            /* setting this flag so as to identify whether the user belongs to Supervisor group or not
            Adding this as part of JIRA LFWM-292 Inventory adjustment Screen.
            */
            this.userInfo.isSupervisor = this.validateUserGroupCode(response.userInfo);
            /* Please note - USERINFO object will be modified during update preferences
            call (like language/country switching scenarios). The modified properties are merged in MergedUserInfo object.
            */
            // do not modify userInfo object after this function call, it will not be reflected in app storage
            this.appStorageService.setItem(KEYS_APPSTORAGE.USER_INFO, this.userInfo);
            let showChangePwd = false;
            let showCountryLang = false;
  
            if (response.changePassword && response.userInfo.userType === 'EXTERNAL') {
              showChangePwd = true;
            }
            if (response.userInfo.defaultCountry === null || response.userInfo.defaultLanguage === null) {
              showCountryLang = true;
            }
            // Return the flags to show changepwd/cntry selection screen
            return {
              'showChangePwd': showChangePwd,
              'showCountryLang': showCountryLang
            };
  	}
          } else {
            // return false to indicate failed login
            return false;
          }
        })
        , catchError((error: any) => {
          if (error.error.errors.length !== 0) {
            if (error.error.errors[0].validationCode !== 200) {
              return Observable.throw(this.resourceService.get(error.error.errors['0'].validationMessage));
            }
          }
        }));
    }
  
  // Method used to get the default printer of the login user
  public fetchDefaultPrinter() {
    const response$ = this.http.get('api/users/default/printer',{Â headers:Â this.header}).pipe(
      map((response: any) => {
        this.userInfo.defaultPrinter = response['printer'];
        return this.userInfo.defaultPrinter;
      }));
    return response$;
  }


  // Method used to create array of access privileges corresponds to the user
  public generatePermissionList(userInfo: any) {
    const privilegeCodes = [];
    userInfo.userPermissions.forEach((privilege, index) => {
      privilegeCodes.push(privilege.privilegeCode);
    });
    return privilegeCodes;
  }

  public clearStore(){
    this.tokenService.invalidateToken();
    this.userInfo = null;
    this.appStorageService.removeItem(KEYS_APPSTORAGE.USER_INFO);
  }

  // Invalidates the token using token service and clears all local information.
  public logout() {
    this.jreportLogout();
    this.tokenService.invalidateToken();
    this.userInfo = null;
    this.appStorageService.removeItem(KEYS_APPSTORAGE.USER_INFO);
    this.appStorageService.removeItem(KEYS_APPSTORAGE.BILLING_USER_INFO);
    this.appStorageService.removeItem(KEYS_APPSTORAGE.POMS_USER_INFO);
    this.appStorageService.removeItem(KEYS_APPSTORAGE.MENU_ROUTING);
    this.navService.appInitialized = false;
    // window.location.href = '/wms/';  // Reload  url To clear all application memory for previous user
    // this.navService.navigateByUrl('/login');
    window.location.href = sceconfig.baseUrlUI; // SIT URL changed
  }

  // Invalidates the token using token service and clears all local information.
  public isAuthenticatedUser() {
    return this.tokenService.isAuthenticatedUser();
  }

  // Method to get the default storer value from UserService
  public getUserRestrictions(restrictionType) {
    let userRestrictions = [];
    if (this.userInfo.userRestrictions && this.userInfo.userRestrictions.length !== 0 && this.userInfo.userRestrictions['0'].restrictions) {
      this.userInfo.userRestrictions['0'].restrictions.forEach((restriction) => {
        if (restriction.restrictionTypeName === restrictionType) {
          const allStorerValues = restriction.restrictionValue;
          if (allStorerValues) {
            userRestrictions = allStorerValues.split(',');
          }
        }
      });
    }
    return userRestrictions;
  }

  // Changes the password of currently logged in user by sending the new password in a token authenticated request
  public changePassword(newPassword: string): Observable<{ changePwdStatus: boolean, errorMsg: string }> {
    return this.http.put(loginUrls.UPDATE_PASSWORD, JSON.stringify({ newPassword: newPassword }),{Â headers:Â this.header}).pipe(
      map((response: any) => {
        return { changePwdStatus: response.statusMessage === 'SUCCESS', errorMsg: response.statusMessage };
      }));
  }

  // Retrieves all countries from backend
  public getAllCountries(): Observable<any> {
    return this.http.get(loginUrls.GET_COUNTRIES,{Â headers:Â this.header});
  }

  // Retrieves all languages from backend
  public getAllLanguages(): Observable<any> {
    return this.http.get(loginUrls.GET_LANGUAGES,{Â headers:Â this.header});
  }

  /* Method calls getAllCountries and returns the country array
     after filtering it with Enabled countries of the current user. */
  public getEnabledCountries(): Observable<any> {
    return this.getAllCountries().pipe(map((allCountries) => {
      const enabledCountries = [].concat(this.userInfo.enabledCountries);
      const returnList = [];
      for (const record of allCountries) {
        for (const cntry of enabledCountries) {
          // tslint:disable-next-line:triple-equals
          if (record.countryId == cntry) {
            returnList.push(record);
          }
        }
        // To set timezone and datetime format as per user's current logged in country
        if (record.countryId === this.userInfo.defaultCountry) {
          this.dateConfigService.defaultDateDisplayFormat = record.dateFormat;
          
          this.dateConfigService.defaultTimeDisplayFormat = record.timeFormat;
          this.dateConfigService.defaultTimeZone = record.timeZone;
        }

        /* extracting dateConfigService dependency from core-module
          this needs to be set back into dateConfigService from datepicker component so that it is applied in datepicker component
          in any. The one time re mapping needs to be done in any common base module like home to have a global date formatting
          across the application.
          **** This should also not to be used directly in dateConfigService under SCEDatePickerComponent
        }*/
        this.dateTimeConfig = {
                                defaultDateDisplayFormat: record.dateFormat,
                                defaultTimeDisplayFormat: record.timeFormat,
                                defaultTimeZone: record.timeZone
                              };

      }
      return returnList;
    }));
  }

  /* Method calls service to change the users default language and country.
     languageId, countryID, Usertype is mandatory. */
  public updatePreferences(countryId: number, languageId: number): Observable<{}> {
    const userType: string = this.userInfo.userType.toLowerCase() === 'external' ? '1' : '0';
    const updatePreferencesRequest: UpdatePreferencesRequest = new UpdatePreferencesRequest();
    updatePreferencesRequest.appCode = 'wms';
    updatePreferencesRequest.countryCode = '';
    updatePreferencesRequest.countryId = countryId;
    updatePreferencesRequest.languageCode = '';
    updatePreferencesRequest.languageId = languageId;
    updatePreferencesRequest.userType = userType;

    return this.http.put(loginUrls.UPDATE_PREFS,
      JSON.stringify(updatePreferencesRequest),{Â headers:Â this.header}).pipe(
      map((response: LoginResponse) => {
        this.tokenService.setToken(response.userInfo.userName, response.token, response.tokenExpiry);
        const mergedUserInfo: UserInfo = this.appStorageService.getItem(KEYS_APPSTORAGE.USER_INFO);
        // FOLLOWING 4 PROPERTIES CHANGE IN UPDATEPREF RESPONSE
        // Only merge new properties thar are changed in update prefernce response
        mergedUserInfo.defaultCountry = response.userInfo.defaultCountry;
        mergedUserInfo.defaultLanguage = response.userInfo.defaultLanguage;
        mergedUserInfo.userPermissions = response.userInfo.userPermissions;
        mergedUserInfo.userRestrictions = response.userInfo.userRestrictions;
        mergedUserInfo.userPermissionsList = this.generatePermissionList(response.userInfo);
        mergedUserInfo.isSupervisor = this.validateUserGroupCode(response.userInfo);
        this.appStorageService.setItem(KEYS_APPSTORAGE.USER_INFO, mergedUserInfo);
        this.userInfo = mergedUserInfo;
        return {
          status: response.statusMessage === 'SUCCESS',
          errorMsg: response.statusMessage,
          defaultCountry: response.userInfo.defaultCountry,
          defaultLanguage: response.userInfo.defaultLanguage,
          baseUrl: response.baseUrl
        };
      }));
  }
  // method used to validate whether the user is a Supervisor or Non-Supervisor
  public validateUserGroupCode(userInfo: any): boolean {
    if (userInfo && userInfo.userGroupCodes && userInfo.userGroupCodes.length > 0) {
      const supervisorsList = userInfo.userGroupCodes.filter((groupCode) => {
        return groupCode === 'Supervisor';
      });
      if (supervisorsList.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  // below method is extracted from shared/button/button-factory-component.ts for library extraction purpose
  public checkButtonPrivilege(privilegeCode: String) {
    let enableButton = false;
    if (this.appStorageService.getItem(KEYS_APPSTORAGE.USER_INFO)['userPermissionsList'].indexOf(privilegeCode) !== -1) {
      enableButton = true;
    }
   // console.log(privilegeCode);
    return enableButton;
  }
  /*
  public checkButtonPrivilege(moduleName: String, privilegeCode: String) {
  let enableButton = false;
  this.appStorageService.getItem('user_info')['userPermissions'].forEach((privilege, index) => {
    if (privilege.moduleName === moduleName && privilege.privilegeCode === privilegeCode) {
      enableButton = true;
    }
  });
  return enableButton;
}
*/

public jreportLogout() {

  let jreportServerUrl;
  switch (window.location.hostname) {
    case sceconfig.wms.wmsProd:
       jreportServerUrl = sceconfig.wms.wmsJreportBaseProd;
      break;
    case sceconfig.wms.wmsPreProd:
        jreportServerUrl = sceconfig.wms.wmsJreportBasePreProd;
       break; 
    case sceconfig.wms.wmsUat:
      jreportServerUrl = sceconfig.wms.wmsJreportBaseUat;
      break;
    case sceconfig.wms.wmsAliCloudProd:
      jreportServerUrl = sceconfig.wms.wmsJreportAliCloudProd;
      break;
    case sceconfig.wms.wmsAliCloudUat:
      jreportServerUrl = sceconfig.wms.wmsJreportAliCloudUat;
      break;
    case sceconfig.wms.wmsAliCloudSit:
      jreportServerUrl = sceconfig.wms.wmsJreportAliCloudUat;
      break;  
    default:
      jreportServerUrl = sceconfig.wms.wmsJreportBaseUrl;
      break;

  }
 const response$ = this.http.delete(sceconfig.wms.wmsJreportBaseUrl + '/jrserver/api/v1.2/session').pipe(
  map((response: any) => {
  }),catchError((error: HttpErrorResponse) => {
    return Observable.throw(error);
  })).subscribe();
  return response$;
}
public getBillingUserInfo() {
  let billingUserInfo = {};
  if (this.appStorageService.getItem(KEYS_APPSTORAGE.BILLING_USER_INFO) &&
    this.appStorageService.getItem(KEYS_APPSTORAGE.BILLING_USER_INFO)) {
    billingUserInfo = this.appStorageService.getItem(KEYS_APPSTORAGE.BILLING_USER_INFO)
  }
  return billingUserInfo
}

  public getPomsUserInfo() {
    let pomsUserInfo = {};
    if (this.appStorageService.getItem(KEYS_APPSTORAGE.POMS_USER_INFO) &&
      this.appStorageService.getItem(KEYS_APPSTORAGE.POMS_USER_INFO)) {
      pomsUserInfo = this.appStorageService.getItem(KEYS_APPSTORAGE.POMS_USER_INFO)
    }
    return pomsUserInfo
  }

  // LFWM-2161 Get all the down time notifications
  public getUsersDownTimeNotifications(){
    const url = 'api/app/downtime/fetch';
    const response$ = this.http.get(url,{Â headers:Â this.header}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return Observable.throw(error.error.statusMessage);
          }
        }
      }));
    return response$;
  }

  public getUserPreferences(){
    const response$ = this.http.get(loginUrls.UPDATE_PREFS, { headers: this.header }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return Observable.throw(error.error.statusMessage);
          }
        }
       }));
    return response$;
  }

    //GC-219 Expiry Indication Popup for the External Users.
    public showExpiryPopup() {
      let msgList = this.resourceService.get('password_expiry_notif');
      let expirationDate;
      let currentDate = new Date();
      const userInfo: UserInfo = this.appStorageService.getItem(KEYS_APPSTORAGE.USER_INFO);
      if (!userInfo.isExpiryPopup) {
            expirationDate = this.userInfo.userExpiryDate;
            if (expirationDate != null) {
              var date1 = new Date(expirationDate);
              // To calculate the time difference of two dates
              var Difference_In_Time = date1.getTime() - currentDate.getTime();
              // To calculate the no. of days between two dates
              var Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));
              if (Difference_In_Days <= 30 && !isNaN(Difference_In_Days) && (Math.sign(Difference_In_Days) === 1)) {
                this.confirmDialogService.okDialog(msgList)
                  .subscribe(() => {
                    userInfo.isExpiryPopup = true;
                    this.appStorageService.setItem(KEYS_APPSTORAGE.USER_INFO, userInfo);
                  });
              } else {
                userInfo.isExpiryPopup = true;
                this.appStorageService.setItem(KEYS_APPSTORAGE.USER_INFO, userInfo);
              }
            }
      }
  
    }
  
}
