import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {forkJoin , of} from 'rxjs';
import {catchError,delay, map} from 'rxjs/operators';

@Injectable()
export class ResourceService {

  public appId;
  public languageId;
  public resourceMapByStorer = {};
  public defaultBundle: any = {};
  public selectedRuleValCompare = [];
  public termRuleSeqVal = [];
  public priceSeqDraftVal = [];
  public priceSeqPendingVal = [];
  public priceSeqRevisionVal = [];
  public termSeqRevisionVal = [];
  public selectedRuleValRevision = [];
  // LFWM-953 Adding this variable for setting the delimiter to be used
  public delimiter: any = /\n/;
  public defaultStorer: string = null;

  constructor(public http: HttpClient, @Optional() @SkipSelf() parent: ResourceService) {
      if (parent) {
        // If parent already loaded
        throw new Error(`Tried to initialize singleton service ResourceService another time.`);
      }
   }

  public loadResourceBundle(appId: number) {
    if (!appId) {
      appId = 1;
    }
    this.appId = appId;
    const hdr = new HttpHeaders({ 'api': 'admin' });
    return this.http.get('api/resource/' + appId + '/bundle',{headers : hdr}).pipe(map((resourceBundle) => {
       if (resourceBundle) {
        this.saveResourceBundle(resourceBundle);
      }
    }));
  }

  public saveResourceBundle(resourceBundleResponse) {
    this.resourceMapByStorer = {};
    this.appId = resourceBundleResponse.appId;
    this.languageId = resourceBundleResponse.languageId;
    this.defaultBundle = {};
    if (resourceBundleResponse.resourceList) {
      resourceBundleResponse.resourceList.forEach((resource) => {
        let resourceBundle = null;
        if (resource.storer) {
          resourceBundle = this.resourceMapByStorer[resource.storer];
          if (!resourceBundle) {
            this.resourceMapByStorer[resource.storer] = {};
            resourceBundle = this.resourceMapByStorer[resource.storer];
          }
        } else {
          resourceBundle = this.defaultBundle;
        }

        resourceBundle[resource.elementId] = resource.displayValue;
      });
    }
  }

  public get(key) {
    return this.getValue(key, key);
  }

  public getBundleForStorer() {
    return this.resourceMapByStorer[this.defaultStorer];
  }

  public getValue(key, defaultVal) {
    let bundle = this.getBundleForStorer();
    let value = null;
    if (bundle) {
      value = bundle[key];
      if (value) {
        return value;
      }
    }
    bundle = this.defaultBundle;
    value = bundle[key];
    if (value) {
      return value;
    }
    return defaultVal;
  }

  // LFWM-953 Method used to handle Delimiter for the display as multi line messages
  // this method can be used for translating the messages from the server responses
  public translateServerResponse(key: any): any {
    const multiKeys: any = [];
    if (key) {
      if (key.split(this.delimiter)) {
        key.split(this.delimiter).forEach((multiKey: any) => {
          multiKeys.push(this.get(multiKey));
        });
      }
    }
    return multiKeys;
  }

}
