import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../components/alerts/alert.service';
import { ModalService } from '../../components/modal/modal.service';
import { ResourceService } from '../../resource.service';
import { UserService } from '../../security/user.service';
import { PrinterSetupService } from './printer-setup.service';


@Component({
  selector: 'lfwms-printer-setup',
  templateUrl: './printer-setup.component.html',
  styleUrls: ['./printer-setup.component.css'],
  providers: [PrinterSetupService]
})
export class PrinterSetupComponent implements OnInit {

  public defaultPrinters: any;
  public selectedRDTPrinter: string = null;
  public selectedPaperPrinter: string = null;
  public userRDTPrinter: String;
  public userPaperPrinter: String;
  public userPrinterGroup: String;
  public userName: String;
  public printerGroups:any;
  public selectedPrinterGroup: string = null;
  public selectedPrinterGroupVal: string = null;

  constructor(public userService: UserService,
    public resourceService: ResourceService,
    public modalService: ModalService,
    public alertService: AlertService,
    public printerSetupService: PrinterSetupService) {
  }

  public ngOnInit() {
    // Invoking fetchDefaultPrinter() is used to populate default printers in the dropdown
    //this.fetchDefaultPrinters();
    this.userName = this.userService.userInfo.userName;
    this.fetchPrinterByUSerName();
  }

  // method used to translate the labels
  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  /* This method is used to populate default printers in the dropdown */
// 2250- added change for fetching default printers by printer group
  public fetchDefaultPrinters() {
    this.printerSetupService.fetchDefaultPrintersbyPrinterGroup(this.selectedPrinterGroupVal)
      .subscribe((resp: any) => {
        if (resp['statusCode'] && resp['statusMessage']) {
          if (resp['statusCode'] === 200 && resp['statusMessage'] === 'SUCCESS') {
            this.defaultPrinters = resp['printerDetails'];
          }
        } else {
          if (resp.error) {
            this.alertService.clearAll();
            this.alertService.error(this.resourceService.translateServerResponse(resp.error));
          }
        }
      }, (error) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
  }
    // LFWM-2250 - added change for fetching default printers by printer group
 public fetchPrinterGroup() {
  this.printerSetupService.fetchPrinterGroup()
  .subscribe((resp: any) => {
    console.log('resp', resp );
        this.printerGroups = resp;
        const wildcardClausePresent: boolean
        // 2250- add all to the list
        = this.printerGroups
            .filter((keyValueObject: any) => {
                return keyValueObject === 'ALL';
            }).length > 0;
            if (!wildcardClausePresent && this.printerGroups[0] === '') {
              this.printerGroups[0] = 'ALL';
          } else if(!wildcardClausePresent){
            this.printerGroups.splice(0,0,'ALL');
            this.printerGroups.join();
          }
        this.fetchDefaultPrinters();
  }, (error) => {
    this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
  });
}
  /*This method is used to save default printer corresponds with the user  */
  public saveDefaultPrinter() {
    const defaultPrinterRequestObj = {};
    defaultPrinterRequestObj['labelPrinter'] = this.selectedRDTPrinter;
    defaultPrinterRequestObj['paperPrinter'] = this.selectedPaperPrinter;
    // 2250- if all or null is selected, empty is passed
    if(this.selectedPrinterGroup === 'ALL' || this.selectedPrinterGroup === null)
    {
      defaultPrinterRequestObj['printerGroup'] = '';
    } else{
      defaultPrinterRequestObj['printerGroup'] = this.selectedPrinterGroup;
    }
    if (this.validateEmptyPrinter()) {
      if (this.validatePrinterValues()) {
        if (this.validateSave()) {
          this.printerSetupService.SaveDefaultPrinters(defaultPrinterRequestObj)
            .subscribe((resp: any) => {
              if (resp['statusCode'] && resp['statusMessage']) {
                if (resp['statusCode'] === 200 && resp['statusMessage'] === 'SUCCESS') {
                  this.alertService.clearAll().success(this.resourceService.get('msg_prntr_success'));
                  this.userRDTPrinter = this.selectedRDTPrinter;
                  this.userPaperPrinter = this.selectedPaperPrinter;
                  this.userPrinterGroup = this.selectedPrinterGroup;
                  if (this.userService.userInfo.defaultPrinter) {
                    this.userService.userInfo.defaultPrinter.labelPrinter = this.selectedRDTPrinter;
                    this.userService.userInfo.defaultPrinter.paperPrinter = this.selectedPaperPrinter;
                  }
                }
              } else {
                if (resp.error) {
                  this.alertService.clearAll();
                  this.alertService.error(this.resourceService.translateServerResponse(resp.error));
                }
              }
            }, (error) => {
              this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
            });
        } else {
          this.alertService.clearAll().error(this.resourceService.get('msg_val_save_print_exist'));
        }
      } else {
        this.alertService.clearAll().error(this.resourceService.get('msg_val_save'));
      }
    } else {
      this.alertService.clearAll().error(this.resourceService.get('msg_val_print_initial'));
    }
  }
  /* This method will fetch default printers associated with the user */
  public fetchPrinterByUSerName() {
    this.printerSetupService.fetchPrinterByUSerName()
      .subscribe((resp: any) => {
        if (resp['statusCode'] && resp['statusMessage']) {
          if (resp['statusCode'] === 200 && resp['statusMessage'] === 'SUCCESS') {
            this.selectedRDTPrinter = resp['printer'].labelPrinter;
            this.selectedPaperPrinter = resp['printer'].paperPrinter;
            this.userRDTPrinter = resp['printer'].labelPrinter;
            this.userPaperPrinter = resp['printer'].paperPrinter;
            this.selectedPrinterGroup = resp['printer'].scePrinterGroup;
            if(this.selectedPrinterGroup === '' || this.selectedPrinterGroup === null){
              this.selectedPrinterGroup = 'ALL';
              this.selectedPrinterGroupVal = ' ';
              // 2250- added change for fetching default printers by printer group
              this.userPrinterGroup = this.selectedPrinterGroup;
            } else{
              this.selectedPrinterGroupVal = this.selectedPrinterGroup;
              this.userPrinterGroup = this.selectedPrinterGroupVal;
            }
           
            this.fetchPrinterGroup();
          }
        } else {
          if (resp.error) {
            this.alertService.clearAll();
            this.alertService.error(this.resourceService.translateServerResponse(resp.error));
          }
        }
      }, (error) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
  }

  /* This method is used to close the modal window */
  public closePrinterSetupModal() {
    this.modalService.modalReference.hide();
  }

  /* This method will validate, selected printers are already available to the user or not */
  public validatePrinterValues() {
    this.alertService.clearAll();
    // 2250- added change for fetching default printers by printer group
    if (this.userRDTPrinter !== this.selectedRDTPrinter || this.userPaperPrinter !== this.selectedPaperPrinter 
      || this.userPrinterGroup !== this.selectedPrinterGroup) {
      return true;
    } else {
      return false;
    }
  }


  /* This method will validate whether user will have any printer assigned or not  */
  public validateEmptyPrinter() {
    this.alertService.clearAll();
    if (this.userRDTPrinter === this.selectedRDTPrinter && this.userPaperPrinter === this.selectedPaperPrinter &&
      this.userRDTPrinter === null && this.selectedRDTPrinter === null && this.userPaperPrinter === null &&
      this.selectedPaperPrinter === null) {
      return false;
    } else {
      return true;
    }
  }
  /* This method will check whether we are overriding default printer value with null
  If we are trying to override default printer value with null value - validation will fail */
  public validateSave() {
    if (this.userRDTPrinter !== null && this.selectedRDTPrinter === null) {
      return false;
    } else if (this.userPaperPrinter !== null && this.selectedPaperPrinter === null) {
      return false;
    } else {
      return true;
    }
  }

  /* Method will invoke while selecting printer from dropdown */
  public onChange() {
    this.alertService.clearAll();
  }
  // 2250- added change for fetching default printers by printer group
  public onChangePrinterGroup() {
    if(this.selectedPrinterGroup === null || this.selectedPrinterGroup === 'ALL'){
      this.selectedPrinterGroupVal = ' ';
    } else{
      this.selectedPrinterGroupVal = this.selectedPrinterGroup;
    }

    this.fetchDefaultPrinters();
  
    this.alertService.clearAll();
  }
}
