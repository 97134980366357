import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { ConfirmDialogService } from '../components/confirm-dialog/confirm-dialog.service';
import { LoggerService } from '../logger.service';
import { TokenService } from './token.service';
import { ResourceService } from '../resource.service';
import { UserService } from './user.service';
import { timer } from 'rxjs';

@Injectable()
export class SessionTimerService {
    // 
    public AUTO_REFRESH_BUFFER: number = 3 * 60 * 1000; // Buffer time in millis for auto refresh on mousemove
    // 5
    public MANUAL_REFRESH_BUFFER: number = 5 * 60 * 1000; // Buffer time in millis for manual refresh once auto_timer expiress
    // private AUTO_REFRESH_BUFFER: number = 5 * 60 * 1000; // Buffer time in millis for auto refresh on mousemove
    // private MANUAL_REFRESH_BUFFER: number = 15 * 60 * 1000; // Buffer time in millis for manual refresh once 

    public tokenService: TokenService;
    public userService: UserService;

    public isAutoRefreshIntervalActive: boolean;
    public autoRefreshTimer: Subscription;
    public manualRefreshTimer: Subscription;
    public tokenExpiryTimer: Subscription;

    constructor(public confirmDialog: ConfirmDialogService,
        public resourceService: ResourceService,
        public _logger: LoggerService,
        @Optional() @SkipSelf() parent: SessionTimerService) {
         if (parent) {
           // If parent already loaded
           throw new Error(`Tried to initialize singleton service SessionTimerService again.`);
         }
     }

    public initialize(userService: UserService, tokenService: TokenService) {
        this.userService = userService;
        this.tokenService = tokenService;
    }

    public start() {
        if (!this.userService || !this.tokenService) {
            this._logger.log('Dependant services are not initialized.');
            return;
        }

        // this._logger.log('Starting timers');
        // reset both stored timers
        if (this.autoRefreshTimer) {
            this.autoRefreshTimer.unsubscribe();
        }
        if (this.manualRefreshTimer) {
            this.manualRefreshTimer.unsubscribe();
        }
        if (this.tokenExpiryTimer) {
            this.tokenExpiryTimer.unsubscribe();
        }
        // reset buffer flag for disabling mousemove refresh
        this.isAutoRefreshIntervalActive = false;
        // 15-(3+5) = 7 mins
        const timeToAutoRefresh = this.tokenService.getMillisecondsToExpiry() - (this.AUTO_REFRESH_BUFFER + this.MANUAL_REFRESH_BUFFER);
        // this._logger.log('Auto Refresh timeout will start in', timeToAutoRefresh);
        this.autoRefreshTimer = timer(timeToAutoRefresh).subscribe(() => {
            // this._logger.log('Auto Refresh Token started');
            this.isAutoRefreshIntervalActive = true;
            const timeToManualRefresh = this.tokenService.getMillisecondsToExpiry() - this.MANUAL_REFRESH_BUFFER;
            this.manualRefreshTimer = timer(timeToManualRefresh).subscribe(() => {
                // reset buffer flag for disabling mousemove refresh. i.e only manual after this point
                this.isAutoRefreshIntervalActive = false;
                this._logger.log('Auto Refresh Token ended');
                this._logger.log('manualRefreshTimer started for ', timeToManualRefresh);
                this._logger.log('manual logout will happen in ', this.tokenService.getMillisecondsToExpiry());
                this.tokenExpiryTimer = timer(this.tokenService.getMillisecondsToExpiry()).subscribe(() => {
                    this.confirmDialog.okDialog(this.resourceService.get('tokn_exprd')).subscribe(() => {
                        this.userService.logout();
                        // window.location.href = '/wms/'; // Reload  url To clear all application memory for previous user
                    });
                    // alert('Session Expired !');
                    // window.location.href = '/wms/'; // Reload  url To clear all application memory for previous user
                });
                this.confirmDialog.confirmDialog([this.resourceService.get('tokn_exp_by') + ' '
                    // +  Math.floor(this.getMillisecondsToExpiry() / 60000)
                    + new Date(this.tokenService.getTokenExpirationDate()).toLocaleTimeString()
                    + '', this.resourceService.get('tokn_extnd')])
                    .subscribe((confirmed) => {
                        if (confirmed) {
                            if (this.tokenService.getMillisecondsToExpiry() > 10) {
                                // if there is time left to make a refresh call do it.
                                this.tokenService.refreshToken();
                            } else {
                                // *need to re look at the if condition
                                // this.navService.navigateByMenuId('home');
                            }
                        }
                        //
                    });
            });
        });
    }
}
