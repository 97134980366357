import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../navigation.service';
import { ResourceService } from '../resource.service';

@Component({
  selector: 'lfwms-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {

  constructor(  public navService: NavigationService,
    public resourceService: ResourceService,
    public activatedRoute: ActivatedRoute) { }
 public  ngOnInit() {
  this.activatedRoute.params.subscribe(params => {
    this.navService.setPageTitle(this.resourceService.get('lbl_admin_home'));
  });
  }

}
