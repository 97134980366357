import moment from 'moment';
import { DatePickerConfigService } from '../../../components/datepicker/datepicker-config.service';
import { ModalService } from '../../../components/modal/modal.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResourceService } from '../../../resource.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Component({
  selector: 'lfwms-maintenance-notice-popup',
  templateUrl: './maintenance-notice-popup.component.html',
  styleUrls: ['./maintenance-notice-popup.component.css']
})
export class MaintenanceNoticePopupComponent implements OnInit {

  public eventSubject = new Subject<any>();
  public downTimeList: any = [];
  public noticeList: any = [];

  constructor(public modalService: ModalService,
    private resourceService: ResourceService,
    private dateConfigService: DatePickerConfigService,) {
    this.eventSubject = new Subject<any>();
  }
  ngOnInit() { }

  public initializeComponents(downtimeList: any) {
    this.noticeList = [];
    this.downTimeList = downtimeList;
    if (this.downTimeList.length > 0) {
      for (const item of this.downTimeList) {
        let finalDate = moment(new Date(item.downTimeEnd), 'DD/MM/YYYY HH:mm:ss');
        let initialDate = moment(new Date(item.downTimeStart), 'DD/MM/YYYY HH:mm:ss');
        let duration = moment.duration(finalDate.diff(initialDate));
        let hours = Math.floor(duration.asHours()) + moment.utc(finalDate.diff(initialDate)).format(':mm:ss');
        let editableMessage = item.downTimeMessage.substr(item.downTimeMessage.indexOf('hours') + 5);
        const data = {
          finalDate: this.dateConfigService.getFormattedDisplayDateTime(finalDate),
          initialDate: this.dateConfigService.getFormattedDisplayDateTime(initialDate),
          hours: hours,
          editableMessage: editableMessage
        };
        this.noticeList.push(data);
      }
    }

  }
  // to get the display value of corresponding key from the resource bundle
  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  public notifyEvent(value: any) {
    this.eventSubject.next(value);
  }

  public receiveEvent(): Observable<any> {
    return this.eventSubject.asObservable();
  }
  public giveResponse() {
    const populateEvent = {
      'type': 'Ok'
    };
    this.notifyEvent(populateEvent);
    this.modalService.modalReference.hide();
  }
}