import { Injectable, SkipSelf, Optional } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRouteSnapshot, Params, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { menuIdRouteMappings, menuIdAppMappings } from './constants';
import { ResourceService } from './resource.service';
import { AlertService } from './components/alerts/alert.service';
import { BreadCrumbItem } from './models/breadcrumb-item';
import { sceconfig } from './sce-config';
import { AppStorageService, KEYS_APPSTORAGE } from './security/app-storage.service';
import {forkJoin , of} from 'rxjs';
import {catchError,delay, map,filter} from 'rxjs/operators';
import { ConfirmDialogService } from './components/confirm-dialog/confirm-dialog.service';
import { domainName, router } from './components/dispatcher-iframe/menus';

// Service for displaying Breadcrumbs, Main Menu, Menu ID based Traversal
@Injectable()
export class NavigationService {

  public currentMenuIdSubject: BehaviorSubject<string>;
  public menuIdbreadcrumbsMapping = [];
  public currentBreadCrumb: BreadCrumbItem[];
  public rootBreadCrumb: BreadCrumbItem;
  public appInitialized: boolean = false;
  public preventNavigation: boolean = false;
  public retainAlertsOnNavigation: boolean = false;
  public homeLabel: string = 'Home';
  // public currentMenuId: string;
  public latestSnapshot: ActivatedRouteSnapshot;
  public header = new HttpHeaders({ 'api': 'admin' });
  public currentApplication: string = 'wms';
  public urlNavigationKey: string = '';
  public directUrlNavigation = null;
  
  public accessibleMenuRouting = {menuRoutings: {}, routings: []};
  private isRemoveBreadcrumb = new BehaviorSubject<boolean>(false);


  constructor(public router: Router, public http: HttpClient,
    public resourceService: ResourceService, public confirmDialogService: ConfirmDialogService,
    public activatedRoute: ActivatedRoute,
    public titleService: Title,
    public alertService: AlertService,
    public appStorageService: AppStorageService,
    @Optional() @SkipSelf() parent: NavigationService) {
    if (parent) {
      // If parent already loaded
      throw new Error(`Tried to initialize singleton service NavigationService again.`);
    }

    this.rootBreadCrumb = { id: 'home', displayValue: this.resourceService.get(this.homeLabel), route: '/home' };
    // this.currentBreadCrumb=[this.rootBreadCrumb];
    /* Do proper nullchecks or Initialise used variables before
       initialising a BehaviorSubject as it triggers all receivers instantly.
       Initialise with id-->'home' */
    this.currentMenuIdSubject = new BehaviorSubject<string>('home');

    this.getInnerRouteSnapshotAfterNavigation()
      .subscribe(snapshot => {
        if (this.retainAlertsOnNavigation) {
          // do not clear alersts and switch off retainAlerts for next navigation
          this.retainAlertsOnNavigation = false;
        } else {
          // default -clear all alert messages on NavigationEnd if retainAlerts switched off
          this.alertService.clearAll();
        }
        // set title and update latest snapshot
        this.latestSnapshot = snapshot;
        this.titleService.setTitle(this.resourceService.get(snapshot.data.title ? snapshot.data.title : ''));
      }
      );

  }

  /* Method to initialize all application data that needs to be loaded
  before home loads, and then navigates to home. Invoked from rootAuthGuard if(!appInitialized) */
  public initAppDataAndReload(url: string = 'login') {
    this.appInitialized = false;
    const resourceBundle = this.resourceService.loadResourceBundle(1);
      return forkJoin(resourceBundle)
      .subscribe(() => {
        this.appInitialized = true;
        // this.navigateByMenuId('home');
        this.navigateByUrl(url);
      }
      );

       // added for testing purposes
       this.getAppPrivRestriction(5).subscribe(resp => {
        //window.location.href = window.location.origin + this.urlNavigationKey + url;
      }, (error2) => {
        console.log('BillingPrivilegeError', error2);
      });

      this.getAppPrivRestriction(6).subscribe(resp => {
        //window.location.href = window.location.origin + this.urlNavigationKey + url;
      }, (error2) => {
        console.log('PomsPrivilegeError', error2);
      });
  }

  /* Returns the observable for subscribing to any menu changes.
     Used in breadcrumb/menu component. */
  public getNavigatedMenuIdBroadcast(): Observable<string> {
    return this.currentMenuIdSubject.asObservable();
  }

  /* Please ensure url navigations are done using method ::
     NavigationService.navigateByMenuId(id) instead of router.navigateByUrl(url);
    >> Calls router.navigateByUrl(url) for the given menuId.
    >> Updates the new breadcrumb.
    >> Emits new Menu ID Change Event
    >> Returns boolean for navigation Success/Failure */
  public navigateByMenuId(newMenuId: string, skipLocationChange?: boolean) {
    const url = menuIdRouteMappings[newMenuId]; // || '/home'
    if (url) {
      if (this.preventNavigation) {
        const dialogMsg = [`<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`, this.resourceService.get('mn_nav_confirm_msg')];
        this.confirmDialogService.confirmDialog(dialogMsg)
          .subscribe((isConfirmed) => {
            if (!isConfirmed) {
              return false;
            }
            this.preventNavigation = false;
            //this.router.navigateByUrl(url, { skipLocationChange: skipLocationChange, replaceUrl: true });
            this.navigateToOtherApplication(newMenuId, skipLocationChange);
            // Update Breadcrumbs if Navigation SUCCESS
            const breadCrumbMapping = this.menuIdbreadcrumbsMapping[newMenuId];
            this.currentBreadCrumb = breadCrumbMapping ? [].concat(breadCrumbMapping) : this.currentBreadCrumb;
            this.currentMenuIdSubject.next(newMenuId);
            return true; // Navigation Success
          });
        return;
      } else {
        return this.navigateToOtherApplication(newMenuId, skipLocationChange);
      }
    } else {
      return false; // Navigation Failure
    }
  }
  //function to handle mutiple application navigation
  public navigateToOtherApplication(newMenuId: string, skipLocationChange?: boolean) {
    let url = menuIdRouteMappings[newMenuId]; // || '/home'
    const applicationToNavigate = menuIdAppMappings[newMenuId]; // || 'wms||gvt';
    this.setCurrentApplicationBasedOnUrl();
    this.setApplicationUrlNavigationBase(applicationToNavigate);
    if (this.currentApplication != applicationToNavigate) {
      if (url === '/home') {
        url = '/home/';
      }
      if (applicationToNavigate === 'billing') {
        this.getAppPrivRestriction(5).subscribe(resp => {
          window.location.href = window.location.origin + this.urlNavigationKey + url;
        }, (error2) => {
          console.log('BillingPrivilegeError', error2);
        });
      } else if (applicationToNavigate === 'poms') {
        this.getAppPrivRestriction(6).subscribe(resp => {
          window.location.href = window.location.origin + this.urlNavigationKey + url;
        }, (error2) => {
          console.log('POMSPrivilegeError', error2);
        });
      } else if (applicationToNavigate === 'dataloader') {
        this.getAppPrivRestriction(3).subscribe(resp => {
          window.location.href = window.location.origin + this.urlNavigationKey + url;
        }, (error2) => {
          console.log('DATALOADERPrivilegeError', error2);
        });
      } else {
        window.location.href = window.location.origin + this.urlNavigationKey + url;
      }
    } else {
      this.router.navigateByUrl(url, { skipLocationChange: skipLocationChange, replaceUrl: true });
      // Update breadcrumbs if navigation success
      const breadCrumbMapping = this.menuIdbreadcrumbsMapping[newMenuId];
      this.currentBreadCrumb = breadCrumbMapping ? [].concat(breadCrumbMapping) : this.currentBreadCrumb;
      this.currentMenuIdSubject.next(newMenuId);
      return true; // Navigation Success
    }
  }
  //Set the current application by checking the current location path
  //if the path has gvt then the user is currently in gvt application
  //else in wms
  // TODO : UPDATE the function based on adding new application
  public setCurrentApplicationBasedOnUrl() {
    if (window.location.pathname.includes(sceconfig.gvt.baseUrlUI)) {
      this.currentApplication = 'gvt';
    }else if (window.location.pathname.includes(sceconfig.dashboard.baseUrlUI)) {
      this.currentApplication = 'dashboard';
    } else if (window.location.pathname.includes(sceconfig.billing.baseUrlUI)) {
      this.currentApplication = 'billing';
    } else if (window.location.pathname.includes(sceconfig.poms.baseUrlUI)) {
      this.currentApplication = 'poms';
    } else if (window.location.pathname.includes(sceconfig.ltpoms.baseUrlUI)) {
      this.currentApplication = 'ltpoms';
    } else if (window.location.pathname.includes(sceconfig.notification.baseUrlUI)) {
      this.currentApplication = 'notification';
    } else if (window.location.pathname.includes(sceconfig.dataloader.baseUrlUI)) {
      this.currentApplication = 'dataloader';
    } else {


      this.currentApplication = 'wms';
    }
    return true;
  }
  //set the application base based on the application to navigate
  // TODO ADD more case when adding new application
  public setApplicationUrlNavigationBase(applicationToNavigate) {
    switch (applicationToNavigate) {
      case 'gvt':
        this.urlNavigationKey = '/gvt';
        break;
      case 'dashboard':
        this.urlNavigationKey = '/dashboard';
        break;
      case 'billing':
        this.urlNavigationKey = '/billing';
        break;
      case 'poms':
        this.urlNavigationKey = '/poms';
        break;
      case 'dataloader':
        this.urlNavigationKey = '/dataloader';
        break;
      case 'ltpoms':
          this.urlNavigationKey = '/ltpoms';
          break;  
      case 'notification':
          this.urlNavigationKey = '/notification';
          break;
      default:
        this.urlNavigationKey = '';
    }
  }

  public navigateByUrl(url: string, skipLocationChange?: boolean, menuId?: string) {
    if (url) {
      if (this.preventNavigation) {
        const dialogMsg = [`<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`, this.resourceService.get('mn_nav_confirm_msg')];
        this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg)).subscribe((isConfirmed) => {
          if (!isConfirmed) {
            return false;
          }
          this.preventNavigation = false;
          this.isRemoveBreadcrumb.next(true);
          this.router.navigateByUrl(url, { skipLocationChange: skipLocationChange, replaceUrl: true });
        });
        return;
      }
      
      if(url === '/home/my-profile') {
        this.currentMenuIdSubject.next('my-profile');
        }
      if(url === '/home/admin/tms') {
          //this.currentMenuIdSubject.next('dispatcher-iframe');
          this.currentBreadCrumb = []
          this.currentMenuIdSubject.next('tms');   
          if(document.getElementById('dispactherIframe') !== null){
            let jwt_token = this.appStorageService.getItem('jwt_token')
            let domain;
            let host = window.location.origin;
            if(host.includes('lflogistics.net')) {
              if (host.includes('dev')) {
                domain = domainName.dev;
              } else if (host.includes('uat')) {
                domain = domainName.uat;
              } else if (host.includes('sit')) {
                domain = domainName.sit;
              }else {
                domain = domainName.prod;
              } 
            } else if(host.includes('ocf.fulfillment')) {
              if(host.includes('cdt')) {
                domain = domainName.dev_ocf_tms;
              } else if(host.includes('sit')) {
                domain = domainName.sit_ocf_tms;
              } else if (host.includes('stage')) {
                domain = domainName.uat_ocf_tms;
              } else {
                domain = domainName.prod_ocf_tms;
              }
            } else if (host.includes('localhost')) {
              domain = domainName.prod_ocf_tms;
            } else {
              domain = domainName.prod_ocf_tms;
            }
           
            var urls = domain + router[menuId] + '?access_token=' + jwt_token['token'] + '&userName=' + jwt_token.username;
            document.getElementById('dispactherIframe')['src'] = urls;
           
        }                
      }
      this.isRemoveBreadcrumb.next(true);
      this.router.navigateByUrl(url, { skipLocationChange: skipLocationChange, replaceUrl: true });
      // this.currentMenuIdSubject.next(newMenuId);
      return true; // Navigation Success
    } else {
      return false; // Navigation Failure
    }
  }

  public getRemoveBredcrumbStatus(){
    return this.isRemoveBreadcrumb.asObservable();
  }

  // Use the below method for navigating with optional queryParams
  public navigate(path: string, params?: {}) {
    if (path) {
      if (this.preventNavigation) {
        const dialogMsg = [`<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`, this.resourceService.get('mn_nav_confirm_msg')];
        this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg)).subscribe((isConfirmed) => {
          if (!isConfirmed) {
            return false;
          }
          this.preventNavigation = false;
          this.router.navigate([path], { queryParams: params, replaceUrl: true });
        });
        return;
      }
      this.router.navigate([path], { queryParams: params, replaceUrl: true });
      // this.currentMenuIdSubject.next(newMenuId);
      return true; // Navigation Success
    } else {
      return false; // Navigation Failure
    }
  }

  // Use the below method for navigating with optional route params
  public navigateWithRouteParams(path: string, params: string[], isBlank?: boolean) {
    if (path) {
      if (this.preventNavigation) {
        const dialogMsg = [`<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`, this.resourceService.get('mn_nav_confirm_msg')];
        this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg)).subscribe((isConfirmed) => {
          if (!isConfirmed) {
            return false;
          }
          this.preventNavigation = false;
          this.router.navigate([path].concat(params), { replaceUrl: true });
          // this.router.navigate([path], { queryParams: params });
        });
        return;
      }
      // this.router.navigate([path], { queryParams: params });
       // open in new tab if isBlank is true
       if (isBlank) {
        // open in a new tab
        this.preventNavigation = false;
        const url = `${[path]}/${params[0]}`;
        window.open(url, '_blank');
        // don't open more than one tab with the same url
        if (window[url]) {
          window[url].close();
        } 
        window[url] = window.open(url, '_blank');
        return false;
      } else {
        this.router.navigate([path].concat(params), { replaceUrl: true });
      }
      this.currentMenuIdSubject.next('dummyTrigger');
      // this.currentMenuIdSubject.next(newMenuId);
      return true; // Navigation Success
    } else {
      return false; // Navigation Failure
    }
  }

  // Returns Current Breadcrumb corresponding to the Last Clicked/Navigated Menu.
  public getCurrentBreadCrumb(): BreadCrumbItem[] {
    if (!this.currentBreadCrumb) {
      return [this.rootBreadCrumb]; // Return rootCrumb if unitialised
    }
    return this.currentBreadCrumb;
  }

  /* Returns the rootmenuId corresponding to the last clicked/navigated menu.
     Used for highlighting the root menu in selected state when navigated through
     navigateByMenuId(id) method or on clicking on breadcrumbs. */
  public getCurrentRootMenuId(): string {
    // If home send 'home' else send second crumbs id
    if (!this.currentBreadCrumb) {
      return this.rootBreadCrumb.id; // Return 'home' if null
    }
    return this.currentBreadCrumb[1] ? this.currentBreadCrumb[1].id : this.rootBreadCrumb.id;
  }

  /* ***Method incomplete***
  Emits menuId and breadcrumb corresponding to the url directly navigated from urlbar.
  Called from router-guard. RootAuthGuard or similar */
  public setMenuIdFromRoute(currentRoute: string) {
    /*   This function logic is to be removed/modified as per review.
      Implementation is still in progress as implementation logics
      are practically limited by sub urls not in current master list
       */
    /*  menuIdRouteMappings['aaa']='/home////'
      let values=Object.values(menuIdRouteMappings).sort().reverse().filter(data=>{
        if(data==currentRoute || data.startsWith(currentRoute))
          return true;
        return false;
      });
    */

    for (const menuId of Object.keys(menuIdRouteMappings)) {
      if (menuIdRouteMappings[menuId] === currentRoute) {
        this.currentBreadCrumb = this.menuIdbreadcrumbsMapping[menuId];
        // Observable.timer(500).subscribe(()=>this.currentMenuIdSubject.next(menuId));
        // setTimeout(500,(menuId)=>this.currentMenuIdSubject.next(menuId))
        // this.currentMenuIdSubject.delay(1000);
        this.currentMenuIdSubject.next(menuId);
        // this.currentMenuIdSubject.delay(0);
        break;
      }
    }
  }

  // Retrieves role based menu configuration for the logged in user from backend
  public getUserMenu(): Observable<any> {
    return this.http.get('api/app/menus', { headers: this.header }).pipe(map((nestedMenu) => {
      // initialize routings array
      this.accessibleMenuRouting['routings'] = [];
      this.accessibleMenuRouting['menuRoutings'] = {};
      this.processMenuRecursively(nestedMenu, []);
      // local storage of permissible routings .
      this.appStorageService.setItem(KEYS_APPSTORAGE.MENU_ROUTING, this.accessibleMenuRouting);
      /** For urls directly routing to a menu find the menu and populate the breadcrumb */
      if (this.directUrlNavigation) {
        this.addBreadcrumpFromUrl(this.directUrlNavigation);
        this.directUrlNavigation = null;
      }
      return nestedMenu;
    }));
  }

  /* Methods for breadcrumb manipulation from outside */

  /* Method to reset breadcrumb to Corresponding breadcrumb for a given menuId */
  public resetBreadcrumb(resetToMenuId: string): NavigationService {
    this.currentBreadCrumb = [].concat(this.menuIdbreadcrumbsMapping[resetToMenuId]);
    // Trigger Subject using some dummyId so that breadcrumb component recives the update;
    this.currentMenuIdSubject.next('dummyTrigger');

    return this;
  }
  /* Method to add a non navigable breadcrumb item */
  public addNonNavigableBreadCrumb(displayName: string) {
    this.addBreadCrumbItem(displayName);
    return this;
  }
  /** Find breadcrump for menu in route */
  public addBreadcrumpFromUrl(route) {
    const menuId = this.findMenuIdFromRoute(route);
    const breadCrumbMapping = this.menuIdbreadcrumbsMapping[menuId];
    this.currentBreadCrumb = breadCrumbMapping ? [].concat(breadCrumbMapping) : [].concat(this.rootBreadCrumb);
    this.currentMenuIdSubject.next(menuId);
  }
  /** Find closest matching menu from url */
  public findMenuIdFromRoute(route) {
    const name = route.split('/');
    let restructureUrl = '';
    let menu = null;
    name.forEach(element => {
      restructureUrl += element;
      for (const menuId of Object.keys(menuIdRouteMappings)) {
        if (menuIdRouteMappings[menuId] === restructureUrl) {
          menu = menuId;
          break;
        }
      }
      restructureUrl += '/';
    });
    return menu;
  }
  /* Method to add a breadcrumbitem with optional route */
  public addBreadCrumbItem(displayName: string, routeIdOrUrl?: string): NavigationService {
    if (!this.currentBreadCrumb) {
      this.currentBreadCrumb = [this.rootBreadCrumb];
    }
    // if mapping is available use the mapped route
    const routeUrlFromRouteId = routeIdOrUrl ? menuIdRouteMappings[routeIdOrUrl] : null;
    // if no mappings available use as routeUrl directly
    const routeUrl = routeUrlFromRouteId ? routeUrlFromRouteId : routeIdOrUrl;
    this.currentBreadCrumb.push({ id: '', displayValue: displayName, route: routeUrl });
    // Trigger Subject using some dummyId so that breadcrumb component recives the update;
    this.currentMenuIdSubject.next('dummyTrigger');

    return this;
  }
  /* Method to remove last breadcrumb item */
  public removeLastBreadCrumbItem(): NavigationService {
    if (this.currentBreadCrumb) {
      this.currentBreadCrumb.pop();
    }
    return this;
  }
  /*END: Methods for breadcrumb manipulation */


  /* This method recursively iterartes over menu list from service
     and generates the display value fetched from resource bundle.
     It also generates the breadcrumb navigation array for each
     menu item during the recursive iterations. */
  public processMenuRecursively(menuItem: any, breadcrumbsArray: BreadCrumbItem[]) {
    let index: number = 1;
    for (const subMenuItem of menuItem) {
      // Find user permissible routes from accessible menus.
      if (subMenuItem.menuID in menuIdRouteMappings) {
        const name = menuIdRouteMappings[subMenuItem.menuID].split('/');
        this.accessibleMenuRouting['menuRoutings'][subMenuItem.menuID] = name[name.length - 1]
        this.accessibleMenuRouting['routings'].push(name[name.length - 1]);
      }
      // Do any per item logic here
      // Add displayValue into actual response
      // subMenuItem.displayValue =this.resourceService.get('mn_'+subMenuItem.menuID),
      subMenuItem.displayValue = this.resourceService.get('mn_' + subMenuItem.menuID);
      breadcrumbsArray.push({
        id: subMenuItem.menuID,
        // displayValue: subMenuItem.displayValue,
        displayValue: this.resourceService.get('mn_' + subMenuItem.menuID),
        route: menuIdRouteMappings[subMenuItem.menuID]
      });
      if (subMenuItem.menuID === 'home') {
        this.menuIdbreadcrumbsMapping[subMenuItem.menuID] = [this.rootBreadCrumb];
      } else {
        this.menuIdbreadcrumbsMapping[subMenuItem.menuID] = [this.rootBreadCrumb].concat(breadcrumbsArray);
      }
      // If it has submenu, reprocess!
      if (subMenuItem.subMenuItems && subMenuItem.subMenuItems.length > 0) {
        this.processMenuRecursively(subMenuItem.subMenuItems, [].concat(breadcrumbsArray));
      }
      /* End of iterative part
      -- reset array for last item in submenulist.
      -- remove last innermost menu to use for next menu in submenulist. */
      const lastItem: boolean = (index++ === menuItem.length);
      if (lastItem) {
        breadcrumbsArray = [];
      } else {
        breadcrumbsArray.pop();
      }
    }
  }
  /* Methods to retrieve the ActivatedRouteSnapshot on NavigationEnd events*/
  public getInnerRouteSnapshotAfterNavigation(): Observable<ActivatedRouteSnapshot> {
    return this.router.events.pipe(filter(event => event instanceof NavigationEnd),
      map(event => {
        // console.log(event,   this.router.routerState.snapshot);
        const snapshot = this.getInnermostRouteSnapshot(this.router.routerState.snapshot.root);
        return snapshot;
      }));
  }
  /* Recursive function to find innermost child of ActivatedRouteSnapshot*/
  public getInnermostRouteSnapshot(snapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (snapshot.children.length > 0) {
      return this.getInnermostRouteSnapshot(snapshot.children[0]);
    } else {
      // console.log(snapshot.data, snapshot.params, snapshot.queryParams, snapshot.paramMap, snapshot);
      //   this.titleService.setTitle('LFWMS-' + this.resourceService.get(snapshot.data.title ? snapshot.data.title : '')) ;
      return snapshot;
    }
  }
  /* Wrapper method to set/get title using titleservice */
  public setPageTitle(title: string) {
    this.titleService.setTitle(title);
  }
  public getPageTitle(): string {
    return this.titleService.getTitle();
  }

  public getRouteParams(): Params {
    if (this.activatedRoute && this.activatedRoute.snapshot) {
      return this.activatedRoute.snapshot.queryParams;
    } else {
      return {};
    }
  }

  /* Method to set retainAlerts without clearing after a navigation call ends
  This method does a one time setting of flag. Call again to change flag state again*/
  public retainAlerts(): NavigationService {
    this.retainAlertsOnNavigation = true;
    return this;
  }


  /* This method transmits the menuId clicked without doing any actual navigation.
    It can be used when a menuId has no actual route to be navigated and has to do some background actions or so.
    One example would be to open a popup directly [eg:PrinterSetupComponet]
    Another can be to implement a backedn api call directly.
    Such menuIds can be handled under respective application's home component by subscribing to the broadcasted menuId.
    >>
     */
  public transmitMenuIdWithoutNavigation(newMenuId: string) {
    this.currentMenuIdSubject.next(newMenuId);
    return true; // Event emit Success
  }

  public getAppPrivRestriction(appId): Observable<any> {
    return this.http.get('api/users/' + appId + '/privrestriction', { headers: this.header }).pipe(map((response: any) => {
      const privilegeCodes = [];
      if (response.userPermissions) {
        response.userPermissions.forEach((privilege, index) => {
          privilegeCodes.push(privilege.privilegeCode);
        });
      }
      response.userPermissionsList = privilegeCodes;
      const billingUserInfo = response;
      if (appId === 5) {
        this.appStorageService.setItem(KEYS_APPSTORAGE.BILLING_USER_INFO, billingUserInfo);
      } else if (appId === 6) {
        this.appStorageService.setItem(KEYS_APPSTORAGE.POMS_USER_INFO, billingUserInfo);
      } else if (appId === 3) {
        this.appStorageService.setItem(KEYS_APPSTORAGE.DL_USER_INFO, billingUserInfo);
      }
      
      return response;
    }),catchError((error) => {
      return Observable.throw(error);
    }));
  }
//Get the current application by checking the current location path
  //if the path has gvt then the user is currently in gvt application
  //else in wms
  // TODO : UPDATE the function based on adding new application
  public getCurrentApplicationBasedOnUrl(){
    let currApp = '';
    if(window.location.pathname.includes(sceconfig.gvt.baseUrlUI)){
      currApp ='gvt';
    } else if(window.location.pathname.includes(sceconfig.dashboard.baseUrlUI)){
      currApp ='dashboard';
    } else if(window.location.pathname.includes(sceconfig.billing.baseUrlUI)){
      currApp ='billing';
    } else if (window.location.pathname.includes(sceconfig.poms.baseUrlUI)) {
      currApp = 'poms';
    } else if (window.location.pathname.includes(sceconfig.ltpoms.baseUrlUI)) {
        this.currentApplication = 'ltpoms';
    } else if (window.location.pathname.includes(sceconfig.notification.baseUrlUI)) {
      currApp = 'notification';
    } else {
      currApp ='wms';
    }
    return currApp;
  }
  //get the application base based on the application to navigate
  // TODO ADD more case when adding new application
  public getApplicationUrlNavigationBase(applicationToNavigate) {
    let urlNavigationBase = '';
    switch(applicationToNavigate){
      case 'gvt':
        urlNavigationBase ='/gvt';
        break;
      case 'dashboard':
        urlNavigationBase ='/dashboard';
        break;
      case 'billing':
        urlNavigationBase ='/billing';
        break;
      case 'poms':
        urlNavigationBase = '/poms';
        break;
      case 'ltpoms':
        this.urlNavigationKey = '/ltpoms';
        break;
      case 'notification':
        urlNavigationBase = '/notification';
        break;
      case 'dataloader':
        urlNavigationBase = '/dataloader';
        break;
      default:
        urlNavigationBase = '';
    }
    return urlNavigationBase
  }
  public navigatetoSearch(itemType: string, params: string[]) {
    let path = menuIdRouteMappings[itemType] + '/search';
    if (path) {
      if (this.preventNavigation) {
        const dialogMsg = [`<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`, this.resourceService.get('mn_nav_confirm_msg')];
        this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg)).subscribe((isConfirmed) => {
          if (!isConfirmed) {
            return false;
          }
          this.preventNavigation = false;
          this.router.navigate([path].concat(params), { replaceUrl: true });
          // this.router.navigate([path], { queryParams: params });
        });
        return;
      }
      const applicationToNavigate = menuIdAppMappings[itemType]; // || 'wms||gvt';
      const currentApplication = this.getCurrentApplicationBasedOnUrl();
      const urlNavigationKey = this.getApplicationUrlNavigationBase(applicationToNavigate);
      if (currentApplication != applicationToNavigate) {
        if (path === '/home') {
          path = '/home/';
        }
        const searchPath = path + '/' + params[0]
        if (applicationToNavigate === 'billing') {
          this.getAppPrivRestriction(5).subscribe(resp => {
            window.location.href = window.location.origin + urlNavigationKey + searchPath;
          }, (error2) => {
            console.log('BillingPrivilegeError', error2);
          });
        } else if (applicationToNavigate === 'poms') {
          this.getAppPrivRestriction(6).subscribe(resp => {
            window.location.href = window.location.origin + urlNavigationKey + searchPath;
          }, (error2) => {
            console.log('POMSPrivilegeError', error2);
          });
        } else {
          window.location.href = window.location.origin + urlNavigationKey + searchPath;
        }
      } else {
        this.router.navigate([path].concat(params), { replaceUrl: true });
        this.currentMenuIdSubject.next('dummyTrigger');
      }
      return true; // Navigation Success
    } else {
      return false; // Navigation Failure
    }
  }
}
