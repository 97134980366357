import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmDialogService } from '../components/confirm-dialog/confirm-dialog.service';
import { CustomPaginationService } from '../custom-pagination/custom-pagination.service';


import { LookUpUser } from '../models/lookup-user';
import { NavigationService } from '../navigation.service';
import { ResourceService } from '../resource.service';
import { UserService } from '../security/user.service';
import { UserManagementService } from '../user-management/user-management.service';


@Component({
  selector: 'lfwms-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  public listPerPage: any[] = [];
  // public recordCount: number;
  public start_index: number;
  public end_index: number;
  public errorMessage: string;
  public addUserDisabled: boolean = true;
  public rows = [];
  public temp = [];
  public sortOrder: number = -1;
  // public currentPage: any;
  public columnDef = [{
    prop: 'userName',
    name: this.getLabel('lbl_um_userName'),
    sortOrder: -1,
    type: 'string'
  }, {
    prop: 'email',
    name: this.getLabel('lbl_um_emailid'),
    sortOrder: -1,
    type: 'string'
  }, {
    prop: 'userType',
    name: this.getLabel('lbl_um_UserType'),
    sortOrder: -1,
    type: 'string'
  }, {
    prop: 'lastloginTime',
    name: this.getLabel('lbl_um_LastAccess'),
    sortOrder: -1,
    type: 'DATE'
  },
  {
    prop: 'bu',
    name: 'BU',
    sortOrder: -1,
    type: 'string'
  }];
  public title: string;
  public list: any[] = [];
  public paginatorService: CustomPaginationService;
  public pageList = [10, 20, 50, 100];
  public recordCount: number = 0;
  public selectedRowsPerPage: number = 10;
  public state = {
    page: 1,
    first: 1,
    rows: [],
    pageCount: this.recordCount
  };
  public currentPage: number = 1;
  public pageSize: number = 10;
  public btnRestrictionAdd: boolean;
  public btnRestrictionEdit: boolean;

  constructor(public userManagementService: UserManagementService,
    public router: Router,
    public navService: NavigationService,
    public userService: UserService,
    public resourceService: ResourceService,
    public confirmDialogService: ConfirmDialogService,
    public activatedRoute: ActivatedRoute) {
    this.paginatorService = new CustomPaginationService(this.confirmDialogService, resourceService);
    this.paginatorService.rows = this.selectedRowsPerPage;
    this.paginatorService.tableMode = 'V';
    this.getAllUserDetails();

    this.paginatorService.getPaginatorBroadcast()
      .subscribe((event: any) => {
        this.state = event;
        this.start_index = (this.state.page * this.paginatorService.rows) - this.paginatorService.rows;
        this.end_index = ((this.state.page * this.paginatorService.rows) + this.paginatorService.rows) - this.paginatorService.rows;
        this.listPerPage = [];
        this.listPerPage = this.rows.slice(this.start_index, this.end_index);
      });
  }
  public ngOnInit() {
    // this.activatedRoute.params.subscribe(params => {
    this.navService.setPageTitle(this.resourceService.get('mn_manage-user'));
    // });
    this.btnRestrictionAdd = this.checkButtonPrivilege('usr_add');
    this.btnRestrictionEdit = this.checkButtonPrivilege('usr_edit');
  }
  public checkButtonPrivilege(privilegeCode: String) {
    return this.userService.checkButtonPrivilege(privilegeCode);
  }
  // method used to translate the labels
  public getLabel(key: any) {
    return this.resourceService.get(key);
  }
  // method used to get user details
  public getAllUserDetails() {
    this.userManagementService.getAllUsers().subscribe((users: any) => {
      this.rows = users;
      this.temp = users;
      this.start_index = 0;
      this.end_index = 10;
      this.recordCount = users.length;
      this.listPerPage = this.rows.slice(this.start_index, this.end_index);
      this.paginatorService.totalRecords = this.recordCount;
      this.paginatorService.choosenPage = this.currentPage;
      this.paginatorService.paginatorState = this.state;
      this.paginatorService.rowsPerPageOptions = this.pageList;
      this.paginatorService.pageLinkSize = 5;
    }, (error) => {
      this.errorMessage = <any>error;
    });
  }
  // method used to sort the column
  public toggleColumnSort(column: any) {
    let currentSortOrder: number = column.sortOrder;
    if ((currentSortOrder === -1) || (currentSortOrder === 0)) {
      this.rows.sort((a: any, b: any) => {
        if (column.type !== 'DATE') {
          if (a[column.prop] && b[column.prop]) {
            if (a[column.prop].toLowerCase() < b[column.prop].toLowerCase()) {
              return -1;
            } else if (a[column.prop].toLowerCase() > b[column.prop].toLowerCase()) {
              return 1;
            } else {
              return 0;
            }
          } // null value handled for non-date fields
          else if (a[column.prop] === null) {
            return 1;
          } else if (b[column.prop] === null) {
            return -1;
          } else if (a[column.prop] === b[column.prop]) {
            return 0;
          }
        } // null values handled for date fields
        else {
          if (a[column.prop] === null) {
            return 1;
          } else if (b[column.prop] === null) {
            return -1;
          } else if (a[column.prop] === b[column.prop]) {
            return 0;
          } else if (a[column.prop] !== null && b[column.prop] !== null) {
            let aDate: Date;
            let bDate: Date;
            aDate = new Date(a[column.prop]);
            bDate = new Date(b[column.prop]);
            return (aDate > bDate) ? -1 : ((bDate > aDate) ? 1 : 0);
          }
        }
      });
      currentSortOrder = 1;
    } else if (currentSortOrder === 1) {
      this.rows.sort((a: any, b: any) => {
        if (column.type !== 'DATE') {
          if (a[column.prop] < b[column.prop]) {
            return 1;
          } else if (a[column.prop] > b[column.prop]) {
            return -1;
          } else {
            return 0;
          }
        } else {
          if (a[column.prop] === null) {
            return 1;
          } else if (b[column.prop] === null) {
            return -1;
          } else if (a[column.prop] === b[column.prop]) {
            return 0;
          } else if (a[column.prop] !== null && b[column.prop] !== null) {
            let aDate: Date;
            let bDate: Date;
            aDate = new Date(a[column.prop]);
            bDate = new Date(b[column.prop]);
            return (aDate > bDate) ? 1 : ((bDate > aDate) ? -1 : 0);
          }
        }
      });
      currentSortOrder = 0;
    }
    this.columnDef.forEach((col) => {
      if (col.prop !== column.property) {
        col.sortOrder = -1;
      }
    });
    this.listPerPage = this.rows.slice(this.start_index, this.end_index);
    column.sortOrder = currentSortOrder;
  }
  // method used to update the filter
  public updateFilter(event: any) {
    const val = event.target.value.toLowerCase();
    this.rows = this.temp.filter(function (d) {
      return d.userName.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.start_index = 0;
    this.end_index = this.paginatorService.rows;
    this.recordCount = this.rows.length;
    this.listPerPage = this.rows.slice(this.start_index, this.end_index);
    this.paginatorService.totalRecords = this.recordCount;
    this.paginatorService.choosenPage = 1;
    this.state.page = 1;
    this.paginatorService.paginatorState = this.state;
    this.paginatorService.rowsPerPageOptions = this.pageList;
    this.paginatorService.pageLinkSize = 5;
    this.paginatorService.updatePaginatorState();
    this.paginatorService.changePageToFirst(event);
  }
  // method used to navigate to user management screen with selected user details
  public onUserRowSelect(event: any): void {
    this.userManagementService.selectedUserDetails = event;
    this.userManagementService.disablefield = true;
    this.navService.navigateByMenuId('user-management', true);
    this.navService.addNonNavigableBreadCrumb(this.resourceService.get('lbl_um_EditUser'));
  }
  // method used to navigate to user management screen to add new user
  public addUserNavigate() {
    this.userManagementService.disablefield = false;
    this.userManagementService.selectedUserDetails = new LookUpUser();
    this.userManagementService.selectedUserDetails.userType = this.resourceService.get('lbl_um_Internal');
    this.navService.navigateByMenuId('user-management', true);
    this.navService.addNonNavigableBreadCrumb(this.resourceService.get('lbl_um_AddUser'));
  }
}
