import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output} from '@angular/core';
import moment from 'moment';
import { ScheduleDowntimePopupComponent } from '../popup/schedule-downtime-popup.component';
import { DowntimeConfigurationService } from '../downtime-configuration.service';
import { DowntimeConfigurationComponent } from '../downtime-configuration.component';
import { UserManagementService } from '../../user-management/user-management.service';
import { DatePickerConfigService } from '../../components/datepicker/datepicker-config.service';
import { ModalService } from '../../components/modal/modal.service';
import { ResourceService } from '../../resource.service';
import { ConfirmDialogService } from '../../components/confirm-dialog/confirm-dialog.service';
import { AlertService } from '../../components/alerts/alert.service';


@Component({
    selector: 'lfwms-downtime-configuration-dashboard',
    templateUrl: './downtime-configuration-dashboard.component.html',
    styleUrls: ['./downtime-configuration-dashboard.component.css'],

})
export class DowntimeConfigurationDashboardComponent extends DowntimeConfigurationComponent implements OnChanges, OnInit {

    @Input() applicationList: Array<any> = [];
    @Input() infoList: Array<any> = [];
    @Output() isEdited = new EventEmitter<any>();
    public appList: Array<any> = [];
    public downTimeStart: string;
    public downTimeEnd: string;
    public downTimeId: any;
    public active: boolean;
    public timeSpan: any;
    public dashboardList: Array<any> = [];
    public appId: any;
    public code: any;
    public name: any;
    public isEnabled: boolean;
    public downStartTimeDisplay: any;
    public downEndTimeDisplay: any;
    public downTimeMessage: any;
    public displayMessage: any;

    constructor(public resourceService: ResourceService,
        public modalService: ModalService,
        public downtimeConfigurationService: DowntimeConfigurationService,
        public confirmDialogService: ConfirmDialogService,
        public alertService: AlertService,
        public userManagementService: UserManagementService,
        public datePickerConfigService: DatePickerConfigService,
    ) {
        super(resourceService, modalService, alertService, userManagementService, datePickerConfigService, downtimeConfigurationService);
    }

    public ngOnInit() {
        this.getInfoList();
    }
    public ngOnChanges(changes: SimpleChanges) {
        if (changes['infoList'].firstChange === false) {
            this.getInfoList();
        }
    }

    // to form the list having displaying data.
    public getInfoList() {
        this.dashboardList = [];
        this.infoList.forEach(info => {
            this.downTimeId = info.downTimeId;
            //LFWM-3612- Some app codes are replaced with application names.
            let appCodeList = info.appCode.toString();
            appCodeList = appCodeList.replace("DL", "Data Loader");
            appCodeList = appCodeList.replace("DSHB", "Dashboard");
            appCodeList = appCodeList.replace("POMS", "Global Control");
            let appCodeArray = appCodeList.split(',');
            let appListNames = appCodeArray;
            this.appList = info.appCode;
            this.active = info.active;
            // LFWM-2916- converting the UTC time fetched from db to local time(according to user timezone).
            this.downTimeStart = this.downtimeConfigurationService.convertTimeToLocalTime(info.downTimeStart);
            this.downTimeEnd = this.downtimeConfigurationService.convertTimeToLocalTime(info.downTimeEnd);
            // LFWM-2916- reconstructing the display message according to user timezone.
            //LFWM-3612- Reconstructing the message as per the user suggestion.
            let hours = this.calculateTimeSpan();
            this.downTimeMessage = this.resourceService.get('lbl_downtime_first_line') +
                '\n' + this.resourceService.get('lbl_from') + " " + moment(this.downTimeStart).format("MMM DD Y, h:mm:ss a") +
                '\n' + this.resourceService.get('lbl_to') + " " + moment(this.downTimeEnd).format("MMM DD Y, h:mm:ss a") +
                '\n' + this.resourceService.get('lbl_duration') + ': ' + hours + " " + 'hours' +
                '\n' + info.downTimeMessage.substr(info.downTimeMessage.indexOf('During'));

            // display the time difference only if the toggle switch is activated.
            if (this.active === true) {
                this.timeSpan = this.calculateTimeSpan();
            } else {
                this.timeSpan = '00:00:00';
            }
            // only two lines with 3 dots (message)is displayed. rest is displayed in tooltip.
            this.displayMessage = this.downTimeMessage.slice(0, 170) + "...";
            let today = moment(Date()).format("YYYY-MM-DD HH:mm:ss");
            // LFWM-2916-filtering downtime details whose startdate is greater than today.
            if (this.downTimeStart > today) {
                const data = {
                    downTimeId: this.downTimeId,
                    downTimeStart: this.downTimeStart,
                    downTimeEnd: this.downTimeEnd,
                    appList: this.appList,
                    appNames: appListNames,
                    timeSpan: this.timeSpan,
                    active: this.active,
                    downTimeMessage: this.downTimeMessage,
                    displayMessage: this.displayMessage
                };
                this.dashboardList.push(data);
            }

        });
    }

    // to calculate the time difference between start and end time
    public calculateTimeSpan() {
        let finalDate = moment(new Date(this.downTimeEnd), 'DD/MM/YYYY HH:mm:ss');
        let initialDate = moment(new Date(this.downTimeStart), 'DD/MM/YYYY HH:mm:ss');
        let duration = moment.duration(finalDate.diff(initialDate));
        let timeDiff = Math.floor(duration.asHours()) + moment.utc(finalDate.diff(initialDate)).format(':mm:ss');
        return timeDiff;
    }
    // to get the display value of corresponding key from the resource bundle
    public getLabel(key: any) {
        return this.resourceService.get(key);
    }

    // capturing value of the toggle switch.
    public capturingToggleSwitchValue(dashboard: any, value: any) {
        this.dashboardList.forEach(data => {
            if (data.downTimeId === dashboard.downTimeId) {
                if (dashboard.active === true) {
                    data.active = true;
                } else {
                    data.active = false;
                }
            }
        });
        this.editToggleSwitch(dashboard);
    }
    // making an API call(update) to save the changes of the toggle switch.
    public editToggleSwitch(item: any) {
        // LFWM-2916- converting the downStartTime and downEndTime to utc and saving the utc times in DB.
        let downStartTimeInUtc = this.downtimeConfigurationService.convertTimeToUtc(item.downTimeStart);
        let downEndTimeInUtc = this.downtimeConfigurationService.convertTimeToUtc(item.downTimeEnd);
        let request = {
            "downTimeId": item.downTimeId,
            "appCode": item.appList,
            "active": item.active,
            "downTimeStart": downStartTimeInUtc,
            "downTimeEnd": downEndTimeInUtc,
            "downTimeMessage": item.downTimeMessage

        }
        const successMsg = 'msg_save_succ';
        this.downtimeConfigurationService.addDowntime(request)
            .subscribe((resp: any) => {
                if (resp['statusCode'] && resp['statusMessage']) {
                    if (resp['statusCode'] === 200) {
                        this.alertService.clearAll().success(this.resourceService.get(successMsg));
                        this.isEdited.emit(true);
                    }

                } else {
                    if (resp.error) {
                        this.alertService.clearAll();
                        this.alertService.error(this.resourceService.translateServerResponse(resp.error));
                    }
                }

            }, (error) => {
                this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
            });
    }

    // edit downtime, scheduleDowntime Popup is displayed.
    public editDowntime(item: any) {
        const modalRef: any = this.modalService
            .showModal(ScheduleDowntimePopupComponent,
                {
                    overlayHeader: null,
                    showHeader: false,
                    overlayHeight: 700,
                    overlayWidth: 580
                });
        modalRef.content.initializeComponents(this.applicationList, item);
        // if the downtime is added succesfully, triggering the fetchDowntime to fetch the details.
        modalRef.content.receiveEvent()
            .subscribe((event: any) => {
                if (event.type === 'addedSuccessfully') {
                    this.isEdited.emit(true);
                }
                modalRef.hide();
            });
        modalRef.content.showLookup = true;
    }
    // delete downtime
    public deleteDowntime(item: any) {
        let info: any;
        this.infoList.forEach(data => {
            if (data.downTimeId === item['downTimeId']) {
                info = data;
            }

        });
        let index = this.infoList.indexOf(info);
        const successMsg = 'msg_del_succ';
        let request = {};

        request = {
            "downTimeId": item['downTimeId']
        }
        this.alertService.clearAll();
        const dialogMsg = this.resourceService.get('mn_delete_downtime_msg');
        this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
            .subscribe((isConfirmed: any) => {
                if (isConfirmed) {
                    this.downtimeConfigurationService.deleteDowntime(request)
                        .subscribe((resp: any) => {
                            if (resp['statusCode'] && resp['statusMessage']) {
                                if (resp['statusCode'] === 200) {
                                    this.alertService.clearAll();
                                    this.alertService.success(this.resourceService.translateServerResponse(this.resourceService.get(successMsg)));
                                    this.infoList.splice(index, 1);
                                    this.getInfoList();
                                }
                            } else {
                                if (resp.error) {
                                    this.alertService.clearAll();
                                    this.alertService.error(this.resourceService.translateServerResponse(resp.error));
                                }
                            }

                        }, (error) => {
                            this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
                        });
                }
            });
    }

}
