import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { IMultiSelectOption, IMultiSelectTexts, IMultiSelectSettings } from 'angular-2-dropdown-multiselect';


import { UserLookupComponent } from '../user-lookup/user-lookup.component';
import { UserCountryRole } from '../models/user-country-role';
import { LookUpUser } from '../models/lookup-user';
import { User } from '../models/user';
import { UserManagementService } from './user-management.service';
import * as moment from 'moment';
import {forkJoin} from 'rxjs';
import { ModalService } from '../components/modal/modal.service';
import { NavigationService } from '../navigation.service';
import { AlertService } from '../components/alerts/alert.service';
import { ResourceService } from '../resource.service';
import { UserService } from '../security/user.service';
import { ConfirmDialogService } from '../components/confirm-dialog/confirm-dialog.service';
import { ButtonItem } from '../components/button/button.model';

@Component({
  selector: 'lfwms-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {

  buttonArray: any[] = [];
  // variables for smooth scrolling of floating menu
  public floatingMenuOffset;
  public isStickyFloatingMenu: boolean;
  public previousScroll = 0;
  public isScrollDown = false;
  
  public succ: any;
  public alertmessage: string;
  public adduser: boolean = true;
  public edituser: boolean = false;
  public checkvalue: string;
  public checkRestriction: any;
  public checkCountry: any;
  public restrictionRoles: any = {};
  public userCountryRoleList: UserCountryRole[];
  public errorMessage: string;
  // public myControl: FormControl = new FormControl();
  public userDetail: User;
  public userlist: any[] = [];
  public countries: any[] = [];
  public restriction = [];
  public optionsModel: number[];
  public appAccessError = '';
  public disable: boolean = false;
  public lookupuser: LookUpUser;
  public subscription: Subscription;
  public types: any = ['Internal'];
  public selectedtype = this.types[0];
  public codes: any[];
  public selectedcode = 1;
  public domains: any = [];
  public selecteddomain = '';
  public user: any = {};
  public lookupdisable: boolean = false;
  public beforegroup: any[];
  public group: IMultiSelectOption[] = [];
  public countrycodeId: any;
  public allRoleGroups: any[];
  public roleGroupMap: {};
  public disableall: boolean = false;
  public isUserNameDisable: boolean;
  public isUserTypeDisable: boolean;
  public isAppCodeDisable: boolean = true;
  public isEditFlow: boolean = false;
  public showExpirationDate: boolean = true;
  public alertMsg: string = 'msg_Error';
  public alertType: string = 'alert-danger';
  public showSuccessAlert: boolean = false;
  public enableaddbutton: boolean = true;
  public enablesavebutton: boolean = false;
  public disableIcon: boolean = false;
  public showtable: boolean = false;
  public helperText: string = 'lbl_um_RestrnNote';
  public helperTextDl: string = 'lbl_um_dl_RestrnNote';
  public allRoleGroupsInfo: any;
  public mySettings: IMultiSelectSettings = {
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: true
  };
  public mySettings1: IMultiSelectSettings = {
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: true
  };
  public myTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Select Groups',
  };
  public myTexts1: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'item selected',
    checkedPlural: 'items selected',
    searchPlaceholder: 'Find',
    searchEmptyResult: 'Nothing found...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: 'Select Roles',
  };

  public btnRestrictionEdit: boolean;
  public btnRestrictionAdd: boolean;
  public btnRestrictionDelete: boolean;
  public filteredCountries: any;
  public modifiedUserList: any;
  public userCountryRoleListForTemplate: any;
  /*** LFWM-2242 LF SCE Dashboard - User Management */
  public dashboardModules: any;
  public enableDshbRestriction = false;
  public dshbRestriction = [];
  /*LFWM-2511 UI changes for reset password */
  public enableResetPasswordButton = false;
  /*LFWM-2755 RG| Deactivate Internal/external user*/
  public enableActiveInactiveButton = false;


  public restrictionRolesCopy: any = {};
  constructor(public userManagementService: UserManagementService,
    public modalService: ModalService,
    public navService: NavigationService,
    public alertservice: AlertService,
    public resourceService: ResourceService,
    public userService: UserService,
    public confirmDialogService: ConfirmDialogService,
  ) {
    this.navService.setPageTitle(this.resourceService.get('mn_manage-user'));
    let type: number;
    this.userlist = [];
    this.userCountryRoleList = [];
    let module = '';
    this.selectedtype = this.resourceService.get(this.selectedtype);
    if (this.checkIfThisIsAnEditFlow()) {
      this.isEditFlow = true;
    }
    this.disableall = !this.userManagementService.disablefield;
    this.isUserNameDisable = false;
    this.isUserTypeDisable = false;
    this.disableIcon = this.userManagementService.icondisable;
    this.subscription = this.userManagementService.getvalue().subscribe((message: any) => {
      this.user.userName = this.userManagementService.selectedUserDetails_LookUp.userName;
      this.user.displayName = this.userManagementService.selectedUserDetails_LookUp.displayName;
      if (this.isEditFlow === true) {
        this.user.userId = this.userManagementService.selectedUserDetails_LookUp.userId;
      }
      if ((this.userManagementService.selectedUserDetails_LookUp.userName !== '')
        && (this.userManagementService.selectedUserDetails_LookUp.userName !== undefined)) {
        this.enableaddbutton = false;
        this.enablesavebutton = true;
        this.showtable = true;
      }
      this.user.emailId = this.userManagementService.selectedUserDetails_LookUp.email;
      this.user.userType = this.userManagementService.selectedUserDetails_LookUp.userType;
      this.user.ldapDomain = this.userManagementService.selectedUserDetails_LookUp.ldapDomain;

      this.user.company  = this.userManagementService.selectedUserDetails_LookUp.company;
      this.user.phone  = this.userManagementService.selectedUserDetails_LookUp.phone;
      this.user.line  = this.userManagementService.selectedUserDetails_LookUp.line;
      this.user.whatsapp  = this.userManagementService.selectedUserDetails_LookUp.whatsapp;
      this.user.wechat  = this.userManagementService.selectedUserDetails_LookUp.wechat;
      this.user.telegram  = this.userManagementService.selectedUserDetails_LookUp.telegram;

   
      if (this.userManagementService.selectedUserDetails_LookUp.userType === 'Internal') {
        type = 0;
        this.showExpirationDate = false;
      }
      this.disableall = this.userManagementService.disablefield;
      this.isUserNameDisable = true;
      this.isUserTypeDisable = true;
      this.disableIcon = this.userManagementService.icondisable;
      this.loadData(this.userManagementService.selectedUserDetails_LookUp.userId, type, false, module);
    });
    this.userManagementService.appCode = 1;
    this.user.userType = this.selectedtype;
    this.user.applicationCode = this.selectedcode;
    this.user.ldapDomain = this.selecteddomain;
    this.user.userName = this.userManagementService.selectedUserDetails.userName;
    this.user.displayName = this.userManagementService.selectedUserDetails.displayName;
    if (this.isEditFlow === true) {
      this.user.userId = this.userManagementService.selectedUserDetails.userId;
    }
    if ((this.userManagementService.selectedUserDetails.userName !== '')
      && (this.userManagementService.selectedUserDetails.userName !== undefined)) {
      this.enableaddbutton = false;
      this.enablesavebutton = true;
      this.showtable = true;
      this.disableall = this.userManagementService.disablefield;
      this.isUserNameDisable = true;
      this.isUserTypeDisable = true;
      this.disableIcon = !this.userManagementService.icondisable;
    }
    this.user.emailId = this.userManagementService.selectedUserDetails.email;
    this.user.userType = this.userManagementService.selectedUserDetails.userType;
    this.user.ldapDomain = this.userManagementService.selectedUserDetails.ldapDomain;
    this.user.bu  = this.userManagementService.selectedUserDetails.bu;
    this.user.remark  = this.userManagementService.selectedUserDetails.remark;

    this.user.company  = this.userManagementService.selectedUserDetails.company;
    this.user.phone  = this.userManagementService.selectedUserDetails.phone;
    this.user.line  = this.userManagementService.selectedUserDetails.line;
    this.user.whatsapp  = this.userManagementService.selectedUserDetails.whatsapp;
    this.user.wechat  = this.userManagementService.selectedUserDetails.wechat;
    this.user.telegram  = this.userManagementService.selectedUserDetails.telegram;

    if (this.userManagementService.selectedUserDetails.userType === 'Internal') {
      type = 0;
      this.showExpirationDate = false;
    }
    this.loadData(this.userManagementService.selectedUserDetails.userId, type, true,module);
  }

  public ngOnInit() {
    this.navService.setPageTitle(this.resourceService.get('mn_manage-user'));
    this.btnRestrictionAdd = this.checkButtonPrivilege('usr_rest_add');
    this.btnRestrictionDelete = this.checkButtonPrivilege('usr_rest_del');
    this.btnRestrictionEdit = this.checkButtonPrivilege('usr_edit');
      //    console.log("Console",this.userManagementService.selectedUserDetails_LookUp.userType);

    //LFWM-2363
    this.isSuperAdminUser();
  }
  //LFWM-2363- checking user is super admin or not  and setting External user option
  public isSuperAdminUser(){
   
  this.userManagementService.isSuperAdmin().subscribe((resp) => {
      if(resp.isUserSuperAdmin|| this.isEditFlow === true){
        this.types.push('External');
        this.types.push('Recipient');
      }
      /*LFWM-2511 UI changes for reset password */
      /*Display reset password with login user role( Admin or SuperAdmin )
       * and EditMode 
       * and Application WMS
       * and userType External*/
      if(resp.isUserSuperAdmin || resp.isUserAdmin){
        if(this.isEditFlow === true &&
          this.user.applicationCode === 1 &&(
          this.user.userType === 'External' ||this.user.userType === 'Recipient')
          ){
          this.enableResetPasswordButton = true;
        }
        if(this.isEditFlow === true &&
          this.user.applicationCode === 1 
          ){
          this.enableActiveInactiveButton = true;
        }
      }
       this.getAllButtons();
      return resp.isUserSuperAdmin;
    }, (error) => {
      return false;
    });
    return false;
  }
  public checkButtonPrivilege(privilegeCode: String) {
    return this.userService.checkButtonPrivilege(privilegeCode);
  }
  // method used to translate the labels
  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  // method to check is this is add user flow or edit user flow
  public checkIfThisIsAnEditFlow() {
    return (this.userManagementService.selectedUserDetails !== undefined)
      && (this.userManagementService.selectedUserDetails !== null)
      && (this.userManagementService.selectedUserDetails.userId !== undefined)
      && (this.userManagementService.selectedUserDetails.userId !== 0);
  }
  // method used to add restrictions to the user
  public addRestrictionsForUser(user: any) {
    if (user !== null && user.userRestrictions !== null) {
      user.userRestrictions.forEach((countryRestriction: any) => {
        const country = this.getCountryForId(countryRestriction.countryId);
        countryRestriction.restrictions.forEach((restriction: any) => {
          let restrictionVal = restriction.restrictionValue;
          if (restrictionVal === null || restrictionVal === '') {
            restrictionVal = 'No Restriction';
          }
          /*** LFWM-2242 LF SCE Dashboard - User Management */
          if (this.userManagementService.appCode === 4) {
            const dshbModuleCode = this.getDshbModuleForId(restriction.moduleCode);
            this.userlist.push({
              'countryId': country.countryId,
              'cntrycode': country.code,
              'id': restriction.restrictionTypeId,
              'restriction': restriction.restrictionTypeName,
              'Value': restrictionVal,
              'dshbModuleId': restriction.moduleCode,
              'dshbModuleCode': dshbModuleCode ? dshbModuleCode.code : ''
            });
          } else {
          this.userlist.push({
            'countryId': country.countryId,
            'cntrycode': country.code,
            'id': restriction.restrictionTypeId,
            'restriction': restriction.restrictionTypeName,
            'Value': restrictionVal
          });
        }
        });
      });
      this.userlist.sort(this.compareCountryRestrictions);
    }
  }
  // method used to add roles to the user
  public addRolesForUser(userRsp: any) {
    if (userRsp !== null && userRsp.userRoles !== null) {
      let countryRole = null;
     
      userRsp.userRoles.forEach((roleRsp) => {
        if(this.userManagementService.appCode === 1 || this.userManagementService.appCode === 5 
          || this.userManagementService.appCode === 6  || this.userManagementService.appCode ===2 ||
          this.userManagementService.appCode === 8 || this.userManagementService.appCode === 9){
          countryRole = this.getRoleForCountry(roleRsp.countryId);
        } else  if (this.userManagementService.appCode === 4) { /*** LFWM-2242 LF SCE Dashboard - User Management */
          countryRole = this.getRoleForCountryForDshbd(roleRsp.countryId,roleRsp.moduleCode);
        }else if (this.userManagementService.appCode === 3 || this.userManagementService.appCode === 7) {
          countryRole = this.getResValDlForCountry(roleRsp);
        }
        else {
          countryRole = this.getResValForCountry(roleRsp.restrictionVal);
        }
        const roleGrp = this.getRoleGroupForGrpId(roleRsp.groupId);
        const role = this.getRoleForGrpIdRoleId(roleRsp.groupId, roleRsp.roleId);
        const country = this.getCountryForId(roleRsp.countryId);
        /*** LFWM-2242 LF SCE Dashboard - User Management
         * To add dashboard module and restriction
        */
        let dshbModule = null;
        let restrictionType = null;
        if (this.userManagementService.appCode === 4 && roleRsp.moduleCode) {
          dshbModule = this.getDshbModuleForId(roleRsp.moduleCode);
          restrictionType = this.getModuleRestriction(roleRsp.moduleCode);
        }
       
        if (!country) {
          return;
          // will need to break from here
        }
        // if there are no groups, probably its a deleted group/role. Show the roles
        // and groups for the country to be unselected

        if (countryRole === null || countryRole === undefined ) {
          // if the country is not yet added, add a new entry in the list
          countryRole = new UserCountryRole();
          countryRole.countryId = country.countryId;

          countryRole.countryCode = country.code;
          /*** LFWM-2242 LF SCE Dashboard - User Management */
          if (this.userManagementService.appCode === 4 && dshbModule !== null) {
            countryRole.dshbModuleId = dshbModule.id;
            countryRole.dshbModuleCode = dshbModule.code;
            countryRole.restrictionType = restrictionType;
            countryRole.restrictionVal = roleRsp.restrictionVal;
          }
          countryRole.allGroups = this.allRoleGroups.slice(0);
          if (this.userManagementService.appCode === 4){
            if (this.roleGroupMap[roleRsp.groupId]) {
              /* added this filter so that only the roles with countryid same as selected
               countryId will be listed in the roles dropdown */
              countryRole.allRoles = this.roleGroupMap[roleRsp.groupId]
            } else {
              countryRole.allRoles = [];
              countryRole.showRoles = false;
            }
          }else{
            if (this.roleGroupMap[roleRsp.groupId]) {
              /* added this filter so that only the roles with countryid same as selected
               countryId will be listed in the roles dropdown */
              countryRole.allRoles = this.roleGroupMap[roleRsp.groupId].filter(roleEach =>
                (roleEach.country === countryRole.countryId)
                // && (roleRsp.roleId === roleEach.id)
              );
  
            } else {
              countryRole.allRoles = [];
              countryRole.showRoles = false;
            }
          }
          if (role && roleGrp) {
            countryRole.selectedGroupIdsForDisplay.push(roleGrp.id);
            for (let j = 0; j < countryRole.allRoles.length; j++) {
              if (countryRole.allRoles[j].id === role.id) {
                countryRole.showRoles = true;
                countryRole.selectedRoleIdsForDisplay.push(role.id);
              }
            }
          }
          if (countryRole.allRoles.length < 1) {
            countryRole.allRoles = [];
          }
      	  if(this.userManagementService.appCode === 2 || this.userManagementService.appCode === 3 || this.userManagementService.appCode === 7){
          countryRole.restrictionVal = roleRsp.restrictionVal;
	  }

          if (this.showExpirationDate) {
            countryRole.expirationDate = roleRsp.expirationDate;
          } else {
            countryRole.expirationDate = null;
          }
          this.userCountryRoleList.push(countryRole);
        } else {
          /*** LFWM-2242 LF SCE Dashboard - User Management
           * To add dashbaord module and restriction
          */
          if (this.userManagementService.appCode === 4 && dshbModule !== null) {
            countryRole.dshbModuleId = dshbModule.id;
            countryRole.dshbModuleCode = dshbModule.code;
            countryRole.restrictionType = restrictionType;
          }
          // add group to the selected groups
          if (countryRole.selectedGroupIdsForDisplay.indexOf(roleRsp.groupId) === -1 && roleGrp) {
            if(this.userManagementService.appCode === 4){
              this.roleGroupMap[roleRsp.groupId].map(roleEach => {
                if ((roleRsp.roleId === roleEach.id)) {
                  // only if any role is assigned for a group then only that particular group needs to be shown as ticked
                  countryRole.selectedGroupIdsForDisplay.push(roleRsp.groupId);
                            // add role to the selected groups
                  countryRole.allRoles = countryRole.allRoles.concat(roleEach);
                  if (countryRole.selectedRoleIdsForDisplay.indexOf(roleRsp.roleId) === -1 && role) {
                    countryRole.selectedRoleIdsForDisplay.push(roleRsp.roleId);
                      countryRole.showRoles = true;
                  }
                }
              });
            }else{
              this.roleGroupMap[roleRsp.groupId].map(roleEach => {
                if ((roleEach.country === roleRsp.countryId) && (roleRsp.roleId === roleEach.id)) {
                  // only if any role is assigned for a group then only that particular group needs to be shown as ticked
                  countryRole.selectedGroupIdsForDisplay.push(roleRsp.groupId);
                            // add role to the selected groups
                  countryRole.allRoles = countryRole.allRoles.concat(roleEach);
                  if (countryRole.selectedRoleIdsForDisplay.indexOf(roleRsp.roleId) === -1 && role) {
                    countryRole.selectedRoleIdsForDisplay.push(roleRsp.roleId);
                      countryRole.showRoles = true;
                  }
                }
              });
            }
            

          } else { // to add role to an already added group
            if (countryRole.selectedRoleIdsForDisplay.indexOf(roleRsp.roleId) === -1 && role) {
              countryRole.selectedRoleIdsForDisplay.push(roleRsp.roleId);
                countryRole.showRoles = true;
            }
          }


        }
      });
      this.userCountryRoleList.sort(this.compareCountryRoles);
    }
  }
  // method used to compare country and roles
  public compareCountryRoles(countryRole1: UserCountryRole, countryRole2: UserCountryRole) {
    if (countryRole1.countryCode === countryRole2.countryCode) {
      return 0;
    } else if (countryRole1.countryCode > countryRole2.countryCode) {
      return 1;
    } else {
      return -1;
    }
  }
  // method used to compare country and restrictions
  public compareCountryRestrictions(restriction1, restriction2) {
    if (restriction1.cntrycode === restriction2.cntrycode) {
      if (restriction1.restriction === restriction2.restriction) {
        return 0;
      } else if (restriction1.restriction > restriction2.restriction) {
        return 1;
      } else {
        return -1;
      }
    } else if (restriction1.cntrycode > restriction2.cntrycode) {
      return 1;
    } else {
      return -1;
    }
  }
  // method used to get role group by using group id
  public getRoleGroupForGrpId(grpId: any) {
    let rolegrp = null;
    this.allRoleGroups.forEach((grp: any) => {
      if (grp.id === grpId) {

        rolegrp = grp;
      }
    });
    return rolegrp;
  }
public createNewUserCountryRoleList(restrictonValue:any){
           
    let countryRole = new UserCountryRole();
    countryRole.countryId = this.restrictionRoles.countryId;
    countryRole.countryCode = this.restrictionRoles.cntrycode;
    countryRole.allGroups = this.allRoleGroups.slice(0);
    countryRole.allRoles = [];
    countryRole.showRoles = false;
    countryRole.expirationDate = null;
    countryRole.disableDelete = true;
    countryRole.restrictionVal = restrictonValue;
    return countryRole;
}

  public addRestrictionforGVTUser(userCountryRoleList: any,restrictionValue:any){
    console.log("restrictionValue", restrictionValue)
    if(restrictionValue === '*') {
      restrictionValue= 'No Restriction';
    }
    console.log("356 : userCountryRoleList", userCountryRoleList)
    restrictionValue.split(',').forEach(element => {
      let newCountryRole = this.createNewUserCountryRoleList(element);
      userCountryRoleList.push(newCountryRole);
    });     
    return userCountryRoleList;
  }

  public getRoleGroupForGrpId1(grpId: any) {
    let rolegrp = null;
    this.allRoleGroups.forEach((grp: any) => {
      if (grp.id === grpId) {

        rolegrp = grp;
      }
    });
    return rolegrp;
  }

  // method used to get role by using group id and role id
  public getRoleForGrpIdRoleId(grpId: any, roleId: any) {
    const roleGrp = this.getRoleGroupForGrpId(grpId);
    let role = null;
    this.allRoleGroupsInfo.forEach((groupObj: any) => {
      if (groupObj.groupId === grpId) {
        groupObj.roles.forEach((roleObj: any) => {
          if (roleObj.id === roleId) {
            role = roleObj;
          }
        });
      }
    });

    return role;
  }
  // method used to get country by using country id
  public getCountryForId(countryId: any) {
    let country = null;
    this.countries.forEach((element) => {
      if (element.countryId === countryId) {
        country = element;
      }
    });
    return country;
  }
  /*** LFWM-2242 LF SCE Dashboard - User Management
   * Get dashboard module name from its Id
   */
  public getDshbModuleForId(moduleID: any) {
    let dshbModule = null;
    this.dashboardModules.forEach(element => {
      if (element.id === moduleID) {
        dshbModule = element;
      }
    });
    return dshbModule;
  }
  /*** LFWM-2242 LF SCE Dashboard - User Management
   * Get restriction corresponding todashbaord module
   */
  public getModuleRestriction(dshbModuleId) {
    let res = null;
    if (dshbModuleId === 'FD' || dshbModuleId === 'BPD'|| dshbModuleId === 'TD') {
      res = 'Client';
    } else if (dshbModuleId === 'CO') {
      res = 'Storer';
    }else if (dshbModuleId === 'OD') {
      res = 'StorerKey';
    }
    return res;
  }
  // method used to load the initial data on page load
  public loadData(userId: any, userType: any, existingUser: any, moduleId: any) {
    const getAllApps = this.userManagementService.getAllUserApps();
    const getAllCountries = this.userManagementService.getAllCountries();
    const getAllRestrictions = this.userManagementService.getAllRestrictions();
    const getAllRoleGroups = this.userManagementService.getAllRoleGroups();
    // const getRestrictedCountries = this.userManagementService.getRestrictedCountries(this.userManagementService.appCode);
    const getRestrictedCountries = this.userManagementService.getUserCountries(this.userManagementService.appCode);

    return forkJoin([getAllApps, getAllCountries, getAllRestrictions, getAllRoleGroups,
      getRestrictedCountries]).subscribe((results) => {
        const allCountry = results[1];
        // JIRA LFWM-717 fixes
        const restrictCountry = results[4];
        allCountry.forEach(element => {
          restrictCountry.forEach(countryId => {
            if (countryId === element.countryId) {
              element.enabledCntry = true;
            }
            /*** LFWM-2242 LF SCE Dashboard - User Management
             * Add Global country for Dashboard App
             */
            if (element.code === 'GBL' && this.userManagementService.appCode === 4 && moduleId !== 'BD') {
              element.enabledCntry = true;
            }
            if (element.code === 'GLO' && this.userManagementService.appCode === 5 ) {
              element.enabledCntry = true;
            }
            if (element.code === 'GBL' && (this.userManagementService.appCode === 3 || this.userManagementService.appCode === 7)) {
              element.enabledCntry = true;
            }
          });
        });
        this.codes = results[0];
        // JIRA LFWM-688 Fixes
        this.countries = results[1].sort((country1, country2) => {
          if (country1.code > country2.code) {
            return 1;
          }
          if (country1.code < country2.code) {
            return -1;
          }
          return 0;
        });
        this.restriction = results[2];
        const allRoleGroups = results[3];
        this.allRoleGroupsInfo = allRoleGroups;
        this.allRoleGroups = [];
        this.roleGroupMap = {};
        this.restriction = this.restriction.filter((item: any) => {
          if (this.userManagementService.appCode === 1) {
            // if ((item.value !== 'Client' && item.value !== 'Vendor' && item.value !== 'Forwarder' && 
            // item.value !== 'Application' && item.value !== 'StorerKey' && item.value !== 'Bill To Party'
            // && item.value !== 'Ship To Party')) {
            if(item.value == "Facility" || item.value == "Storer" || item.value == "Seller"){
              return item;
            }
          } else if (this.userManagementService.appCode === 2) {
            if ((item.value === 'Client')|| (item.value === 'Bill To Party') ||
            (item.value === 'Ship To Party')) {
              return item;
            }
          }  else if (this.userManagementService.appCode === 4) {
            /*** LFWM-2242 LF SCE Dashboard - User Management
             * All restrictions are returned
             * Restriction is based on module selected
             */
            if (moduleId === 'FD' || moduleId === 'BPD') {
              if ((item.value === 'Client')) {
                return item;
              }
            } else if (moduleId === 'OD') {
              if ((item.value === 'StorerKey')) {
                return item;
              }
            } else {
                return item;
            }
          }  else if (this.userManagementService.appCode === 5) {
            if ((item.value === 'Client')) {
              return item;
            }
          }  else if (this.userManagementService.appCode === 6) {
            // POMS-3 LFSCE POMS user management changes
            if ((item.value === 'Client') || (item.value === 'Vendor') || (item.value === 'Forwarder')) {
              return item;
            }
          }else if (this.userManagementService.appCode === 3 || this.userManagementService.appCode === 7) {
            if ((item.value === 'Application')) {
              return item;
            }
          }else if(this.userManagementService.appCode === 8){
            if(item.value === 'Tenant ID' || item.value === 'Location ID' || item.value === 'Principal'|| item.value == 'Carrier'){
              return item;
            }
          }else if (this.userManagementService.appCode === 9) {
            if (item.value == "Facility" || item.value == "Storer") {
              return item;
            }
          }
        });
        let getModuleRest = this.getModuleRestriction(moduleId);
        this.dshbRestriction = this.restriction.filter((item: any) => {
          if ((item.value === getModuleRest)) {
            this.user.restriction = item.id;
            return item;
          }
        });
        allRoleGroups.forEach((roleGroup: any) => {
          this.allRoleGroups.push({ 'id': roleGroup.groupId, 'name': roleGroup.groupName, 'country': roleGroup.countryId });
          const roleList = [];
          roleGroup.roles.forEach((role: any) => {
            roleList.push({ 'id': role.id, 'name': role.role, 'grpId': roleGroup.groupId, 'country': role.countryId });
          });
          this.roleGroupMap[roleGroup.groupId] = roleList;
        });

        if (userId === undefined) {
          existingUser = false;
        }
        if (existingUser && userId !== null) {
          this.user.applicationCode = this.userManagementService.appCode;
          if (moduleId === 'OD') {
            this.user.dshbModuleId = 'OD';
          } else if (moduleId === 'FD') {
            this.user.dshbModuleId = 'FD';
          } else if (moduleId === 'BPD') {
            this.user.dshbModuleId = 'BPD';
	  }else if (moduleId === 'TD') {
            this.user.dshbModuleId = 'TD';
          } else {
            moduleId = '';
          }
          this.userManagementService.getUser(userId, userType, this.userManagementService.appCode,moduleId).subscribe((user) => {
            this.user.active = user.active;
            this.user.ldapDomain = user.ldapDomain;
            this.addRestrictionsForUser(user);
            this.addRolesForUser(user);
            this.modifiedUserList = this.getUserlistModified();
            if ((this.userManagementService.appCode === 4 && moduleId) || this.userManagementService.appCode !== 4) {
              this.userCountryRoleListForTemplate = this.getUserCountryRoleList();
            } else {
              this.userCountryRoleListForTemplate = [];
            }
            if (this.userCountryRoleListForTemplate.length === 0 && moduleId === 'OD') {
              this.alertservice.clearAll().error('Please save WMS application again for getting Operation Dashboard restrictions!');
            }
            if (this.userManagementService.appCode === 2) {
              console.log("508 : userCountryRoleListForTemplate-loaddata",this.userCountryRoleListForTemplate);
              let roleList = [];
              let modifiedList = [];
              this.modifiedUserList.filter((usr) => { 
                this.filteredCountries.filter((countryDetail) => { 
                  if (countryDetail.countryId === usr.countryId) {
                    modifiedList.push(usr);
                  }
                });
              });
              this.userCountryRoleListForTemplate.filter((role) => { 
                this.filteredCountries.filter((countryDetail) => { 
                  if (countryDetail.code === role.countryCode) {
                    roleList.push(role);
                  }
                });
              });
              this.userCountryRoleListForTemplate = roleList;
              //this.userCountryRoleListForTemplate = this.addRestrictionforGVTUser(this.getUserCountryRoleList(), this.userlist[0].restrictionVal);
            }
            

          });
        } /* Deleted an else if statement that delt with an unwanted derver call */
        this.filteredCountries = this.getFilteredCountries();
        if(this.userManagementService.appCode === 4 && this.user.dshbModuleId === 'TD'){
          this.filteredCountries = this.filteredCountries.filter((cntry)=>{
           if(cntry.code === 'GBL'){
            return cntry
           }
          })
        }
        if (!(existingUser && userId !== null)) {
          this.modifiedUserList = this.getUserlistModified();
          this.userCountryRoleListForTemplate = this.getUserCountryRoleList();
        }
      });

  }

  public getFilteredCountries() {
    return this.countries.filter((cntry) => cntry.enabledCntry);
  }
  // method to show and hide delete symbol based on user restriction
  public getUserlistModified() {
    this.userlist.forEach((usr) => {
      if (this.getFilteredCountries().filter((countryDetail) => countryDetail.code === usr.cntrycode).length > 0) {
        usr.disableDelete = true;
      }
    });
    return this.userlist;
  }
  // method to show and hide delete symbol based on user restriction for country role
  public getUserCountryRoleList() {
    this.userCountryRoleList.forEach((usrCntryRole) => {
      if (this.getFilteredCountries().filter((filterCountry) => filterCountry.code === usrCntryRole.countryCode).length > 0) {
        usrCntryRole.disableDelete = true;
      }
    });
    return this.userCountryRoleList;
  }
  // method used to get role by using country id
  public getRoleForCountry(countryId: any) {
    let countryRole = null;
    this.userCountryRoleList.forEach((role: any) => {
      if (role.countryId && role.countryId === countryId) {
        countryRole = role;
      }
    });
    return countryRole;
  }
  public getRoleForCountryForDshbd(countryId: any,modulecode : any) {
    let countryRole = null;
    this.userCountryRoleList.forEach((role: any) => {
      if (role.countryId && role.countryId === countryId && role.dshbModuleId === modulecode) {
        countryRole = role;
      }
    });
    return countryRole;
  }
  public getResValForCountry(resVal: any) {
    let countryRole = null;
    this.userCountryRoleList.forEach((role: any) => {
      if (role.restrictionVal && role.restrictionVal === resVal) {
        countryRole = role;
      }
    });
    return countryRole;
  }

  public getResValDlForCountry(resVal: any) {
    let countryRole = null;
    this.userCountryRoleList.forEach((role: any) => {
      if ((role.restrictionVal && (role.restrictionVal === resVal.restrictionVal)) 
        && (role.countryId === resVal.countryId) 
        ) {
        countryRole = role;
      }
    });
    return countryRole;
  }
  
  // make the coresponding button enable or disable
  public getUser() {
    this.adduser = true;
    this.edituser = false;
    this.disable = false;
  }
  // method used to add country, restriction type and value to the existing user or new user
  public addRow() {
    let verify = false;
    this.countrycodeId = this.user.countryCode;
    if (this.userManagementService.appCode == 3 || this.userManagementService.appCode === 7) {
      this.validateApplications(this.user.Value);
    }
    this.countries.forEach((element: any) => {
      const parsedCountryCode = parseInt(this.user.countryCode, 10);
      if (parsedCountryCode === element.countryId) {
        this.restrictionRoles.countryCode = element.name;
        this.restrictionRoles.countryId = element.countryId;
        this.restrictionRoles.cntrycode = element.code;
      }
    });

    this.restriction.forEach((element: any) => {
      const parsedRestriction = parseInt(this.user.restriction, 10);
      if (parsedRestriction === element.id) {
        this.restrictionRoles.restriction = element.value;
        this.restrictionRoles.id = element.id;
      }
    });
    this.restrictionRoles.Value = this.user.Value;
    if ((this.userManagementService.appCode === 3 ) && this.user.Value === '*') {
      this.alertservice.clearAll().error(this.resourceService.get('"*" is not a supported restriction value!'));
      return;
    }
    if ((this.userManagementService.appCode === 3 || this.userManagementService.appCode === 7) && this.appAccessError !== null) {
      this.alertservice.clearAll().error(this.resourceService.get('Invalid value for restriction'));
      return;
    }
    if (this.restrictionRoles.countryCode !== '' && this.restrictionRoles.countryCode !== undefined) {
      if (this.user.restriction !== '' && this.user.restriction !== undefined) {
        if (this.user.Value !== '' && this.user.Value !== undefined) {
          this.userlist.forEach((element: any) => {
            if ((element.cntrycode === this.restrictionRoles.cntrycode)
              && (element.restriction === this.restrictionRoles.restriction)) {
              verify = true;
              if (this.restrictionRoles.Value === 'No Restriction') {
                for (let i = 0; i < this.userlist.length; i++) {
                  if ((this.userlist[i].cntrycode === this.restrictionRoles.cntrycode)
                    && (this.userlist[i].restriction === this.restrictionRoles.restriction)) {
                    this.userlist.splice(i, 1, this.restrictionRoles);
                    if( this.userManagementService.appCode ===3 || this.userManagementService.appCode === 7){
                      this.userCountryRoleListForTemplate=this.addRestrictionforGVTUser(this.getUserCountryRoleList(),this.restrictionRoles.Value);
                    }
                    this.modifiedUserList = this.getUserlistModified();
                  }
                }
                this.restrictionRoles = {};
                this.user.Value = '';
                this.checkCountry = this.userlist[this.userlist.length - 1].countryCode;
                this.checkRestriction = this.userlist[this.userlist.length - 1].restriction;
              } else {
                if (this.restrictionRoles.Value === '*') {
                  this.restrictionRoles.Value = 'No Restriction';
                  this.checkvalue = '';
                } else {
                  if (this.restrictionRoles.Value !== '' && this.restrictionRoles.Value !== 'No Restriction') {
                    for (let i = 0; i < this.userlist.length; i++) {
                      if ((this.userlist[i].cntrycode === this.restrictionRoles.cntrycode)
                        && (this.userlist[i].restriction === this.restrictionRoles.restriction)) {
                        if (this.userlist[i].Value !== 'No Restriction') {
                          //this.restrictionRoles.Value = this.userlist[i].Value + ',' + this.restrictionRoles.Value;

                        // [LFWM-2173][User management should validate duplicate values for storer/facility/seller]
                     
                            if (!this.userlist[i].Value.includes(this.restrictionRoles.Value) || this.userlist[i].Value != this.restrictionRoles.Value){
                              if(this.userManagementService.appCode ===3 || this.userManagementService.appCode === 7 || this.userManagementService.appCode === 8){
                                this.userCountryRoleListForTemplate=this.addRestrictionforGVTUser(this.userCountryRoleListForTemplate,this.restrictionRoles.Value);
                              }
                              this.restrictionRoles.Value = this.userlist[i].Value + ',' + this.restrictionRoles.Value;
                              this.restrictionRolesCopy=  this.restrictionRoles.Value;
                            } else{
                                this.alertservice.clearAll().error(this.resourceService.get('msg_um_DuplicateValueRestrcton'));
                                this.restrictionRoles= this.restrictionRolesCopy;
                              }
                              this.modifiedUserList = this.getUserlistModified();     
                        }
                      }
                    }
                  }
                }
                for (let i = 0; i < this.userlist.length; i++) {
                  if ((this.userlist[i].cntrycode === this.restrictionRoles.cntrycode)
                    && (this.userlist[i].restriction === this.restrictionRoles.restriction)) {
                      // [LFWM-2173][User management should validate duplicate values for storer/facility/seller]
                      var role = [];
                      if(this.restrictionRoles.Value.includes(',')){
                         role=this.restrictionRoles.Value.split(',');
                      }
                      var values = Object.create(null);
                      var duplicatesExist=false;
                      for (let i = 0; i < role.length; ++i) {
                          var value = role[i];
                          if (value in values) {
                            duplicatesExist=true;
                          }
                          values[value] = true;
                      }
                    if(!duplicatesExist){
                      this.userlist.splice(i, 1, this.restrictionRoles);
                    } else {
                      this.alertservice.clearAll().error(this.resourceService.get('msg_um_DuplicateValueRestrcton'));
                    }
                    this.modifiedUserList = this.getUserlistModified();
                  }
                }
                this.restrictionRoles = {};
                this.user.Value = '';
                this.user.restriction = undefined;
                this.checkCountry = this.userlist[this.userlist.length - 1].countryCode;
                this.checkRestriction = this.userlist[this.userlist.length - 1].restriction;
              }
            }
          });
          if (!verify) {
            let countryRole = this.getRoleForCountry(this.restrictionRoles.countryId);
            if(this.userManagementService.appCode === 1 || this.userManagementService.appCode === 5 || 
              this.userManagementService.appCode === 6 || this.userManagementService.appCode === 2 || this.userManagementService.appCode === 8  || this.userManagementService.appCode === 9){
            if (countryRole === null || countryRole === undefined) {
              countryRole = new UserCountryRole();
              countryRole.countryId = this.restrictionRoles.countryId;
              countryRole.countryCode = this.restrictionRoles.cntrycode;
              countryRole.allGroups = this.allRoleGroups.slice(0);
              countryRole.allRoles = [];
              countryRole.showRoles = false;
              countryRole.expirationDate = null;
              this.userCountryRoleList.push(countryRole);
              this.userCountryRoleListForTemplate = this.getUserCountryRoleList();
            }}
            if(this.userManagementService.appCode ===3 || this.userManagementService.appCode === 7){
            if (countryRole === null || countryRole === undefined) {
                this.userCountryRoleListForTemplate=this.addRestrictionforGVTUser(this.getUserCountryRoleList(),this.restrictionRoles.Value)
            }}
            if (this.restrictionRoles.Value === '*') {
              this.restrictionRoles.Value = 'No Restriction';
              this.userlist.push(this.restrictionRoles);
              this.modifiedUserList = this.getUserlistModified();
              this.restrictionRoles = {};
              this.user.Value = '';
              this.user.restriction = undefined;
              this.checkCountry = this.userlist[this.userlist.length - 1].countryCode;
              this.checkRestriction = this.userlist[this.userlist.length - 1].restriction;
              this.checkvalue = this.userlist[this.userlist.length - 1].Value;
            } else {
              // [LFWM-2173][User management should validate duplicate values for storer/facility/seller][to validate when user enters comma separated values]
              var role = [];
              if(this.restrictionRoles.Value.includes(',')){
                 role=this.restrictionRoles.Value.split(',');
               
              }
              var values = Object.create(null);
              var duplicatesExist=false;
              for (let i = 0; i < role.length; ++i) {
                  var value = role[i];
                  if (value in values) {
                    duplicatesExist=true;
                  }
                  values[value] = true;
              }
            console.log('duplicatesExist',duplicatesExist);
            if(!duplicatesExist){
              this.userlist.push(this.restrictionRoles);
            }
            else{
              this.alertservice.clearAll().error(this.resourceService.get('msg_um_DuplicateValueRestrcton'));
            }
              this.modifiedUserList = this.getUserlistModified();
              this.restrictionRoles = {};
              this.user.Value = '';
              this.user.restriction = undefined;
              this.checkCountry = this.userlist[this.userlist.length - 1].countryCode;
              this.checkRestriction = this.userlist[this.userlist.length - 1].restriction;
              this.checkvalue = this.userlist[this.userlist.length - 1].Value;
            }
          }
        } else {
          this.alertservice.clearAll().error(this.resourceService.get('msg_um_EntrVlidRestrcton'));
        }
      } else {
        this.alertservice.clearAll().error(this.resourceService.get('msg_um_AddRestrForCountry'));
      }
    } else {
      this.alertservice.clearAll().error(this.resourceService.get('msg_um_slctCntryCode'));
    }
    if(this.userCountryRoleListForTemplate.length === 1 && this.userManagementService.appCode === 7) {
      // for(let i=0;i<this.userCountryRoleListForTemplate.length;i++){
         this.userCountryRoleListForTemplate[0].restrictionVal = this.userlist[0].Value;
      // }
     }
    this.showtable = true;
  }
  /*** LFWM-2242 LF SCE Dashboard - User Management
   * To add a restriction for dashboard application
   */
  public addRowDashboard() {
    let verify = false;
    this.countrycodeId = this.user.countryCode;
    this.countries.forEach((element: any) => {
      const parsedCountryCode = parseInt(this.user.countryCode, 10);
      if (parsedCountryCode === element.countryId) {
        this.restrictionRoles.countryCode = element.name;
        this.restrictionRoles.countryId = element.countryId;
        this.restrictionRoles.cntrycode = element.code;
      }
    });

    this.restriction.forEach((element: any) => {
      const parsedRestriction = parseInt(this.user.restriction, 10);
      if (parsedRestriction === element.id) {
        this.restrictionRoles.restriction = element.value;
        this.restrictionRoles.id = element.id;
      }
    });
    this.dashboardModules.forEach(element => {
      const parsedModuleId = this.user.dshbModuleId;
      if (parsedModuleId === element.id) {
        this.restrictionRoles.dshbModuleCode = element.code;
        this.restrictionRoles.dshbModuleId = element.id;
      }
    });
    if (this.restrictionRoles.countryCode !== '' && this.restrictionRoles.countryCode !== undefined) {
      if (this.restrictionRoles.cntrycode !== 'GBL' && 
      ( this.restrictionRoles.dshbModuleId === 'FD' ||  this.restrictionRoles.dshbModuleId === 'BPD') ) {
        this.alertservice.clearAll().error(this.resourceService.get('msg_um_EntrGlbCntry'));
      } else {
        if (this.user.restriction !== '' && this.user.restriction !== undefined) {
          if (this.user.Value !== '' && this.user.Value !== undefined) {
            if (this.user.dshbModuleId !== '' && this.user.dshbModuleId !== undefined) {
              this.getCountryRoleListForDshb(this.restrictionRoles,this.user.Value);
              this.userCountryRoleListForTemplate = this.getUserCountryRoleList();
              this.userlist.forEach((element: any) => {
                if ((element.cntrycode === this.restrictionRoles.cntrycode)
                  && (element.restriction === this.restrictionRoles.restriction)
                  && (element.dshbModuleCode === this.restrictionRoles.dshbModuleCode)) {
                  verify = true;
                  if (this.restrictionRoles.Value === 'No Restriction') {
                    for (let i = 0; i < this.userlist.length; i++) {
                      if ((this.userlist[i].cntrycode === this.restrictionRoles.cntrycode)
                        && (this.userlist[i].restriction === this.restrictionRoles.restriction)
                        && (this.userlist[i].dshbModuleCode === this.restrictionRoles.dshbModuleCode)) {
                        this.userlist.splice(i, 1, this.restrictionRoles);
                        console.log("Inside No Restriction");
                        // if(this.userManagementService.appCode ===2){
                        //   this.userCountryRoleListForTemplate=this.addRestrictionforGVTUser(this.getUserCountryRoleList(),this.restrictionRoles.Value);
                        // }
                        this.modifiedUserList = this.getUserlistModified();
                      }
                    }
                    this.restrictionRoles = {};
                    this.user.dshbModuleID = undefined;
                    this.user.Value = '';
                    this.checkCountry = this.userlist[this.userlist.length - 1].countryCode;
                    this.checkRestriction = this.userlist[this.userlist.length - 1].restriction;
                    // this.enableDshbRestriction = false;
                  } else {
                    if (this.restrictionRoles.Value === '*') {
                      this.restrictionRoles.Value = 'No Restriction';
                      this.user.Value = '';
                      this.checkvalue = '';
                    } else {
                      if (this.restrictionRoles.Value !== '' && this.restrictionRoles.Value !== 'No Restriction') {
                        for (let i = 0; i < this.userlist.length; i++) {
                          if ((this.userlist[i].cntrycode === this.restrictionRoles.cntrycode)
                            && (this.userlist[i].restriction === this.restrictionRoles.restriction)
                            && (this.userlist[i].dshbModuleCode === this.restrictionRoles.dshbModuleCode)) {
                            if (this.userlist[i].Value !== 'No Restriction') {
                              //this.restrictionRoles.Value = this.userlist[i].Value + ',' + this.restrictionRoles.Value;
                              // if(this.userManagementService.appCode ===2){
                              //   this.userCountryRoleListForTemplate=this.addRestrictionforGVTUser(this.userCountryRoleListForTemplate,this.restrictionRoles.Value);
                              // }
                              // [LFWM-2173][User management should validate duplicate values for storer/facility/seller]
                              
                              // if (this.userlist[i].Value && (!this.userlist[i].Value.includes(this.user.Value) || this.userlist[i].Value != this.user.Value)){
                              //   this.restrictionRoles.Value = this.userlist[i].Value + ',' + this.user.Value;
                              //   this.restrictionRolesCopy=  this.user.Value;
                              // } else if (!this.userlist[i].Value) {
                              //   this.restrictionRoles.Value = this.user.Value;
                              //   this.restrictionRolesCopy=  this.user.Value;
                              // } else if (this.userlist[i] && !this.userlist[i].Value){
                              //   this.restrictionRoles.Value = this.user.Value;
                              //   this.restrictionRolesCopy=  this.user.Value;
                              // } else{
                              //   this.alertservice.clearAll().error(this.resourceService.get('msg_um_DuplicateValueRestrcton'));
                              //   this.restrictionRoles= this.restrictionRolesCopy;
                              // }
                              this.modifiedUserList = this.getUserlistModified();
                            } 
                          }
                        }
                      }
                    }
                    for (let i = 0; i < this.userlist.length; i++) {
                      if ((this.userlist[i].cntrycode === this.restrictionRoles.cntrycode)
                        && (this.userlist[i].restriction === this.restrictionRoles.restriction)
                        && (this.userlist[i].dshbModuleCode === this.restrictionRoles.dshbModuleCode)) {
                        // [LFWM-2173][User management should validate duplicate values for storer/facility/seller]
                      var role = [];
                      if(this.restrictionRoles.Value && this.restrictionRoles.Value.includes(',')){
                         role=this.restrictionRoles.Value.split(',');
                      }
                      var values = Object.create(null);
                      var duplicatesExist=false;
                      for (let i = 0; i < role.length; ++i) {
                          var value = role[i];
                          if (value in values) {
                            duplicatesExist=true;
                          }
                          values[value] = true;
                      }
                    if(!duplicatesExist){
                      this.userlist.splice(i, 1, this.restrictionRoles);
                    } else {
                      this.alertservice.clearAll().error(this.resourceService.get('msg_um_DuplicateValueRestrcton'));
                    }
                    this.modifiedUserList = this.getUserlistModified();
                      }
                    }
                    this.checkCountry = this.userlist[this.userlist.length - 1].countryCode;
                    this.checkRestriction = this.userlist[this.userlist.length - 1].restriction;
                  }
                }
              });
              if (!verify) {
                // let countryRole = this.getRoleForCountry(this.restrictionRoles.countryId);
                // if(this.userManagementService.appCode === 1 || this.userManagementService.appCode === 4){
                // if (countryRole === null || countryRole === undefined) {
                //   countryRole = new UserCountryRole();
                //   countryRole.countryId = this.restrictionRoles.countryId;
                //   countryRole.countryCode = this.restrictionRoles.cntrycode;
                //   countryRole.allGroups = this.allRoleGroups.slice(0);
                //   countryRole.allRoles = [];
                //   countryRole.showRoles = false;
                //   countryRole.expirationDate = null;
                //   this.userCountryRoleList.push(countryRole);
                //   this.userCountryRoleListForTemplate = this.getUserCountryRoleList();
                // }}
                // if(this.userManagementService.appCode === 2){
                // if (countryRole === null || countryRole === undefined) {
                //     this.userCountryRoleListForTemplate=this.addRestrictionforGVTUser(this.getUserCountryRoleList(),this.restrictionRoles.Value)
                // }}
                if (this.restrictionRoles.Value === '*') {
                  this.restrictionRoles.Value = 'No Restriction';
                  this.userlist.push(this.restrictionRoles);
                  this.modifiedUserList = this.getUserlistModified();
                  this.restrictionRoles = {};
                  this.user.Value = '';
                  this.user.restriction = undefined;
                  this.user.dshbModuleId = undefined;
                  this.checkCountry = this.userlist[this.userlist.length - 1].countryCode;
                  this.checkRestriction = this.userlist[this.userlist.length - 1].restriction;
                  this.checkvalue = this.userlist[this.userlist.length - 1].Value;
                  //this.enableDshbRestriction = false;
                } else if (this.restrictionRoles && this.restrictionRoles.Value 
                  && this.restrictionRoles.Value === 'No Restriction'){
                  this.user.Value = '';
                  this.userlist.push(this.restrictionRoles);
                  this.modifiedUserList = this.getUserlistModified();
                  this.restrictionRoles = {};
                } else {
                   // [LFWM-2173][User management should validate duplicate values for storer/facility/seller][to validate when user enters comma separated values]
                    var role = [];
                    if (this.restrictionRoles) {
                      if(this.restrictionRoles.Value && 
                        this.restrictionRoles.Value.includes(',')){
                        role=this.restrictionRoles.Value.split(',');
                      
                      } else {
                       this.restrictionRoles.Value = this.user.Value;
                      }
                    }
                    var values = Object.create(null);
                    var duplicatesExist=false;
                    for (let i = 0; i < role.length; ++i) {
                        var value = role[i];
                        if (value in values) {
                          duplicatesExist=true;
                        }
                        values[value] = true;
                    }
                  if(!duplicatesExist){
                    this.userlist.push(this.restrictionRoles);
                  }
                  else{
                    this.alertservice.clearAll().error(this.resourceService.get('msg_um_DuplicateValueRestrcton'));
                  }
                  this.modifiedUserList = this.getUserlistModified();
                  this.checkCountry = this.userlist[this.userlist.length - 1].countryCode;
                  this.checkRestriction = this.userlist[this.userlist.length - 1].restriction;
                  this.checkvalue = this.userlist[this.userlist.length - 1].Value;
                  this.enableDshbRestriction = true;
                }
              }
            } else {
              this.alertservice.clearAll().error(this.resourceService.get('msg_um_EntrVlidModule'));
            }
          } else {
            this.alertservice.clearAll().error(this.resourceService.get('msg_um_EntrVlidRestrcton'));
          }
        } else {
          this.alertservice.clearAll().error(this.resourceService.get('msg_um_AddRestrForCountry'));
        }
      }
    } else {
      this.alertservice.clearAll().error(this.resourceService.get('msg_um_slctCntryCode'));
    }
    this.showtable = true;
  }
  /*** LFWM-2242 LF SCE Dashboard - User Management
   * Country Role list for dashboard app
   */
  public getCountryRoleListForDshb(restrictions: any, value: any) {
    let verify = false;
    if (value === '*') {
      restrictions.Value = 'No Restriction';
    }
    this.userCountryRoleList.forEach(element => {
      if ((element.countryId === restrictions.countryId) && (element.restrictionType === restrictions.restriction)
      && (element.dshbModuleCode === restrictions.dshbModuleCode)) {
        verify = true;
        if (value === 'No Restriction' || value === '*') {
          element.restrictionVal = restrictions.Value;
        } else {
          let splittedArray=[];
          let currentArray = [];
          if (element.restrictionVal.includes(',')) {
            element.restrictionVal.split(',').forEach(userdata => {
              splittedArray.push(userdata);
            });
          } else {
            splittedArray.push(element.restrictionVal);
          }
          if (value.includes(',')) {
            value.split(',').forEach(currentvalue => {
              currentArray.push(currentvalue);
            });
          } else {
            currentArray.push(value);
          }
          let isPresent = false;
          let isNoRestriction = false;
          currentArray.forEach(data => {
            splittedArray.forEach(splitData => {
              if (data === splitData) {
                isPresent = true;
              }
              if(splitData === 'No Restriction'){
                isPresent = true;
                isNoRestriction = true;
              }
            })
          })
          if (isPresent === false) {
            element.restrictionVal += ',' + value;
              //this.restrictionRoles.Value += ',' + value;
            this.restrictionRoles.Value = element.restrictionVal;
          } else if(isNoRestriction){
            element.restrictionVal = value;
            this.restrictionRoles.Value = value ==='*' ? 'No Restriction': value;
          }else {
            this.alertservice.clearAll().error(this.resourceService.get('msg_um_DuplicateValueRestrcton'));
          }
        }
      }
    });
    // if(this.userManagementService.appCode===4){
    //   verify = true;
    // }
    if (!verify) {
      const countryRole = new UserCountryRole();
        countryRole.countryId = this.restrictionRoles.countryId;
        countryRole.countryCode = this.restrictionRoles.cntrycode;
        countryRole.allGroups = this.allRoleGroups.slice(0);
        countryRole.allRoles = [];
        countryRole.showRoles = false;
        countryRole.expirationDate = null;
        countryRole.dshbModuleCode = restrictions.dshbModuleCode;
        countryRole.dshbModuleId = restrictions.dshbModuleId;
        countryRole.restrictionVal = value ==='*' ? 'No Restriction': value;
        countryRole.restrictionType = restrictions.restriction;
        this.userCountryRoleList.push(countryRole);
    }
  }
  // method used to validate the data before the submission
  public validateData(userData) {
    const countryList: any[] = [];
    let facilitycheck: boolean = false;
    let storercheck: boolean = false;
    let sellercheck:boolean = false;
    let clientcheck: boolean = false;
    let btpcheck : boolean = false;
    let stpcheck:boolean = false;
    let storerkeycheck: boolean = false;
    let vendorcheck: boolean = false;
    let applicationcheck: boolean = false;
    let forwardercheck: boolean = false;
    let carriercheck:boolean = false;
    let restrictionvalid: boolean = true;
    let tenantcheck = false;
    let prinicipalcheck = false;
    let locidcheck = false;
    let invalidExpirationDates = [];
    if((userData.phone !== null && userData.phone !== "" && userData.phone !== undefined)){
      if(this.phoneRregexValidation(userData.phone)){
          return true
      }else{
        this.alertservice.clearAll().error(this.resourceService.get('Invalid phone number'));
        return false;
      }
  }
    if (userData.userName !== '' && userData.userName !== undefined) {
      if ((userData.userType === 'Internal' || userData.userType === 0 )||
          ((userData.userType === 'External'|| userData.userType === 1 )  &&
          (userData.emailId !== null ||userData.emailId !== '' || userData.emailId !== undefined)) ||
          ((userData.userType === 'Recipient'|| userData.userType === 2 )  &&
          (userData.emailId !== null ||userData.emailId !== '' || userData.emailId !== undefined))
         ) {
        if (this.emailRregexValidation(userData.emailId, userData.userType)) {
          if (this.userlist !== [] && this.userlist !== undefined && this.userlist.length > 0) {
            this.userlist.forEach((element: any) => {
              if (countryList.indexOf(element.cntrycode) === -1) {
                countryList.push(element.cntrycode);
              }
            });
            for (let i = 0; i < countryList.length; i++) {
              facilitycheck = false;
              storercheck = false;
              clientcheck = false;
              vendorcheck = false;
              forwardercheck = false;
              stpcheck = false;
              btpcheck = false;
              tenantcheck = false;
              prinicipalcheck = false;
              locidcheck = false;
              sellercheck = false;
              carriercheck = false;
              for (let j = 0; j < this.userlist.length; j++) {
                if (this.userlist[j].cntrycode === countryList[i]) {
                  if (this.userlist[j].restriction === 'Facility') {
                    facilitycheck = true;
                  }
                  if (this.userlist[j].restriction === 'Storer') {
                    storercheck = true;
                  }
                  if (this.userlist[j].restriction === 'Seller') {
                    sellercheck = true;
                  }
                  if (this.userlist[j].restriction === 'Client') {
                    clientcheck = true;
                  }
                  if (this.userlist[j].restriction === 'Bill To Party') {
                    btpcheck = true;
                  }
                  if (this.userlist[j].restriction === 'Ship To Party') {
                    stpcheck = true;
                  }
                  if (this.userlist[j].restriction === 'Vendor') {
                    vendorcheck = true;
                  }
                  if (this.userlist[j].restriction === 'StorerKey') {
                    storerkeycheck = true;
                  }
                  if (this.userlist[j].restriction === 'Forwarder') {
                    forwardercheck = true;
                  }
                  if (this.userlist[j].restriction === 'Application') {
                    applicationcheck = true;
                  }
                  if (this.userlist[j].restriction === 'Tenant ID') {
                    tenantcheck = true;
                  }
                  if (this.userlist[j].restriction === 'Principal') {
                    prinicipalcheck = true;
                  }
                  if (this.userlist[j].restriction === 'Location ID') {
                    locidcheck = true;
                  }
                  if (this.userlist[j].restriction === 'Carrier') {
                    carriercheck = true;
                  }
                }
              }
              if (facilitycheck === true || storercheck === true || clientcheck === true 
                || vendorcheck === true || forwardercheck === true || storerkeycheck === true
                || applicationcheck === true || tenantcheck === true || carriercheck === true
                || prinicipalcheck === true || locidcheck === true || sellercheck === true || btpcheck === true || stpcheck === true) {
                this.alertMsg = '';
              } else {
                restrictionvalid = false;
                break;
              }
            }
            if (restrictionvalid) {
              const userListCntryCode = [];
              const rolesMngntCntryCode = [];
              const validatelist = [];
              this.userlist.forEach((element: any) => {
                if (userListCntryCode.indexOf(element.cntrycode) === -1) {
                  userListCntryCode.push(element.cntrycode);
                }
              });
              this.userCountryRoleList.forEach((element: any) => {
                if (rolesMngntCntryCode.indexOf(element.countryCode) === -1) {
                  rolesMngntCntryCode.push(element.countryCode);
                }
                // loggedin user can able to edit the country group, role, expiration date of enabled countries 
                if (element.expirationDate && this.userService.userInfo.enabledCountries.includes(element.countryId)) {
                  // changing format of expirationDate to default format to compare
                  const expirationDate = new Date(element.expirationDate);
                  const toDay = new Date(); // minimum date - today's date [Ex: Thu Oct 22 2020 13:01:08 GMT+0530 (India Standard Time)]
                  const maxExpirationDate = new Date();
                  /* time is added after changing the expiration date to default format of Date object. 
                  so time should be current time for comparision  */
                  expirationDate.setHours(toDay.getHours(), toDay.getMinutes(), toDay.getSeconds(), toDay.getMilliseconds());
                  // maximum date - next year current date - 1 [Ex: Thu Oct 21 2021 13:01:08 GMT+0530 (India Standard Time)]
                  maxExpirationDate.setFullYear(toDay.getFullYear() + 1, toDay.getMonth(), toDay.getDate() - 1);
                  if (expirationDate < toDay || expirationDate > maxExpirationDate) {
                    invalidExpirationDates.push(element.expirationDate);
                  }
                }
              });
              if (userListCntryCode.length === rolesMngntCntryCode.length) {
                userListCntryCode.forEach((element: any) => {
                  if (rolesMngntCntryCode.indexOf(element) === -1) {
                    validatelist.push(element);
                  }
                });
                if (validatelist.length === 0) {
                  if((userData.userType === 'External' || userData.userType === 'Recipient') && invalidExpirationDates.length >0) {
                    this.alertservice.clearAll().error(this.resourceService.get('msg_um_inv_exp_date'));
                    return false;
                  } else {
                    return true;
                  }
                } else {
                  this.alertservice.clearAll().error(this.resourceService.get('msg_um_RmvRoleErrMsg'));
                  return false;
                }
              } else {
                this.alertservice.clearAll().error(this.resourceService.get('msg_um_RmvRoleErrMsg'));
                return false;
              }
            }  else if(restrictionvalid === false && clientcheck===false){
              this.alertservice.clearAll().error(this.resourceService.get('Please enter mandatory Client Restriction'));
              return false;
            }else {
              if(this.userManagementService.appCode != 8){
                this.alertservice.clearAll().error(this.resourceService.get('msg_um_AddStrAndFac'));
                return false;
              }else{
                return true;
              }
            }
          } else {
            this.alertservice.clearAll().error(this.resourceService.get('msg_um_UsrRestrnErr'));
            return false;
          }
        } else {
          this.alertservice.clearAll().error(this.resourceService.get('msg_um_EmailIdErr'));
          return false;
        }
      } else {
        this.alertservice.clearAll().error(this.resourceService.get('msg_um_MandtryEmailID'));
        return false;
      }
    } else {
      this.alertservice.clearAll().error(this.resourceService.get('msg_um_MandtryUsrnme'));
      return false;
    }
  }


    // method used to validate the phone
    public phoneRregexValidation(phone: any): boolean {
      // const pattern = new RegExp(/^\+(?:[0-9]{1,3})?\s*\d*$/);
      const pattern = new RegExp(/^\+(?:[0-9]{1,3})?(\s?[0-9]){6,14}$/)
       return (pattern.test(phone));
   }
  // method used to validate the username pattern
  public usernamRregexValidation(name: any, usertype: any): boolean {
    if (usertype === 'Internal') {
      return true;
    } else {
      const pattern = new RegExp('^[A-z]+$');
      return (pattern.test(name));
    }
  }
  // method used to validate the email
  public emailRregexValidation(email: any, usertype: any): boolean {
    if (usertype === 'Internal' || usertype === 0) {
      return true;
    } else {
      const pattern = new RegExp(/^[a-zA-Z0-9!#$%&'*+-/=?^_`{|}.-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/);
      return (pattern.test(email));
    }
  }
  // method onclick of SAVE button
  public submit(userData: any) {
    if (this.validateData(userData)) {
      console.log("after 1st validation");
      // populate user restrictions
      const countryLst = [];
      const countryRestrictionMap = {};
      const countryrestrictionList = [];
      /*** LFWM-2242 LF SCE Dashboard - User Management
       * Req formation for Dashboard
       */
      if (this.userManagementService.appCode === 4) {

        this.userlist.forEach((userrestriction: any) => {
          if (countryLst.indexOf(userrestriction.countryId) === null || countryLst.indexOf(userrestriction.countryId) === -1) {
            countryLst.push(userrestriction.countryId);
          }
          const locresLst = countryRestrictionMap[userrestriction.countryId];
          if (locresLst) {
            locresLst.push({ 'typeId': userrestriction.id, 'value': userrestriction.Value, 'moduleCode': this.user.dshbModuleId });
          } else {
            const userrestlst = [];
            userrestlst.push({ 'typeId': userrestriction.id, 'value': userrestriction.Value, 'moduleCode': this.user.dshbModuleId });
            countryRestrictionMap[userrestriction.countryId] = userrestlst;
          }
        });
        countryLst.forEach((countryId: any) => {
          const country = this.getCountryForId(countryId);
          countryrestrictionList.push({
            'countryId': countryId,
            'restrictions': countryRestrictionMap[countryId]
          });
        });
      } else {

        this.userlist.forEach((userrestriction: any) => {
          if (countryLst.indexOf(userrestriction.countryId) === null || countryLst.indexOf(userrestriction.countryId) === -1) {
            countryLst.push(userrestriction.countryId);
          }
          const locresLst = countryRestrictionMap[userrestriction.countryId];
          if (locresLst) {
            locresLst.push({ 'typeId': userrestriction.id, 'value': userrestriction.Value,'moduleCode': this.user.dshbModuleId });
          } else {
            const userrestlst = [];
            userrestlst.push({ 'typeId': userrestriction.id, 'value': userrestriction.Value,'moduleCode': this.user.dshbModuleId });
            countryRestrictionMap[userrestriction.countryId] = userrestlst;
          }
        });
        countryLst.forEach((countryId: any) => {
          countryrestrictionList.push({
            'countryId': countryId,
            'restrictions': countryRestrictionMap[countryId]
          });
        });
      }

      // populate user roles
      const countryRolesMap = {};
      const userRoleList = [];
      if(this.userManagementService.appCode === 1 || this.userManagementService.appCode === 5 ||
        this.userManagementService.appCode === 6 || this.userManagementService.appCode === 8 || this.userManagementService.appCode === 9){
      this.userCountryRoleList.forEach((userCountryRole: any) => {
        userCountryRole.allRoles.forEach((role: any) => {
          if (userCountryRole.selectedRoleIdsForDisplay.indexOf(role.id) !== -1) {
              userRoleList.push({
                'countryId': userCountryRole.countryId,
                'roleId': role.id,
                'groupId': role.grpId,
                'expirationDate': userCountryRole.expirationDate
              });
            }
        });
      });}
      else if(this.userManagementService.appCode === 2) {
        let resArr=[];
        countryrestrictionList.forEach(restr => {
          restr.restrictions.forEach(element => {
              element.value.split(',').forEach(value => {
                resArr.push(value);
              });
          });
        });
        this.userCountryRoleListForTemplate.forEach((userCountryRole: any) => {
          userCountryRole.allRoles.forEach((role: any) => {
            if (userCountryRole.selectedRoleIdsForDisplay.indexOf(role.id) !== -1) {
              userRoleList.push({
                'countryId': userCountryRole.countryId,
                'roleId': role.id,
                'groupId': role.grpId,
                'expirationDate': userCountryRole.expirationDate,
                'restrictionVal': resArr.join(',')
              });
            }
          });
        }); 
      } else if (this.userManagementService.appCode === 4) { /*** LFWM-2242 LF SCE Dashboard - User Management */
        this.userCountryRoleListForTemplate.forEach((userCountryRole: any) => {
          userCountryRole.allRoles.forEach((role: any) => {
            if (userCountryRole.selectedRoleIdsForDisplay.indexOf(role.id) !== -1) {
              userRoleList.push({
                'countryId': userCountryRole.countryId,
                'roleId': role.id,
                'groupId': role.grpId,
                'expirationDate': userCountryRole.expirationDate,
                'restrictionVal':  userCountryRole.restrictionVal,
                'moduleCode': userCountryRole.dshbModuleId?userCountryRole.dshbModuleId:''
              });
            }
          });
        });
      }
      else {
        this.userCountryRoleListForTemplate.forEach((userCountryRole: any) => {
          userCountryRole.allRoles.forEach((role: any) => {
            if (userCountryRole.selectedRoleIdsForDisplay.indexOf(role.id) !== -1) {
              userRoleList.push({
                'countryId': userCountryRole.countryId,
                'roleId': role.id,
                'groupId': role.grpId,
                'expirationDate': userCountryRole.expirationDate,
                'restrictionVal':userCountryRole.restrictionVal
              });         
            }
          });
        });  
      }
      let usrType = 0;
      if (userData.userType === 'External') {
        usrType = 1;
        userData.displayName = userData.userName;
      }

       if (userData.userType === 'Recipient') {
        usrType = 2;
        userData.displayName = userData.userName;
      }

      let userId = null;
      if (this.isEditFlow) {
        userId = userData.userId;
      }
      // JIRA LFWM-718 fixes
      if (userData.userType === 'Internal') {
        userData.displayName = this.user.displayName;
        if(userData.displayName === '' ||userData.displayName === undefined){
          this.alertservice.clearAll().error(this.resourceService.get('msg_um_InvalidUser'));
          return false;
        }
      }
      this.userDetail = {
        userId: userId,
        userName: userData.userName,
        displayName: userData.displayName,
        emailId: userData.emailId,
        userType: usrType,
        ldapDomain: userData.ldapDomain,
        applicationId: 1,
        userRestrictions: countryrestrictionList,
        userRoles: userRoleList,
        bu: userData.bu,
        remark: userData.remark,
        company: userData.company,
        phone: userData.phone,
        whatsapp: userData.whatsapp,
        wechat: userData.wechat,
        line: userData.line,
        telegram: userData.telegram,
      };
      this.validateData(this.userDetail);
      if (this.isEditFlow) {
        console.log("inside submit save API Call");
        this.userManagementService.updateUser(this.userDetail, this.user.dshbModuleId).subscribe((resp) => {
          this.subscribeUser(resp);
          if(resp && resp.statusCode !== 500 &&
            this.userManagementService.appCode === 1){
                this.submitForDashboard(userData);
            }
        }, (error) => {
          this.alertservice.clearAll().error(this.resourceService.translateServerResponse(error));
        });
      } else {
        this.userManagementService.addUser(this.userDetail).subscribe((resp) => {
          this.subscribeUser(resp);
        }, (error) => {
          this.alertservice.clearAll().error(this.resourceService.translateServerResponse(error));
        });
      }
    }
  }
  public submitForDashboard(userDataForDash: any) {
    if (this.validateData(userDataForDash)) {
      // populate user restrictions
      const countryLst = [];
      const countryRestrictionMap = {};
      const countryrestrictionList = [];
      /*** LFWM-2242 LF SCE Dashboard - User Management
       * Req formation for Dashboard
       */
        this.userlist.forEach((userrestriction: any) => {
          if (countryLst.indexOf(userrestriction.countryId) === null || countryLst.indexOf(userrestriction.countryId) === -1) {
            countryLst.push(userrestriction.countryId);
          }
          const locresLst = countryRestrictionMap[userrestriction.countryId];
          if (locresLst) {
            locresLst.push({ 'typeId': userrestriction.id, 'value': userrestriction.Value, 'moduleCode': 'OD' });
          } else {
            const userrestlst = [];
            userrestlst.push({ 'typeId': userrestriction.id, 'value': userrestriction.Value, 'moduleCode': 'OD' });
            countryRestrictionMap[userrestriction.countryId] = userrestlst;
          }
        });
        countryLst.forEach((countryId: any) => {
          const country = this.getCountryForId(countryId);
          countryrestrictionList.push({
            'countryId': countryId,
            'restrictions': countryRestrictionMap[countryId]
          });
        });

      // populate user roles
      const countryRolesMap = {};
      const userRoleList = []; /*** LFWM-2242 LF SCE Dashboard - User Management */
        this.userCountryRoleListForTemplate.forEach((userCountryRole: any) => {
          userCountryRole.allRoles.forEach((role: any) => {
            if (userCountryRole.selectedRoleIdsForDisplay.indexOf(role.id) !== -1) {
              userRoleList.push({
                'countryId': userCountryRole.countryId,
                'roleId': role.id,
                'groupId': role.grpId,
                'expirationDate': userCountryRole.expirationDate,
                'restrictionVal':  userCountryRole.restrictionVal,
                'moduleCode': 'OD'
              });
            }
          });
        });
      let usrType = 0;
      if (userDataForDash.userType === 'External') {
        usrType = 1;
        userDataForDash.displayName = userDataForDash.userName;
      }
      if (userDataForDash.userType === 'Recipient') {
        usrType = 2;
        userDataForDash.displayName = userDataForDash.userName;
      }
      let userId = null;
      if (this.isEditFlow) {
        userId = userDataForDash.userId;
      }
      // JIRA LFWM-718 fixes
      if (userDataForDash.userType === 'Internal') {
        userDataForDash.displayName = this.user.displayName;
        if(userDataForDash.displayName === '' ||userDataForDash.displayName === undefined){
          this.alertservice.clearAll().error(this.resourceService.get('msg_um_InvalidUser'));
          return false;
        }
      }
      this.userDetail = {
        userId: userId,
        userName: userDataForDash.userName,
        displayName: userDataForDash.displayName,
        emailId: userDataForDash.emailId,
        userType: usrType,
        ldapDomain: userDataForDash.ldapDomain,
        applicationId: 4,
        userRestrictions: countryrestrictionList,
        userRoles: userRoleList,
        bu: userDataForDash.bu,
        remark: userDataForDash.remark,
        company: userDataForDash.company,
        phone: userDataForDash.phone,
        whatsapp: userDataForDash.whatsapp,
        wechat: userDataForDash.wechat,
        line: userDataForDash.line,
        telegram: userDataForDash.telegram,
      };
      this.validateData(this.userDetail);
      if (this.isEditFlow) {
        this.userManagementService.updateUser(this.userDetail, 'OD').subscribe((resp) => {
          this.subscribeUser(resp);
        }, (error) => {
          //this.alertservice.clearAll().error(this.resourceService.translateServerResponse(error));
        });
      } else {
        this.userManagementService.addUser(this.userDetail).subscribe((resp) => {
          this.subscribeUser(resp);
        }, (error) => {
          //this.alertservice.clearAll().error(this.resourceService.translateServerResponse(error));
        });
      }
    }
  }
  public subscribeUser(resp) {
    this.succ = resp;
    if (this.succ.statusCode === 200) {
      this.alertMsg = this.succ.statusMessage; // + " " + this.userDetail.userName;
      this.alertservice.clearAll().success(this.resourceService.translateServerResponse(this.alertMsg));
      this.isEditFlow = true;
      this.disableall = true;
      let application = '';
      this.disableIcon = this.userManagementService.icondisable;
      this.user.userId = this.succ.userId;
      this.isUserNameDisable = true; // fix for username disable after add functionality
      this.isUserTypeDisable = true;
      /*
       * LFWM-662 code to update the user restriction for current country once it is changed---starts
       */
      let facility = '';
      let storer = '';
      const currentCountryId = this.userDetail.userRestrictions.filter((restriction) => {
        return restriction.countryId === this.userService.userInfo.userRestrictions[0]['countryId'];
      });
      if (currentCountryId.length > 0 && this.userDetail.emailId === this.userService.userInfo.emailAddress) {
        currentCountryId[0].restrictions.forEach(eachrestriction => {
          if (eachrestriction.typeId === 1) {
            facility = eachrestriction.value === 'No Restriction' ? null : eachrestriction.value;
          } else if (eachrestriction.typeId === 2) {
            storer = eachrestriction.value === 'No Restriction' ? null : eachrestriction.value;
          } else if (eachrestriction.typeId === 3 || this.userManagementService.appCode === 7) {
            application = eachrestriction.value === 'No Restriction' ? null : eachrestriction.value;
          }
        });
        this.userService.userInfo.userRestrictions['0']['restrictions'].forEach(restrictionservice => {
          if (restrictionservice.restrictionTypeId === 1) {
            restrictionservice.restrictionValue = facility;
          } else if (restrictionservice.restrictionTypeId === 2) {
            restrictionservice.restrictionValue = storer;
          }
        });
      }

      /*
       * LFWM-662 code to update the user restriction for current country once it is changed----ends
       */

    } else if (this.succ.statusCode === 500) {
      this.alertMsg = this.succ.statusMessage;
      this.alertservice.clearAll().error(this.resourceService.translateServerResponse(this.alertMsg));
    } else {
      if (this.succ.errors && this.succ.errors.length > 0) {
        this.alertservice.clearAll().error(this.resourceService.get(this.succ.errors[0].validationMessage));
      } else {
        this.alertservice.clearAll().error(this.resourceService.get('msg_um_usrAbsntInLDAP'));
      }
    }
  }

  // method used to remove the restricton
  public deleterestriction(i: any) {
   let count = 0;
    
    this.modifiedUserList.forEach(element => {
      if (element.countryId === this.modifiedUserList[i].countryId) {
        count ++;
      }
    });

    let rolesMessage = '';
    if (count === 1) {
      const message = this.modifiedUserList[i].cntrycode + ' ' + this.modifiedUserList[i].restriction + ' (' +  this.modifiedUserList[i].Value + ') ';
       rolesMessage =   'Youâre about to delete the ' + message + 'restriction, all related country / region  roles management will be delete.' +
      'Once you conï¬rm and press Yes, it cannot be';
    } else {
      // this.modifiedUserList = this.modifiedUserList.filter(a => a.countryId === this.modifiedUserList[i].countryId);
      const message = this.modifiedUserList[i].cntrycode + ' ' + this.modifiedUserList[i].restriction + ' (' +  this.modifiedUserList[i].Value + ') ';
      rolesMessage = 'Youâre about to delete the' + message + 'Press yes to conï¬rm.';
    }

    this.confirmDialogService.confirmDialog(this.resourceService.get(rolesMessage))
      .subscribe((isConfirmed: any) => {
        if (isConfirmed) {
          if (count === 1) {
          
            this.userCountryRoleListForTemplate = this.userCountryRoleListForTemplate.filter(a => a.countryCode !== this.modifiedUserList[i].cntrycode);
            this.userCountryRoleList = this.userCountryRoleListForTemplate;
          }
          const retVal: boolean = true;
          //this.alertservice.clearAll().warn(this.resourceService.get('msg_um_RstrnErrMsg'));
          if (retVal) {
           this.userlist.splice(i, 1);
           this.modifiedUserList = this.userlist;
          }
        } else {
          return;
        }
      });

  }
  // method onclick of CANCEl button navigate to userlisting screen
  public cancel() {
    this.navService.navigateByMenuId('manage-user', true);
  }
  // method used to open the lookup for suggestions
  public callModal(name: any) {
    if (this.disableall) {
      if (name !== undefined) {
        if (name.length >= 4) {
          this.alertservice.clearAll();
          this.userManagementService.search_value = name;
          this.userManagementService.sendsearch(name);
          const modalRef: any = this.modalService
            .showModal(UserLookupComponent,
              {
                overlayHeader: this.resourceService.get('lbl_um_CreateNewUser'),
                showHeader: true,
                overlayHeight: 520,
                overlayWidth: 1024
              });
        } else {
          this.alertservice.clearAll().warn(this.resourceService.get('msg_um_enter4Char'));
        }
      } else {
        this.alertservice.clearAll().warn(this.resourceService.get('msg_um_enter4Char'));
      }
    }
  }
  public selectModule() {
    this.user.Value = '';
    const moduleRestriction = this.getModuleRestriction(this.user.dshbModuleId);
    if (this.user.dshbModuleId === 'OD' || this.user.dshbModuleId === 'FD' || this.user.dshbModuleId === 'BPD') {
      this.userCountryRoleList = [];
    }
    this.handleAppCodeChange(this.user.applicationCode,this.user.dshbModuleId);
    setTimeout(() => {
      this.enableDshbRestriction = true;   
    },1000)
  }
  //method used to handle application code change
  public handleAppCodeChange(applicationCode: any, moduleId?: any) {
    if (!moduleId) {
      this.user.countryCode = undefined; 
      this.user.dshbModuleId = undefined;
    }
    this.user.countryCode = undefined; 
    this.userManagementService.appCode=Number(this.user.applicationCode);
    this.enableDshbRestriction = false;
    this.selectedcode = this.userManagementService.appCode;
    this.restriction = [];
    this.userlist = [];
    this.filteredCountries = [];
    this.userCountryRoleList = [];
	this.modifiedUserList = [];
	this.restrictionRoles = {};
    if(this.userManagementService.appCode == 1){
      this.loadData(this.user.userId, this.user.userType, true, moduleId);
      // this.filteredCountries = this.coreFilteredCountries.filter((item:any) =>{ 
      //   return (item.code !== 'GBL')});
      this.restriction = this.restriction.filter((item:any) =>{
        if((item.value !== 'Client' && item.value !== 'Vendor' && item.value !== 'Forwarder')){
          return item;
        }
       });
      //  this.userManagementService.getUser(this.user.userId, this.user.userType, this.appCode)
    } else if (this.userManagementService.appCode === 4) { /*** LFWM-2242 LF SCE Dashboard - User Management */
      // this.user.dshbModuleId = undefined;
      /* Dashboard Modules are hardcoded in UI */
      this.dashboardModules = [{id: 'FD', code: 'Forwarder Dashboard'},
      {id: 'OD', code: 'Operation Dashboard'},
      {id: 'BPD', code: 'Booking Performance Dashboard'},
      {id: 'TD' , code : 'TMS Dashboard'}];
      this.loadData(this.user.userId, this.user.userType, true, moduleId);
    } else if(this.userManagementService.appCode == 2){
      this.loadData(this.user.userId, this.user.userType, true, moduleId);
      this.restriction = this.restriction.filter((item:any) =>{
        if((item.value === 'Client')){
          return item;
        }
       });
    //   const getGvtResCountries = this.userManagementService.getRestrictedCountries(this.appCode);
    //   const getGvtCountries = this.userManagementService.getAllCountries();
    //   Observable.forkJoin([getGvtCountries, getGvtResCountries]).subscribe((gvtResults) => {
    //     const getGvtAllCountries = gvtResults[0];
    //     const gvtRestrictCountry = gvtResults[1];   
    //     getGvtAllCountries.forEach(element => {
    //       gvtRestrictCountry.forEach(countryId => {
    //         if (countryId === element.countryId) {
    //           element.enabledCntry = true;
    //         }
    //       });
    //     });
    //     this.gvtCountries = gvtResults[0].sort((country1, country2) => {
    //       if (country1.code > country2.code) {
    //         return 1;
    //       }
    //       if (country1.code < country2.code) {
    //         return -1;
    //       }
    //       return 0;
    //     });
    //     this.filteredCountries = this.getGvtFilteredCountries().filter((item:any) =>{ 
    //       return (item.code === 'GBL')});
    //     });
    // this.restriction = this.res.filter((item:any) =>{ 
    //       return (item.value === 'Client')
    //   });
    //   console.log("***User Counttry Code inisde handle *********", this.user.countryCode);
    //   this.user.countryCode = this.user.countryCode;
    //   console.log("***modifiedUserList***",this.modifiedUserList);  
    } else if(this.userManagementService.appCode == 5){
      this.loadData(this.user.userId, this.user.userType, true, moduleId);
      // this.filteredCountries = this.coreFilteredCountries.filter((item:any) =>{ 
      //   return (item.code !== 'GBL')});
      this.restriction = this.restriction.filter((item:any) =>{
        if((item.value === 'Client')){
          return item;
        }
       });
      //  this.userManagementService.getUser(this.user.userId, this.user.userType, this.appCode)
    } else if(this.userManagementService.appCode == 6) {
      // POMS-3 LFSCE POMS user management changes
      this.loadData(this.user.userId, this.user.userType, true, moduleId);
      this.restriction = this.restriction.filter((item:any) =>{
         if((item.value === 'Client' || item.value === 'Vendor' || item.value === 'Forwarder')){
          return item;
        }
       });
    }else if(this.userManagementService.appCode == 8){
      this.loadData(this.user.userId, this.user.userType, true, moduleId);
      this.restriction = this.restriction.filter((item:any) =>{
        if(item.value === 'Tenant ID' || item.value === 'Country' || item.value === 'Location ID' || item.value === 'Principal'){
          return item;
        }
       });
    } else if(this.userManagementService.appCode == 9){
      this.loadData(this.user.userId, this.user.userType, true, moduleId);
      // this.filteredCountries = this.coreFilteredCountries.filter((item:any) =>{ 
      //   return (item.code !== 'GBL')});
      this.restriction = this.restriction.filter((item:any) =>{
        if((item.value === 'Storer')){
          return item;
        }
       });
      //  this.userManagementService.getUser(this.user.userId, this.user.userType, this.appCode)
    }
    if (this.userManagementService.appCode == 3 || this.userManagementService.appCode === 7) {
      this.loadData(this.user.userId, this.user.userType, true,moduleId);
      this.restriction = this.restriction.filter((item:any) =>{
        if((item.value === 'Application')){
          return item;
        }
       });
    }
  }
  public isfieldsEnable(usertype) {
    if (usertype === 'External' || usertype === 'Recipient') {
      this.isUserNameDisable = false;
      this.disableall = this.userManagementService.disablefield;
      this.user.userName = ''; // LFWM-2299
      if (this.user.emailId !== '') {
        this.user.userName = this.user.emailId; // LFWM-2299
      }
      this.isUserNameDisable = true; // LFWM-2299 Disabling username field so as to populate the username same as email id
      this.isUserTypeDisable = false;
    } else if (usertype === 'Internal') {
      this.user.userName = ''; // LFWM-2299
      this.user.emailId = ''; // LFWM-2299
      this.disableall = !this.userManagementService.disablefield;
      this.isUserNameDisable = false;
      this.isUserTypeDisable = false;
    }
  }
  public setRestriction(user) {
    this.user.restriction = user.restriction;
   }
   public validateApplications(value) {
     const appAccess = ['WMS', '*'];
     this.appAccessError = null;
     if (this.userService.userInfo.gvtAccess) {
       appAccess.push('GVT');
     }
     if (this.userService.userInfo.dashboardAccess) {
       appAccess.push('DSHB');
       appAccess.push('Dashboard');
     }
     if (this.userService.userInfo.dataLoaderAccess) {
       appAccess.push('DL');
       appAccess.push('Dataloader');
     }
     if (this.userService.userInfo.notificationAccess) {
      appAccess.push('NOTIFICATION');
      appAccess.push('SCE NOTIFICATION APP');
     }
     if (this.userService.userInfo.billAccess) {
       appAccess.push('BILLING');
       appAccess.push('Billing System');
     }
     if (this.userService.userInfo.pomsAccess) {
      appAccess.push('POMS');
      appAccess.push('POMS');
     }
     if (this.userService.userInfo.omsAccess) {
      appAccess.push('OMS');
      appAccess.push('OMS');
     }
     let values = '';
       value.split(',').forEach(element => {
         if (appAccess.includes(element.toUpperCase()) === false) {
           if (values === '') {
             values = 'No access to ' + element;
           } else {
             values = values + ', ' + element;
           }
         }
       }); 
       if (values !== '') {
         this.appAccessError = values;
       }
   }
  // LFWM-2299 Disabling username field so as to populate the username same as email id
  public populateEmailId(userType: string) {
    if (userType === 'External' || userType === 'Recipient') {
      this.user.userName = this.user.emailId;
    }
   }
   public resetPassword(userId){
    this.confirmDialogService.confirmDialog(this.resourceService.get(this.resourceService.get('lbl_um_AreYouSureToResetPassword')))
    .subscribe((isConfirmed: any) => {
      if (isConfirmed) {
        this.userManagementService.resetPassword(userId).subscribe((resp) => {
         if(resp.statusCode === 200 && resp.statusMessage === 'SUCCESS'){
          this.alertservice.clearAll().success(this.resourceService.get('msg_um_ResetPasswordSuccess'));

         } else{
          this.alertservice.clearAll().error(this.resourceService.get('msg_um_ResetPasswordFailed'));

         }
        }, (error) => {
          return false;
        });

      } else {
        return false;
      }
    });
   }
   // LFWM-2755 RG| Deactivate Internal/external user
   public toggleUserStatus(username,checked){
      this.userManagementService.toggleUserStatus(username,checked).subscribe((resp) => {
      if(resp.statusCode === 200 && resp.statusMessage === 'SUCCESS'){
        const msg  = checked ? this.resourceService.get('msg_um_user_activate_success') : this.resourceService.get('msg_um_user_deactivate_success');
        this.alertservice.clearAll().success(msg);
        } else{
        this.user.active = !checked; // resetting to previous value
        const failedmsg  = checked ? this.resourceService.get('msg_um_user_activate_failed') : this.resourceService.get('msg_um_user_deactivate_failed');
        this.alertservice.clearAll().error(failedmsg);
        }
      }, (error) => {
        return false;
      });
   }

  handleMainButtonPanelActions($event) {
    if ($event === 'Save') {
      this.submit(this.user);
    } else if ($event === 'Close') {
      this.cancel();
    } else {
      this.resetPassword(this.user.userName);
    }
  }

  @ViewChild('floatingMenuId', { read: ElementRef }) navbarif: ElementRef;
  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    this.floatingMenuOffset = this.navbarif.nativeElement.offsetTop;
    if (this.previousScroll < window.pageYOffset) {
      this.isScrollDown = true;
    } else {
      this.isScrollDown = false;
    }
    this.previousScroll = window.pageYOffset;
    // floating menu is getting fixed when height is 40 px from top, that is why we are adding/subtracting 39 px
    // to the offset. So that there wont be any sudden jumps during scrolling.
    if ((window.pageYOffset + (this.isScrollDown ? 39 : -39)) >= (this.floatingMenuOffset)) {
      this.isStickyFloatingMenu = true;
    } else {
      this.isStickyFloatingMenu = false;
    }
  }


  protected getAllButtons(): any {
    this.buttonArray = [];
    if (this.enableResetPasswordButton === true ) {
      this.buttonArray.push(new ButtonItem('Reset Password', this.resourceService.get('Reset Password'), '', '', []));
      if (this.btnRestrictionEdit === true) {
        this.buttonArray.push(new ButtonItem('Save', this.resourceService.get('btn_save'), '', 'icon_save.svg', []));
      }
      this.buttonArray.push(new ButtonItem('Close', this.resourceService.get('btn_close'), '', 'button-close.svg', []));
    } else if (this.btnRestrictionEdit === true) {
      this.buttonArray.push(new ButtonItem('Save', this.resourceService.get('btn_save'), '', 'icon_save.svg', []));
      this.buttonArray.push(new ButtonItem('Close', this.resourceService.get('btn_close'), '', 'button-close.svg', []));
    } else {
      this.buttonArray.push(new ButtonItem('Close', this.resourceService.get('btn_close'), '', 'button-close.svg', []));
    }
  }
}
