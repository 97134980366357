import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserLookupComponent } from './user-lookup/user-lookup.component';
import { UserListComponent } from './user-list/user-list.component';
import { RoleManagementComponent } from './role-management/role-management.component';
import {  GroupManagementComponent } from './group-management/group-management.component';
import { FieldManagementComponent } from './field-management/field-management.component';
import { DowntimeConfigurationComponent } from './downtime-configuration/downtime-configuration.component';
import { DowntimeConfigurationDashboardComponent } from './downtime-configuration/downtime-configuration-dashboard/downtime-configuration-dashboard.component';
import { ScheduleDowntimePopupComponent } from './downtime-configuration/popup/schedule-downtime-popup.component';
import { MaintenanceNoticePopupComponent } from './downtime-configuration/popup/maintenance-notice-popup/maintenance-notice-popup.component';
import { DispatcherIframeComponent } from './components/dispatcher-iframe/dispatcher-iframe.component';
@NgModule({
  imports: [
    RouterModule.forChild([
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: AdminHomeComponent},
      { path: 'userlookup', component: UserLookupComponent },
      { path: 'usermanagement', component: UserManagementComponent },
      { path: 'userlist', component: UserListComponent },
      { path: 'rolemanagement', component: RoleManagementComponent},
      { path: 'groupmanagement', component: GroupManagementComponent },      
      { path: 'fieldmanagement', component:FieldManagementComponent },
      { path: 'downtime-configuration', component: DowntimeConfigurationComponent},
      { path: 'dashboard', component: DowntimeConfigurationDashboardComponent },
      { path: 'schedule-downtime-popup', component: ScheduleDowntimePopupComponent },
      { path: 'maintenance-notice-popup', component: MaintenanceNoticePopupComponent },
      { path: 'tms', component: DispatcherIframeComponent}
      
    ])
  ],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
