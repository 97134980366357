import { Injectable, Input } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { LoaderEnabled } from "./components/loader/loader.service";
import { sceconfig } from "./sce-config";
import { Subject } from "rxjs";
import {catchError, map} from 'rxjs/operators';


@Injectable()
export class DashboardService {
    public iframeUrl: any;
    @Input('showcrsl') showCarousel: boolean = true;
    public favouriteDashboardList: any[];
    public preferenceEventSubject: Subject<any>;
    constructor(public http: HttpClient) {
        this.preferenceEventSubject = new Subject<any>();
    }
    public getFavouriteDashboard(moduleId): Observable<any> {
        const hdr = new HttpHeaders({ 'api': 'admin' });
        const response$ = this.http.get('api/users/dashboard/favorites/' + moduleId, { headers: hdr }).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => {
                if (error.error.length !== 0) {
                    if (error.error.statusCode !== 200) {
                        return Observable.throw(error.error.statusMessage);
                    }
                }
            }));
        return response$;
    }
    public fetchDshbModules(): Observable<any> {
        const hdr = new HttpHeaders({ 'api': 'admin' });
        return this.http.get('api/users/dashboard/', { headers: hdr }).pipe(
            map((response: any) => {
                return response;
            }),catchError((error: any) => {
                return Observable.throw('Failed to fetch  Dashboard Modules ' + error.message);
            }));
    }
    public fetchSelectedIframeUrl(params): Observable<any> {
        const hdr = new HttpHeaders({ 'api': 'admin' });
        return this.http.post('api/users/dashboards/url', params, { headers: hdr }).pipe(
            map((response: any) => {
                return response;
            }),catchError((error: any) => {
                return Observable.throw('Failed to fetch KPI ');
            }));
    }
    public removeFavourites(unFav) {
        const req = { "accessModuleId": unFav.accessModuleId, "dashboardId": unFav.dashboardId, "moduleId": "" }
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'api': 'admin'
            }),
            body: req,
        };
        const response$ = this.http.delete('api/users/dashboard/favorites', options).pipe(
            map((response: any) => {
                console.log('respo', response)
                return response;
            }),
            catchError((error: any) => {
                if (error.error.length !== 0) {
                    if (error.error.statusCode !== 200) {
                        return Observable.throw(error.error.statusMessage);
                    }
                }
            }));
        return response$;
    }

    public receiveEvent(): Observable<any> {
        return this.preferenceEventSubject.asObservable();
    }

    public notifyEvent(event: any) {
        this.preferenceEventSubject.next(event);
    }
}

