import { Injectable } from '@angular/core';
import moment from 'moment';


@Injectable()
export class DatePickerConfigService {
    /* All date format variables are represented in momentjs formats
    unless explicitly specified.
    Only difference now is in primeng pCalendar date formats
    */
    public momentToPrimeNgDateFormatMapping: {} = {
        'YYYY-MM-DD': 'yy-mm-dd',
        'MM/DD/YYYY': 'mm/dd/yy',
        'DD-MMM-YYYY': 'dd-M-yy',
        'DD/MM/YYYY' : 'dd/mm/yy',
        'YYYY/MM/DD': 'yy/mm/dd',
        'MM-DD-YYYY': 'mm-dd-yy',
        'DD-MM-YYYY' : 'dd-mm-yy'
     };
    // Map <string, string> = new Map<string, string>();

    public defaultDateDisplayFormat: string = 'DD-MMM-YYYY';
    // default format[moment representation] used while displaying
    public defaultTimeDisplayFormat: string = 'hh:mm:ss A';
    // default format[moment representation] used while displaying

    public defaultDateInputFormat: string = 'YYYY-MM-DD';
    public defaultTimeInputFormat: string = 'HH:mm:ss.SSS';
    public defaultTimeSpanInputFormat: string = 'DD/MM/YYYY';
    public defaultTimeSpanDisplayFormat: string = 'hh:mm a';
    public defaultTimeZone: string;

    public getPrimeNgDateFormat(momentFormat) {
        const primeNgFormat = this.momentToPrimeNgDateFormatMapping[momentFormat];
        
        if (!primeNgFormat) {
            console.warn('No mapping for ' + momentFormat);
        }
        return primeNgFormat;
    }

    /* method to format date alone with optional formatspecifier
    public getFormattedDisplayDate(inputDateOnly: string, inputDateOnlyFormat?: string) {
        if (!inputDateOnlyFormat) {
            inputDateOnlyFormat = this.defaultDateInputFormat;
        }
        const formattedOutputDate: any = moment(
            inputDateOnly,
            inputDateOnlyFormat,
            true
          ).format(this.defaultDateDisplayFormat);
    }*/
    /* method to format date alone */
    public getFormattedDisplayDate(inputDateOnly: any) {
        return moment(
            inputDateOnly,
            this.defaultDateInputFormat,
            true
        ).format(this.defaultDateDisplayFormat);
    }

    /* method to format datetime with optional formatspecifier
    public getFormattedDisplayDateTime1(inputDateTime: string, inputDateTimeFormat?: string) {
        if (!inputDateTimeFormat) {
            inputDateTimeFormat = this.defaultDateInputFormat + ' ' + this.defaultTimeInputFormat;
        }
        const formattedOutputDate: any = moment(
            inputDateTime,
            inputDateTimeFormat,
            true
          ).format(this.defaultDateDisplayFormat + ' ' + this.defaultTimeDisplayFormat);

        return formattedOutputDate;
    } */

    /* method to format datetime */
    public getFormattedDisplayDateTime(inputDateTime: any) {
        let formatDate= moment(
            inputDateTime,
            this.defaultDateInputFormat + ' ' + this.defaultTimeInputFormat,
            true
        ).format(this.defaultDateDisplayFormat + ' ' + this.defaultTimeDisplayFormat);
        // fix for LFWM-2581 LFWM-2191 LFWM-2502
        // The datepicker lib has an issue in displaying  time from 12:00:00 AM to 12:59:59 AM 
        // This is a temperory fix add to display the time 12:00:00 AM to 12:59:59 AM as 00:00:00 AM to  00:59:59 AM 
        // Once user select any date then the value will change to 12 pm and if an above
		// mention range should be manually enter (key in )as 00:00:00 AM instead of selecting from date picker
        const  time  =  moment(inputDateTime).format("hh:mm:ss A");
        if(moment(inputDateTime).format("hh") === '12'&&
        moment(inputDateTime).format("A")=== 'AM'){
             let dateDisplayList=  formatDate.split(" ");
             if(dateDisplayList.length > 2){
                let date = dateDisplayList[0];
                let timeDisplayList = dateDisplayList[1].split(":");
                if(timeDisplayList.length > 1){
                    let hour = timeDisplayList[0].replace('12', '00'); 
                    let dateDisplay = date+" "+hour+":"+timeDisplayList[1]+":"+timeDisplayList[2]+" "+'am'; 
                    return dateDisplay;
                } else {
                    return formatDate;
                }
            } else {
                return formatDate;
            }

        } else {
            return formatDate;

        }
    }

    public getFormattedDisplayDateTimeSpan(inputDateTime: any) {
        return moment(
            inputDateTime,
            this.defaultTimeSpanInputFormat + ' ' + this.defaultTimeSpanDisplayFormat,
            true
        ).format(this.defaultTimeSpanInputFormat + ' ' + this.defaultTimeSpanDisplayFormat);
 
    }
}