import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { IntervalTimer } from '../models/interval-timer';

@Component({
  selector: 'sce-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class SCECarouselComponent implements OnInit, AfterViewInit {

  public intervalTimer: IntervalTimer;
  public isLoading: boolean = false;
  public currentIndex: number = 0;
  public negativeMargin: number = 0;

  @Input()
  public viewportHeight: number = 300;
  @Input()
  public viewportWidth: number = 300;
  @Input()
  public imageUrlsList: Array<string>;

  constructor() {}

  public ngOnInit() {
    this.negativeMargin
      = (((this.imageUrlsList.length * 30)
        + ((this.imageUrlsList.length + 1) * 10)) / 2) * -1;
  }

  public ngAfterViewInit() {
    this.intervalTimer = new IntervalTimer(() => {
      if ((this.currentIndex + 1) === this.imageUrlsList.length) {
        this.currentIndex = 0;
      } else {
        this.currentIndex++;
      }
    }, 3000);
    this.intervalTimer.start();
  }

  public navigateToSlide(index: number) {
    if (index === this.imageUrlsList.length) {
      index = 0;
    } else if (index === -1) {
      index = (this.imageUrlsList.length - 1) ;
    }
    this.intervalTimer.stop();
    this.currentIndex = index;
    this.intervalTimer.start();
  }

}
