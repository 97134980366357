import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-sce-core',
  template: `
    <p>
      sce-core works!
    </p>
  `,
  styles: []
})
export class SceCoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
