import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStorageService, KEYS_APPSTORAGE } from '../../security/app-storage.service';
import { domainName, router } from './menus';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { TokenService } from '../../security/token.service';
import { Observable } from 'rxjs';
import { LoginResponse } from '../../models/login-response.model';
import { UserInfo } from '../../models/user-info.model';
import { catchError,delay, map} from 'rxjs/operators';

@Component({
  selector: 'app-dispatcher-iframe',
  templateUrl: './dispatcher-iframe.component.html',
})
export class DispatcherIframeComponent implements OnInit {
  @Input() iframeUrl: string;
  public header =Â newÂ HttpHeaders({Â 'api':Â 'admin'Â });
  public safeSrc: SafeResourceUrl;
  public isframe: string;
  public userInfo: UserInfo;

  // public safeSrc: String;
  constructor(
    public http: HttpClient,
    public tokenService: TokenService,
    public sanitizer: DomSanitizer,
   public appStorageService: AppStorageService,
  ) {}
  public url: string;
  public ngOnInit() {
  var org:any  = document.getElementById('form-iframe')
  //   org.onload= () => {
  //     //è§£å³æå¼é«åº¦å¤ªé«çé¡µé¢ååæå¼é«åº¦è¾å°é¡µé¢æ»å¨æ¡ä¸æ¶ç¼©
  //     org.style.height = '0px';
  //     var iDoc = org.contentDocument || org.document
  //     var height:any = this.calcPageHeight(iDoc)
  //     if (height < 850) {
  //         height = 850;
  //     }
  //     org.style(height, height + 'px')
  // }
    let jwt_token = this.appStorageService.getItem('jwt_token')
    let domain;
    let host = window.location.origin;
    if(host.includes('lflogistics.net')) {
      if (host.includes('dev')) {
        domain = domainName.dev;
      } else if (host.includes('uat')) {
        domain = domainName.uat;
      } else if (host.includes('sit')) {
        domain = domainName.sit;
      } else {
        domain = domainName.prod;
      } 
    } else if(host.includes('ocf.fulfillment')) {
      if(host.includes('cdt')) {
        domain = domainName.dev_ocf_tms;
      } else if(host.includes('sit')) {
        domain = domainName.sit_ocf_tms;
      } else if (host.includes('stage')) {
        domain = domainName.uat_ocf_tms;
      } else {
        domain = domainName.prod_ocf_tms;
      }
    } else {
      domain = domainName.prod_ocf_tms;
    } 
    // this.activateInfo.queryParams.subscribe((queryParams) => {
    //   menuId = queryParams.menuId;
    // });
   
    console.log( 'localStorage', localStorage.getItem('menuId'), router[localStorage.getItem('menuId')], 'jwt_token', jwt_token);       
    this.url = domain + router[localStorage.getItem('menuId')] + '?access_token=' + jwt_token['token'] + '&userName' + jwt_token.userName;
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.url
    );
   // this.load();  
  // this.safeSrc = this.iframeUrl;
  }
  public ngOnChanges() {
    // this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
    //   this.url
    // );
    // this.safeSrc = this.iframeUrl;
  }
  calcPageHeight(doc) {
    var cHeight = Math.max(doc.body.clientHeight, doc.documentElement.clientHeight)
    var sHeight = Math.max(doc.body.scrollHeight, doc.documentElement.scrollHeight)
    var height = Math.max(cHeight, sHeight)
    return height
  }

  load(){
   const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
   input !== null && input.tagName === 'IFRAME';
   var isframe = document.getElementById('dispactherIframe');
  //  let jwt_token = localStorage.getItem('jwt_token'); 
  //  localStorage.setItem('jwt_token', jwt_token);
   setTimeout(()=> {
        if (isIFrame(isframe) && isframe.contentWindow) {
          isframe.contentWindow.postMessage({token: {}, status: 'success'}, '*')
        }
      }, 500) 
   }

}
