import { Injectable, Optional, SkipSelf } from '@angular/core';

/* enum to store all the key names that will be stored in memory 
export enum KEYS_APPSTORAGE1 {
  // TOKEN_NAME = "jwt_token",
  // USER_INFO = 'user_info'
}
*/
export class KEYS_APPSTORAGE {
  public static TOKEN_NAME: string = 'jwt_token';
  public static USER_INFO: string = 'user_info';
  public static BILLING_USER_INFO: string = 'billing_user_info'; //Billing UI Changes
  public static POMS_USER_INFO: string = 'poms_user_info'; //POMS UI Changes
  public static MENU_ROUTING: string = 'menu_routing'; // User Accessible menu routings
  public static DL_USER_INFO: string = 'dl_user_info'; //DL UI Changes
}

@Injectable()
export class AppStorageService {

  constructor(@Optional() @SkipSelf() parent: AppStorageService) {
    if (parent) {
      // If parent already loaded
      throw new Error(`Tried to initialize singleton service AppStorageService again.`);
    }
  }

  /* Generic methods to store and retrieve any object as key value */
  public getItem(key: string): any {
    const dataFromSession = JSON.parse(sessionStorage.getItem(key));
    if (dataFromSession) {
      return dataFromSession;
    } else {
      const dataFromLocal = JSON.parse(localStorage.getItem(key));
      if (dataFromLocal) {
        sessionStorage.setItem(key, JSON.stringify(dataFromLocal));
      }
      return dataFromLocal;
    }
  }
  public setItem(key: string, jsonObject: any) {
    sessionStorage.setItem(key, JSON.stringify(jsonObject));
    localStorage.setItem(key, JSON.stringify(jsonObject));
  }
  public removeItem(key) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }
  /* END of Generic methods to store and retrieve any object as key value */

}
