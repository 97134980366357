import {
  Component, OnInit, Input, Output, EventEmitter,
  AfterContentInit, ContentChildren, QueryList, OnChanges, SimpleChanges, OnDestroy
} from '@angular/core';
import { SCETabChangeEvent } from './tab-change-event';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'sce-tabbedpane',
  styleUrls: ['./tabbedpane.component.css'],
  template: `
             <div [hidden]="!expanded" class="sce-tabdpane-content" [class.expanded]="expanded">
                <ng-content></ng-content>
              </div>
            `
})
export class SCETabbedpaneComponent implements OnDestroy {
  static counter = 0; // To keep track of tabIndex

  @Input() expanded: boolean;
  @Input() header: string;
  @Input() tabId: string;
  public tabIndex: number;


  constructor() {
    this.tabIndex = SCETabbedpaneComponent.counter++;
    if (!this.tabId) {
      this.tabId = this.tabIndex.toString();
    }
  }

  ngOnDestroy() {
    SCETabbedpaneComponent.counter = 0;
  }

}


@Component({
  selector: 'sce-tabbedpanes',
  styleUrls: ['./tabbedpane.component.css'],
  template: `
            <div  class="sce-tabdpanes-wrapper">
              <div class="sce-tabdpanes-header-wrapper">
                <span *ngFor="let tab of tabs"
                (click)="showTab(tab)"
                title="{{tab.header}}"
                class="sce-tabdpane-header"
                [class.expanded]="tab.expanded">
                  {{tab.header}}
                </span>
              </div>
            <ng-content></ng-content>
            </div>
            `
})
export class SCETabbedpanesComponent implements AfterContentInit, OnChanges {

  constructor() { }
  @ContentChildren(SCETabbedpaneComponent) tabs: QueryList<SCETabbedpaneComponent>;
  // emits the selected tab's id or index if id is null
  @Output() openedTabId: EventEmitter<string> = new EventEmitter();
  public eventSubscription: Subscription;
  // used to check whether we need to do some pretab change validations
  @Output() preTabChanged: EventEmitter<SCETabChangeEvent> = new EventEmitter();
  @Input() selectTabIndex: number;
  public ngOnChanges(changes: SimpleChanges) {
    if (changes['selectTabIndex']) {

      if (this.tabs
        && changes['selectTabIndex'].currentValue > -1
        && changes['selectTabIndex'].currentValue < this.tabs.toArray().length) {
        this.showTab(this.tabs.toArray()[changes['selectTabIndex'].currentValue]);
      }
    }
  }
  ngAfterContentInit() {
    // open the first tab none of the tab has input expanded=true
    const activeTabs = this.tabs.filter((tab) => tab.expanded);
    if (activeTabs.length === 0) {
      this.showTab(this.tabs.first);
    }

    // else {
    //   this.showTab(activeTabs[0]) ;
    // }
  }


  public showTab(selectedTab: SCETabbedpaneComponent) {
    const tabChangeEvent = new SCETabChangeEvent();
    tabChangeEvent.tabId = selectedTab.tabId;
    tabChangeEvent.hasError = false;
    this.preTabChanged.emit(tabChangeEvent);

    if (!tabChangeEvent.hasError) {
      // unselect all tabs
      this.tabs.toArray().forEach(tab => tab.expanded = false);

      // select the clicked tab.
      selectedTab.expanded = true;
      this.openedTabId.emit(selectedTab.tabId);
    }
  }
}
