import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AlertService } from './components/alerts/alert.service';
import { AlertsComponent } from './components/alerts/alerts.component';
import { ButtonPanelComponent } from './components/button/button-panel.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './components/confirm-dialog/confirm-dialog.service';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderService } from './components/loader/loader.service';
import { DashboardService } from './dashboard.service';
import { JReportService } from './jreport-service';
import { LoggerService } from './logger.service';
import { NavigationService } from './navigation.service';
import { ResourceService } from './resource.service';
import { SceCoreComponent } from './sce-core.component';
import { AppStorageService } from './security/app-storage.service';
import { SessionTimerService } from './security/session-timer.service';
import { TokenService } from './security/token.service';
import { UserService } from './security/user.service';
import { FormsModule } from '@angular/forms';
import { DynamicWidgetDirective } from './components/modal/widget/dynamic-widget-directive';
import { SCEModalComponent } from './components/modal/modal.component';
import { CustomPaginationComponent } from './components/custom-pagination/custom-pagination.component';
import { SCEDropdownComponent } from './components/dropdown/dropdown.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { SCELeftPanelComponent } from './components/left-panel/left-panel.component';
import { SCEIframeComponent } from './components/iframe/iframe.component';
import { SCECarouselComponent } from './components/carousel/carousel.component';
import { SCETabbedpaneComponent, SCETabbedpanesComponent } from './components/tabbedpane/tabbedpane.component';
import { SCEAccordionComponent } from './components/accordion/accordion.component';
import { SCECarouselHomeComponent } from './components/dynamic-carousel-view/dynamic-carousel-view.component';
import { SCEDashboardHomeComponent } from './components/dashboard-home/dashboard-home.component';
import { ModalService } from './components/modal/modal.service';
import { CustomPaginationService } from './components/custom-pagination/custom-pagination.service';
import { LeftPanelService } from './components/left-panel/left-panel.service';
import { DatePickerConfigService } from './components/datepicker/datepicker-config.service';
import { TooltipModule } from 'primeng/tooltip';
import { PrinterSetupService } from './home/printer-setup/printer-setup.service';
import { PrinterSetupComponent } from './home/printer-setup/printer-setup.component';
import { MenuComponent } from './home/menu/menu.component';
import { LoginComponent } from './login/login.component';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { HttpClientModule } from '@angular/common/http';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { CountryRoleComponent } from './user-management/country-role/country-role.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserListComponent } from './user-list/user-list.component';
import { RoleManagementComponent } from './role-management/role-management.component';
import { GroupManagementComponent } from './group-management/group-management.component';
import { PrivilegeListComponent } from './group-management/privilege-list/privilege-list.component';
import { FieldManagementComponent } from './field-management/field-management.component';
import { UserLookupComponent } from './user-lookup/user-lookup.component';
import { DowntimeConfigurationComponent } from './downtime-configuration/downtime-configuration.component';
import { DowntimeConfigurationDashboardComponent } from './downtime-configuration/downtime-configuration-dashboard/downtime-configuration-dashboard.component';
import { ScheduleDowntimePopupComponent } from './downtime-configuration/popup/schedule-downtime-popup.component';
import { RoleManagementService } from './role-management/role-management.service';
import { GroupManagementService } from './group-management/group-management.service';
import { UserManagementService } from './user-management/user-management.service';
import { from } from 'rxjs';
import { InputSwitchModule } from 'primeng-lts/inputswitch';
import { TableModule } from 'primeng-lts/table';
import { DialogModule } from 'primeng-lts/dialog';
import { DropdownModule } from 'primeng-lts/dropdown';
import {CalendarModule} from 'primeng-lts/calendar';
import { DowntimeConfigurationService } from './downtime-configuration/downtime-configuration.service';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { MaintenanceNoticePopupComponent } from './downtime-configuration/popup/maintenance-notice-popup/maintenance-notice-popup.component';
import { DispatcherIframeComponent } from './components/dispatcher-iframe/dispatcher-iframe.component';

/*
    *****************************
    *****   SCE Core Library  *****
    *****   README          *****
    *****************************
This module will have a collection of core framework and services that will be common across the SCE Platform.
All applications under sce-platform will be using these services as such and should be kept as generic as posible.

Strictly follow the below points to keep the library standards.

  --  *** important ***  No external references allowed since the core needs to be independent fo everything else.
      This module has to be isolated from all other modules and no module should be imported into this module.
  --  This module will have services related to the core of the platform and are not application specific,
      This would include any generic logging,token and session management, application global storage management etc.
  --  The entire application navigation should only be done through NavigationService and the angular router should not be directly used.
*/
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DialogModule,   // required for p-dialog used in SCEModalComponent-- primeng dependency need to to be removed from SCEUILibrary
    DropdownModule,  // required for p-dropdown used in CustomPaginationComponent -- primeng dependency need to to be removed from SCEUILibrary
    CalendarModule,
    TooltipModule,
    MultiselectDropdownModule,
    DropdownModule,
    AdminRoutingModule,
    HttpClientModule,
    TableModule,
    InputSwitchModule
  ],
  declarations: [SceCoreComponent,
    AlertsComponent,
    ConfirmDialogComponent,
    LoaderComponent,
    //sce-ui-lib starts
    DynamicWidgetDirective,
    SCEModalComponent,
    CustomPaginationComponent,
    SCEDropdownComponent,
    DatepickerComponent,
    SCELeftPanelComponent,
    SCEIframeComponent,
    SCECarouselComponent,
    SCETabbedpaneComponent,
    SCETabbedpanesComponent,
    SCEAccordionComponent,
    SCECarouselHomeComponent,
    SCEDashboardHomeComponent,
    //sce-ui-lib ends
    PrinterSetupComponent,
    MenuComponent,
    LoginComponent,
    AdminHomeComponent,
    UserManagementComponent,
    CountryRoleComponent,
    UserListComponent,
    RoleManagementComponent,
    GroupManagementComponent,
    PrivilegeListComponent,
    UserLookupComponent,
    FieldManagementComponent,
    DowntimeConfigurationComponent,
    DowntimeConfigurationDashboardComponent,
    ScheduleDowntimePopupComponent,
    MaintenanceNoticePopupComponent,
    ButtonPanelComponent,
    UserProfileComponent,
    DispatcherIframeComponent


  ],
  exports: [SceCoreComponent,
    AlertsComponent,
    ConfirmDialogComponent,
    LoaderComponent,
    DynamicWidgetDirective,
    SCEModalComponent,
    CustomPaginationComponent,
    SCEDropdownComponent,
    DatepickerComponent,
    SCELeftPanelComponent,
    SCEIframeComponent,
    SCECarouselComponent,
    SCETabbedpaneComponent,
    SCETabbedpanesComponent,
    SCEAccordionComponent,
    SCECarouselHomeComponent,
    SCEDashboardHomeComponent,
    PrinterSetupComponent,
    MenuComponent,
    LoginComponent,
    AdminHomeComponent,
    UserManagementComponent,
    CountryRoleComponent,
    UserListComponent,
    RoleManagementComponent,
    GroupManagementComponent,
    PrivilegeListComponent,
    UserLookupComponent,
    FieldManagementComponent,
    DowntimeConfigurationComponent,
    DowntimeConfigurationDashboardComponent,
    ScheduleDowntimePopupComponent,
    MaintenanceNoticePopupComponent,
    ButtonPanelComponent,
    UserProfileComponent
  ]
})

export class SceCoreModule {

  static forRoot(): ModuleWithProviders<SceCoreModule> {
    return {
      ngModule: SceCoreModule,
      providers: [
        AlertService,
        ConfirmDialogService,
        // { provide: ErrorHandler, useClass: CoreErrorHandler },
        ResourceService,
        LoggerService,
        AppStorageService,
        SessionTimerService,
        TokenService,
        UserService,
        NavigationService,
        JReportService,
        LoaderService,
        DashboardService,
        ModalService,
        CustomPaginationService,
        LeftPanelService,
        DatePickerConfigService,
        PrinterSetupService,
        RoleManagementService,
        GroupManagementService,
        UserManagementService,
        DowntimeConfigurationService

      ]
    };
  }
}

export * from './sce-config';
export { DashboardService } from './dashboard.service';
export { ResourceService } from './resource.service';
export { ConfirmDialogService } from './components/confirm-dialog/confirm-dialog.service';
export { AppStorageService, KEYS_APPSTORAGE } from './security/app-storage.service';
export { SessionTimerService } from './security/session-timer.service';
export { TokenService } from './security/token.service';
export { UserService } from './security/user.service';
export { NavigationService } from './navigation.service';
export { LoggerService } from './logger.service';
export { JReportService } from './jreport-service';
export { AlertService } from './components/alerts/alert.service';
export { LoaderService, LoaderEnabled } from './components/loader/loader.service';
export { ModalService } from './components/modal/modal.service';
export { SCEModalComponent } from './components/modal/modal.component';
export { DynamicWidgetDirective } from './components/modal/widget/dynamic-widget-directive';
export { CustomPaginationService } from './components/custom-pagination/custom-pagination.service';
export { SCEDropdownComponent } from './components/dropdown/dropdown.component';
export { DatepickerComponent } from './components/datepicker/datepicker.component';
export { DatePickerConfigService } from './components/datepicker/datepicker-config.service';
export { LeftPanelService } from './components/left-panel/left-panel.service';
export { LoginComponent } from './login/login.component';
export { UserProfileComponent } from './components/user-profile/user-profile.component';
export { MenuComponent } from './home/menu/menu.component';
export { PrinterSetupComponent } from './home/printer-setup/printer-setup.component';
export { PrinterSetupService } from './home/printer-setup/printer-setup.service';
export { GroupManagementService } from './group-management/group-management.service';
export { UserManagementService } from './user-management/user-management.service';
export * from './constants';
export { DowntimeConfigurationService } from './downtime-configuration/downtime-configuration.service';
export { DispatcherIframeComponent } from './components/dispatcher-iframe/dispatcher-iframe.component'
