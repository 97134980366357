import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { LookUpUser } from '../models/lookup-user';
import { User } from '../models/user';
import { map, catchError } from 'rxjs/operators';
import { ResourceService } from '../resource.service';


@Injectable()
export class UserManagementService {

    public userArray = [];
    public name: string = 'Dummy\'s initial Name';
    public headers = new HttpHeaders({ 'Content-Type': 'application/json' , 'api' : 'admin'});
    public ctr: number = 0;

    public disablefield: boolean = false;
    public icondisable: boolean = true;
    public search_value: string;
    public selectedUserDetails: LookUpUser;
    public selectedUserDetails_LookUp: LookUpUser;
    public userUrl: string = './assets/user.json';
    public userlookupUrl: string = './assets/userLookUp.json';
    public newSubject = new Subject<any>();
    public searchsubject = new Subject<any>();
    public filtersubject = new Subject<any>();
    public appCode: number = 1;
    public selectedAppRoles: any;
    public gvtRoleRestriction: any;
    public selectedRole: any;
    constructor(public _http: HttpClient, public resourceService: ResourceService) { }
    // method used to get user array
    public getUserArray(): any {
        return this.userArray;
    }
    // method used to get user details
    public userDetails(): Observable<User> {
        return this._http.get(this.userUrl, { headers: this.headers }).pipe(
            map((response: User) => <User>response),
            catchError(this.handleError));
    }
    // methos used to get the details to be populated in lookup table
    public userLookUpDetails(): Observable<LookUpUser[]> {
        return this._http.get(this.userlookupUrl, { headers: this.headers }).pipe(
            map((response: LookUpUser[]) => <LookUpUser[]>response),
            catchError(this.handleError));
    }
    // method used to send row selected  from  lookup component to user management component
    public retrieve(value: string) {
        this.newSubject.next({ text: value });
    }
    // method used to get message
    public getMessage(): Observable<any> {
        return this.newSubject.asObservable();
    }
    // method used to send search keyword from user management component to lookup component
    public sendsearch(value: string) {
        this.searchsubject.next({ text: value });
    }
    // method used to receive search keyword from user management component to lookup component
    public getSearch(): Observable<any> {
        return this.searchsubject.asObservable();
    }
    // method used to handle error
    public handleError(error: Response) {
        return Observable.throw(error || this.resourceService.get('msg_um_ServrErrDuringLogin'));
    }
    // method used to retrive value
    public retrievevalue(value: string) {
        this.filtersubject.next();
    }
    // method used to get value
    public getvalue(): Observable<any> {
        return this.filtersubject.asObservable();
    }
    // method used to get name
    public getName() {
        return this.name;
    }
    // method used to set name
    public setName(newName: string) {
        this.name = newName;
    }
    // method used to get counter
    public getCounter() {
        return 'Shared counter-' + this.ctr++;
    }
    // method used to get all application code on page load
    public getAllApps(): Observable<any[]> {
        return this._http.get('api/core/applications', { headers: this.headers }).pipe(
            map((response: any[]) => <any[]>response),
            catchError(this.handleError));
    }
    //[LFWM-2614][User Management - Application access restrictions][Change the api to get the app list based on login user]
    // method used to get all application code on page load
    public getAllUserApps(): Observable<any[]> {
        return this._http.get('api/core/user/applications', { headers: this.headers }).pipe(
            map((response: any[]) => <any[]>response),
            catchError(this.handleError));
    }
    // method used to get all countries on page load
    public getAllCountries(): Observable<any[]> {
        return this._http.get('api/app/countries', { headers: this.headers }).pipe(
            map((response: any[]) => <any[]>response),
            catchError(this.handleError));
    }
    //
    public getRestrictedCountries(appId): Observable<any[]> {
        const appCode = appId;
        return this._http.get('api/users/1/countries', { headers: this.headers }).pipe(
            map((response: any[]) => <any[]> ((appCode === 2 || appCode === 6) ? [16] : response ) ),
            catchError(this.handleError));
    }
    // method use to get all user's countries base on role
    public getUserCountries(appId): Observable<any[]> {
        const appCode = appId;
        return this._http.get('api/users/1/usercountries', { headers: this.headers }).pipe(
            map((response: any[]) => <any[]> ((appCode === 2 || appCode === 6) ? [16] : response ) ),
            catchError(this.handleError));
    }
    // method used to get all  restrictions on page load
    public getAllRestrictions(): Observable<any[]> {
        return this._http.get('api/app/restrictions', { headers: this.headers }).pipe(
            map((response: any[]) => <any[]>response),
            catchError(this.handleError));
    }
    // method used to get all the groups which has a role associated to it on page load
    public getAllRoleGroups(): Observable<any[]> {
        return this._http.get('api/app/' + this.appCode +'/groups', { headers: this.headers }).pipe(
            map((response: any[]) => <any[]>response),
            catchError(this.handleError));
    }
      // method used to get all the groups and the corresponding roles on page load
      public getAllGroups(appId): Observable<any[]> {
        return this._http.get('api/admin/'+appId+'/groups', { headers: this.headers }).pipe(
            map((response: any[]) => <any[]>response),
            catchError(this.handleError));
    }
    // method used to get roles by using country id and group id
    public getAllRolesById(countryId: string, GroupId: string): Observable<any[]> {
        const rolesurl = 'api/app/' + countryId + '/' + GroupId + '/roles';
        return this._http.get(rolesurl, { headers: this.headers }).pipe(
            map((response: any[]) => <any[]>response),
            catchError(this.handleError));
    }
    // method used to add or update the user details
    public addUser(user: any): Observable<any> {
        user.applicationId = this.appCode;
        return this._http.post('api/users/', user, { headers: this.headers }).pipe(
            map((response: any[]) => <any>response),
            catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.status === 400) {
                        return Observable.throw(error.error.errors['0'].defaultMessage);
                    }else if (error.error.errors[0].validationCode !== 200) {
                        return Observable.throw(error.error.errors['0'].validationMessage);
                    }
                }

            }));
    }

    public updateUser(user: any, moduleCode: any): Observable<any> {
        user.applicationId = this.appCode;
        let url;
        if (moduleCode) {
            user.applicationId = 4;
            url = 'api/users/' + moduleCode
        } else {
            url = 'api/users/'
        }
        return this._http.put(url, user, { headers: this.headers }).pipe(
            map((response: any[]) => <any>response),
            catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.status === 400) {
                        return Observable.throw(error.error.errors['0'].defaultMessage);
                    }else if (error.error.errors[0].validationCode !== 200) {
                        return Observable.throw(error.error.errors['0'].validationMessage);
                    }


                }

            }));
    }
    // method used to get all users
    public getAllUsers(): Observable<any[]> {
		/* Dummy header with key='api' may be injected to identify and separate api calls 
	to common library or differentiate between application specific api calls */
        const hdr = new HttpHeaders({ 'api': 'admin' });
        return <Observable<any[]>> this._http.get('api/users/', {headers : hdr} ).pipe(
            map((response) => {
                return response;
            })
            , catchError((error: any) => {
                return Observable.throw(this.resourceService.get('server_err_lot_data') + this.resourceService.get(error.message));
            }));
      
    }
    // method used to get usernames
    public getUserNames(name_value): Observable<any[]> {
        this.userArray = [];
        return this._http.get('api/users/suggestions/' + name_value, { headers: this.headers }).pipe(
            map((response: any[]) => <any[]>response),
            catchError(this.handleError));
    }
    // method used to get corespondimg user detail by id and type
    public getUser(id, type, appCode?: Optional, code?: Optional): Observable<any> {
        if (!appCode) {
            appCode = 1;
        }
        type = (type === 'Internal') ? 0 : 1;
        let userurl = 'api/users/' + id + '/' + type + '/';
        if (appCode && code === '') {
            userurl = 'api/users/' + appCode + '/' + id + '/' + type + '/';
        } else {
            userurl = 'api/users/' + appCode + '/' + id + '/' + type + '/'+ code + '/';
        }
        //const userurl = 'api/users/' + appCode + '/' + id + '/' + type + '/';
        this.userArray = [];
        return this._http.get(userurl, { headers: this.headers }).pipe(
            map((response) => response),
            catchError(this.handleError));
    }

    // method used to get roles
    public getAllRoles(appId): Observable<any[]> {
        return this._http.get('api/app/'+appId+'/roles', { headers: this.headers }).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.error.errors[0].validationCode !== 200) {
                        return Observable.throw(error.error.errors['0'].validationMessage);
                    }
                }
            }));
    }

    //method to get role restriction
    public getRoleRestriction(role) {
        return this._http.get('api/users/' + role + '/restriction', { headers: this.headers }).pipe(
        map((response: any) => {
            return response;
        }),
        catchError((error: any) => {
            if (error.error && error.error.errors && error.error.errors.length !== 0) {
                if (error.error.errors[0].validationCode !== 200) {
                    return Observable.throw(error.error.errors['0'].validationMessage);
                }
            }
        }));
    }
    // method used to get logged in user roles
    public getUserRoles(appId): Observable<any[]> {
        return this._http.get('api/users/'+appId+'/roles', { headers: this.headers }).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.error.errors[0].validationCode !== 200) {
                        return Observable.throw(error.error.errors['0'].validationMessage);
                    }
                }
            }));
    }

    //LFWM-2363-Checking if user is super admin or not
    public isSuperAdmin(){
        return this._http.get('api/admin/user/superadmin/access', { headers: this.headers }).pipe(
        map((response: any) => {
            return response;
        }),
        catchError((error: any) => {
            if (error.error.errors.length !== 0) {
                if (error.error.errors[0].validationCode !== 200) {
                    return Observable.throw(error.error.errors['0'].validationMessage);
                }
            }
        }));
    } 
    /*LFWM-2511 UI changes for reset password */
    public  resetPassword(userId){
        return this._http.put('api/users/'+ userId+'/password/reset','', { headers: this.headers }).pipe(
            map((response: any[]) => <any>response),
            catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.status === 400) {
                        return Observable.throw(error.error.errors['0'].defaultMessage);
                    }else if (error.error.errors[0].validationCode !== 200) {
                        return Observable.throw(error.error.errors['0'].validationMessage);
                    }
                }
            }));
    }
    public getAllRoleGroupsForApp(appId : any): Observable<any[]> {
        return this._http.get('api/app/' + appId +'/groups', { headers: this.headers }).pipe(
            map((response: any[]) => <any[]>response),
            catchError(this.handleError));
    }
    //LFWM-2755 RG| Deactivate Internal/external user
    //http://localhost:8080/scecommon/api/users/{userName}/{isActive}/deactivate
    //toggleUserStatus
    public  toggleUserStatus(username,status){
        return this._http.put('api/users/'+ username+'/'+status+'/activate','', { headers: this.headers }).pipe(
            map((response: any[]) => <any>response)
            ,catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.status === 400) {
                        return Observable.throw(error.error.errors['0'].defaultMessage);
                    }else if (error.error.errors[0].validationCode !== 200) {
                        return Observable.throw(error.error.errors['0'].validationMessage);
                    }
                }
            }));
    }
}
