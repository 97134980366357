import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UserCountryRole } from '../../models/user-country-role';
import { UserManagementComponent } from '../user-management.component';

import moment from 'moment';
import { AlertService } from '../../components/alerts/alert.service';
import { ConfirmDialogService } from '../../components/confirm-dialog/confirm-dialog.service';
import { ResourceService } from '../../resource.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[lfwms-country-role]',
  templateUrl: './country-role.component.html',
  styleUrls: ['./country-role.component.css']
})
export class CountryRoleComponent  {

  @Input() countryRole: UserCountryRole;
  @Input() index: number;
  @Input() appCode: number;

  public user: any = {};
  public minExpirationDate: Date;
  public maxExpirationDate: Date;
  public yearRangeForExpirationDate: string;

  constructor(public usrMgmt: UserManagementComponent, public alertservice: AlertService,
    public confirmDialogService: ConfirmDialogService,
    public resourceService: ResourceService) {
    this.user = this.usrMgmt.user;
    this.appCode = parseInt(this.user.applicationCode, 10);
    /* [LFWM-2393] - max of expirationDate should be one year from todays date
     if today - 2020-10-13, then max of expirationDate - 2021-10-12 */
    const toDay = new Date();
    this.minExpirationDate = new Date();
    this.maxExpirationDate = new Date();
    this.maxExpirationDate.setFullYear(toDay.getFullYear() + 1, toDay.getMonth(), toDay.getDate() - 1);
    const d = moment();
    const yearPickerStart = d.year();
    const yearPickerEnd = d.year() + 1;
    // year range of expirationDate should starts from current year and maximum upto one year [Ex: 2020:2021]
    this.yearRangeForExpirationDate = yearPickerStart + ':' + yearPickerEnd;
  }


  // method used to repopulate groups and populate coreesponding roles on group change
  public onChangeGroups($event: any) {
    const selectedGrpIds = [].concat($event);
    const currentlySelectedRoleIds = this.countryRole.selectedRoleIdsForDisplay.concat([]);
    this.countryRole.allRoles = [];
    this.countryRole.selectedRoleIdsForDisplay.splice(0);
    if (selectedGrpIds.length > 0) {
      this.countryRole.showRoles = true;
      selectedGrpIds.forEach((grpId: any) => {
        let rolesForGrp;
        if(this.appCode === 4){
         rolesForGrp = this.usrMgmt.roleGroupMap[grpId];
        }else{
          rolesForGrp = this.usrMgmt.roleGroupMap[grpId].filter(roleEach =>
          roleEach.country === this.countryRole.countryId);
        }
        this.countryRole.allRoles = this.countryRole.allRoles.concat(rolesForGrp);
        rolesForGrp.forEach((role: any) => {
          if (currentlySelectedRoleIds.indexOf(role.id) !== -1) {
            this.countryRole.selectedRoleIdsForDisplay.push(role.id);
          }
        });
      });
    } else {
      this.countryRole.showRoles = false;
    }
  }
  // method used to delete the roles
  public deleteRoles(countryId: any) {

    const rolesMessage = 'You are about to permanently delete the roles, all related country ' + '/' +
      // tslint:disable-next-line:no-unused-expression
      'region restriction will be delete. Once you confirm and press Yes' + ',' + 'it cannot be undone or recovered';
    this.confirmDialogService.confirmDialog(this.resourceService.get(rolesMessage))
      .subscribe((isConfirmed: any) => {
        if (isConfirmed) {
          const retVal: boolean = true;
          let allowDelete = false;
          const errorMsg = 'msg_um_RoleRestrnErrMsg';
         // this.alertservice.clearAll().warn(this.resourceService.get(errorMsg));
          if (this.appCode === 1 || this.appCode === 4 || this.appCode === 5 || this.appCode === 6 || this.appCode === 8) {
            const index = this.usrMgmt.userCountryRoleList.indexOf(this.countryRole);
            if (retVal === true && index !== -1) {
              this.usrMgmt.userCountryRoleList.splice(index, 1);
            }
          } else if (this.appCode === 2) {
            const index = this.usrMgmt.userCountryRoleListForTemplate.indexOf(this.countryRole);
            if (retVal === true && index !== -1) {
              const roleRestrictionVal = this.usrMgmt.userCountryRoleListForTemplate[index].restrictionVal;
              this.usrMgmt.userCountryRoleListForTemplate.splice(index, 1);
              this.usrMgmt.userCountryRoleList.splice(index, 1);
              const userListToDelete = this.usrMgmt.userlist.filter(selectedUsrlist => selectedUsrlist.countryId === countryId
              );
              const tmp: any = [];
              this.usrMgmt.userlist[0].Value.split(',').forEach(value => {
                if (value !== roleRestrictionVal) {
                  tmp.push(value);
                }
              });

            if (tmp.length === 0) {
              this.usrMgmt.userlist.splice(0, 1);
            }else {
              this.usrMgmt.userlist[0].Value = tmp.toString();
            }

        }
      }
      else if(this.appCode ===3 || this.appCode === 7){
        const index = this.usrMgmt.userCountryRoleListForTemplate.indexOf(this.countryRole);
        if (retVal === true && index!==-1) { 
          const roleRestrictionVal = this.usrMgmt.userCountryRoleListForTemplate[index].restrictionVal;
           let userListToDelete = this.usrMgmt.userlist.filter(selectedUsrlist =>selectedUsrlist.countryId === countryId
           )
           const tmp : any=[];
          this.usrMgmt.userlist.forEach((usrList, jindex) => {
            if (usrList.countryId === userListToDelete[0].countryId) {
              usrList.Value.split(',').forEach((value, k) => {
                if (value !== roleRestrictionVal) {
                  tmp.push(value);
                }
                if (usrList.Value.split(',').length- 1 === k) {
                  if(tmp.length === 0){
                    this.usrMgmt.userlist.splice(jindex,1);
                  }else{
                    allowDelete = true;
                    this.usrMgmt.userlist[jindex].Value =tmp.toString();
                  }
                }
              });
            }
          }); 
          this.usrMgmt.userCountryRoleListForTemplate.splice(index, 1);
        }
      }
      if (allowDelete === false) {
        // new changes with notification
        this.usrMgmt.modifiedUserList = this.usrMgmt.modifiedUserList.filter(a => a.countryId !== countryId);
    }
          this.usrMgmt.userlist = this.usrMgmt.modifiedUserList;

        } else {
          return;
        }
});

}
}

