import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class LoaderService {

  public static loaderEnabled = false;

  constructor() {
  }

  public static showLoader() {
    LoaderService.loaderEnabled = true;
  }

  public static hideLoader() {
    LoaderService.loaderEnabled = false;
  }



  get loaderEnabled() {
    return LoaderService.loaderEnabled;
  }


  public showLoader() {
    LoaderService.loaderEnabled = true;
  }

  public hideLoader() {
    LoaderService.loaderEnabled = false;
  }

}




/** Implementation For LoaderEnabled Decorator
 * Use @LoaderEnabled() above any method that returns and observable
 * so that the loader will be shown when the method is called and 
 * hidden when the subscription is executed.
 *
 * Also a timer is used so that All requests that complete within
 *  say less than 200ms is not shown with a loader.
 */
export function LoaderEnabled(): MethodDecorator {

  return function ( target: any, propertyKey: string, descriptor: PropertyDescriptor ) {
    const original = descriptor.value;
    descriptor.value = function () {
    if (this.timer) {
        clearTimeout(this.timer);
      }
       this.timer = setTimeout(() => {
         LoaderService.showLoader();
       }, 200);
    // console.log('******Decorator call -LOADERON', propertyKey);
      return original.apply(this, arguments).pipe(
          map((res) => {
            clearTimeout(this.timer);
           // console.log('******Decorator call done--LOADEROFF', propertyKey);
            LoaderService.hideLoader();
            return res;
          }), catchError((err) => {
            clearTimeout(this.timer);
          //  console.log('******Decorator call done--LOADEROFF', propertyKey);
            LoaderService.hideLoader();
            throw err;
          }));
  };
  return descriptor;
};

}


/*

public addLoader(target: Observable<Object>) {
  console.log('******Method call -LOADERON', target);
  return target
          .finally(() => {
            console.log('******Method done--LOADEROFF');
          });
  }
*/