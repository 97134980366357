import { Component } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { DashboardEvent, REMOVE_FAVOURITE } from '../models/dashboard-event';

@Component({
  selector: 'sce-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.css']
})
export class SCEDashboardHomeComponent {

  public show: boolean = false;
  public apiReportList: any[];
  public apiErrorlist: any[];
  public favouritesList: any;
  public favouriteReportsList: any[];
  public favouriteDashboardList: any[];
  public width = '100%';
  // Set default height for Iframe
  public height = '728';
  public userLoggedinCountryCode: any;
  
  constructor(public dashboardService: DashboardService) {
  }

  public removeFav(reports, jindex, index) {
    // If single chart in a row 
	// Removed it from Array & update the dom
    if (index === undefined) {
      const unFav = this.dashboardService.favouriteDashboardList[jindex].responsiveChart;
      this.dashboardService.removeFavourites(unFav).subscribe((resp: any) => {
        delete this.dashboardService.favouriteDashboardList[jindex].responsiveChart;
        if (this.dashboardService.favouriteDashboardList[jindex].reportsList.length === 0 
          && !this.dashboardService.favouriteDashboardList[jindex].responsiveChart) {
            this.dashboardService.showCarousel = true;
          }
      });
    } else {
      // If two charts in a row 
	  // Removed it from Array & update the dom
      const unFav = reports.reportsList[index];
      this.dashboardService.removeFavourites(unFav).subscribe((resp: any) => {
        reports.reportsList.splice(index, 1);
        this.dashboardService.favouriteDashboardList[jindex].reportsList = reports.reportsList;
        if (this.dashboardService.favouriteDashboardList[jindex].reportsList.length === 0) {
          this.dashboardService.favouriteDashboardList.splice(jindex, 1);
          this.dashboardService.showCarousel = true;
        }
      });
    }
    // Load the DOM again after remving charts
    const event = new DashboardEvent();
    event.type = REMOVE_FAVOURITE;
    this.dashboardService.notifyEvent(event);
  }
  public toggleRemoveBtn(jindex, index) {
    // Toggle remove button, for Single chart
    if (index === undefined) {
      this.dashboardService.favouriteDashboardList[jindex].responsiveChart.show = 
      !this.dashboardService.favouriteDashboardList[jindex].responsiveChart.show;
    } else {
      // Toggle remove button, for two charts in a row
      this.dashboardService.favouriteDashboardList[jindex].reportsList[index].show = 
      !this.dashboardService.favouriteDashboardList[jindex].reportsList[index].show;
    }
  }
}
