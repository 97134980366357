import { Component, Input} from '@angular/core';
import { ResourceService } from '../../resource.service';

@Component({
  selector: 'lfwms-privilege-list',
  templateUrl: './privilege-list.component.html',
  styleUrls: ['./privilege-list.component.css']
})
export class PrivilegeListComponent {

  /**
   * Component Logic ::
   * This Component is intended to display the priveleges corresponding to a User Role,
   * specific to each Module in a tabular format, where the Administrator/User can
   * View/Edit Privileges based on isReadOnly flag.
   * formattedPrivilegeData is an Input Object array, created from the Access Privilege Response
   * which is in a tree structure and Privilege Id's corresponding to Role Id.
   * formattedPrivilegeData will be in the below structure:
   * [{
   *    level: 0/1/2/3, <--- Depends on the level of the Module in Access Privilege Response
   *    displayId: xxx <--- Resource Bundle Id
   *    parentIndex: number <--- Index of the Parent Module in the formattedPrivilegeData Array,
   *                      used to toggle the Select All Button corresponding to each Root Module
   *    isEnabled: boolean <--- Flag to hold the value of Select All Button corresponding to each Root Module
   *    privileges: [
   *                  [
   *                    {
   *                      id: xxx, <--- Privilege Id
   *                      display: xxx, <--- Resource Bundle Id
   *                      isChecked: boolean <--- Flag to hold the Checked/Unchecked Status
   *                      }...
   *                   ]...
   *                ] <-- Note : Each element in this Array is indeed (2-D) Array,
   *                      used to preserve the Grid Layout while displaying in tabular format
   * }...]
   */
  @Input() formattedPrivilegeData: any;
  @Input() isReadOnly: boolean = false;


  constructor(public resourceService: ResourceService) { }

  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  /*This method is used to enable child modules while clicking on parent module */
  public toggleSelectAll(parentModuleDefinition: any, moduleIndex: number) {
    parentModuleDefinition.isEnabled = parentModuleDefinition.isEnabled;
    if (parentModuleDefinition.level === 0) {
     for (let x = moduleIndex; x < this.formattedPrivilegeData.length; x++) {
        const moduleDefinition = this.formattedPrivilegeData[x];
        if (parentModuleDefinition.isChecked) {
         if (parentModuleDefinition.parentIndex === this.formattedPrivilegeData[x].parentIndex
            && this.formattedPrivilegeData[x].isNotDetailScreen) {
            this.formattedPrivilegeData[x].isEnabled = parentModuleDefinition.isChecked;
          }
        } else {
          if (parentModuleDefinition.parentIndex === this.formattedPrivilegeData[x].parentIndex) {
              this.formattedPrivilegeData[x].isEnabled = parentModuleDefinition.isChecked;
            this.formattedPrivilegeData[x].isChecked = parentModuleDefinition.isChecked;
            this.formattedPrivilegeData[x].privileges.forEach((privilege) => {
              privilege.forEach((privil: any) => {
                privil.isEnabled = parentModuleDefinition.isEnabled;
                privil.isChecked = parentModuleDefinition.isChecked;
              });
            });
          }
        }
      }
    } else {
      if (this.formattedPrivilegeData.length > 0) {
        this.formattedPrivilegeData[moduleIndex].privileges.forEach((privilege) => {
          privilege.forEach((privil: any) => {
            privil.isEnabled = parentModuleDefinition.isChecked;
            privil.isChecked = parentModuleDefinition.isChecked;
          });
        });
        if (parentModuleDefinition.isDetailParent) {
          for (let x = moduleIndex; x < this.formattedPrivilegeData.length; x++) {
            const moduleDefinition = this.formattedPrivilegeData[x];
            /* The x-dock in inbound and kitting under inventory has the same currentIndex , so when the detail item with
             subParentIndex === parentId of Selected, kitting detail will also be selected. So we need to add a check to
              select only those items with the parentIndex also same  */
            if ((parentModuleDefinition.currentIndex === this.formattedPrivilegeData[x].subParentIndex) &&
            (parentModuleDefinition.parentIndex === this.formattedPrivilegeData[x].parentIndex)
            ) {
             this.formattedPrivilegeData[x].isEnabled = parentModuleDefinition.isChecked;
              this.formattedPrivilegeData[x].privileges.forEach((privilege) => {
                privilege.forEach((privil: any) => {
                  privil.isEnabled = parentModuleDefinition.isChecked;
                  privil.isChecked = parentModuleDefinition.isChecked;
                });
              });
            }
          }
        }
      }
    }
  }
}
