import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import { ResourceService } from '../resource.service';
import { throwError , of  } from 'rxjs'; 


@Injectable()
export class GroupManagementService {
    public headers = new HttpHeaders({ 'api': 'admin' });
    constructor(public _http: HttpClient, public resourceService: ResourceService) { }
    /*Method used to add a new role in the system or update an existing role */
    public saveGroup(groupDefinition: any) {
        return this._http.post('api/admin/group', groupDefinition,{headers : this.headers} ).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.error.errors[0].validationCode !== 200) {
                        return Observable.throw(error.error.errors['0'].validationMessage);
                    }
                }
            }),);
    }

    /* Method used to perform soft delete the role from DB */
    public deleteGroup(appId: number, groupId: number): Observable<any> {
        const url = 'api/admin/' + appId + '/group/' + groupId + '/';
        return this._http.delete(url,{headers : this.headers}).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.error.errors[0].validationCode !== 200) {
                        return Observable.throw(error.error.errors['0'].validationMessage);
                    }
                }
            }));
    }

    public checkActiveUser(appId: number, groupId: number): Observable<any> {
        const url = `api/admin/group/activeuser/${appId}/${groupId}`;
        return this._http.get(url, { headers: this.headers })
            .pipe(mergeMap((res: any) => {
                if (res.statusCode !== 200) {
                 return throwError({ error: res });
                }
                return of(res);
            }),
            catchError((error: any) => {
                if (error.error.errors && error.error.errors.length > 0) {
                    return throwError(error.error.errors[0].validationMessage);
                } else if (error.error) {
                    return throwError(error.error.statusMessage);
                }
            }));
    }
}
