import { Component } from '@angular/core';
import { ConfirmDialogService } from './confirm-dialog.service';
import { ResourceService } from '../../resource.service';

@Component({
  selector: 'sce-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {

  public hidden = true;
  public buttonA = 'OK';
  public buttonB = '';
  public buttonC = '';
  public buttonD = '';
  public buttonE = '';
  public buttonAPrimary = '';
  public buttonBPrimary = '';
  public buttonCPrimary = 'btn-primary';
  public buttonDPrimary = '';
  public buttonEPrimary = '';

  public messageLines: string[] = ['Do you wish to confirm ?'];

  // @ViewChild('buttonA') mainButton:ElementRef;

  constructor(public confirmDialogService: ConfirmDialogService, public resourceService: ResourceService) {
    this.confirmDialogService.getDialogBroadcast()
      .subscribe((dialog: any) => {
        this.messageLines = dialog.messages;
        switch (dialog.type) {
          case 'confirm':
            this.buttonA = this.resourceService.get('lbl_yes');
            this.buttonAPrimary = '';
            this.buttonB = '';
            this.buttonC = this.resourceService.get('lbl_no');
            this.buttonCPrimary = 'btn-primary';
            this.buttonD = '';
            this.buttonE = '';
            break;
          case 'confirm-with-yes-default':
              this.buttonA = this.resourceService.get('lbl_yes');
              this.buttonAPrimary = 'btn-primary';
              this.buttonB = '';
              this.buttonC = this.resourceService.get('lbl_no');
              this.buttonCPrimary = '';
              this.buttonD = '';
              this.buttonE = '';
              break;
          case 'okcancel':
            this.buttonA = this.resourceService.get('lbl_ok');
            this.buttonB = '';
            this.buttonC = '';
            this.buttonD = '';
            this.buttonE = this.resourceService.get('btn_cncel');
            break;
          case 'yesnocancel':
            this.buttonA = this.resourceService.get('lbl_yes');
            this.buttonB = '';
            this.buttonC = this.resourceService.get('lbl_no');
            this.buttonCPrimary = 'btn-primary';
            this.buttonD = '';
            this.buttonE = this.resourceService.get('btn_cancel');
            break;
          case 'ok':
            this.buttonA = this.resourceService.get('lbl_ok');
            this.buttonB = '';
            this.buttonC = '';
            this.buttonD = '';
            this.buttonE = '';
            break;
          case 'yesallnoallcancel':
            this.buttonA = this.resourceService.get('lbl_yes');
            this.buttonB = this.resourceService.get('lbl_yes_to_all');
            this.buttonC = this.resourceService.get('lbl_no');
            this.buttonCPrimary = 'btn-primary';
            this.buttonD = this.resourceService.get('lbl_no_to_all');
            this.buttonE = this.resourceService.get('btn_cancel');
            break;
        }
        this.show();
      });
  }

  public show() {
    this.hidden = false;
  }

  public hide() {
    this.hidden = true;
  }

  public confirm() {
    this.hide(); // !important hide should be done first
    this.confirmDialogService.sendResponse(true);
  }

  public decline() {
    this.hide(); // !important hide should be done first
    this.confirmDialogService.sendResponse(false);
  }

  public cancel() {
    this.hide(); // !important hide should be done first
    this.confirmDialogService.sendResponse('cancel');
  }

  public yestoall() {
    this.hide(); // !important hide should be done first
    this.confirmDialogService.sendResponse('yestoall');
  }


  public notoall() {
    this.hide(); // !important hide should be done first
    this.confirmDialogService.sendResponse('notoall');
  }


}
